import { makeStyles } from '@mui/styles';

import { ANIMATIONS } from '../../utils/animations.utils';

export const useLoadingStyles = makeStyles({
	...ANIMATIONS['gyant-animation-spin'],
	loader: {
		animation: '$gyant-animation-spin 1s linear infinite',
	},
});
