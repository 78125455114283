import { Box, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { heartbeatAnimation } from '../../utils/mui-animations.utils';
import { Info } from '../info/info.component';

export const StyledDateErrorMessage = styled(Info)(({ theme: { palette } }) => ({
	marginTop: palette.sizes.size_2,
	'& > svg': {
		animation: `${heartbeatAnimation} 3s linear infinite`,
	},
}));

export const InputStyles = (theme: Theme) => ({
	marginRight: `${theme.palette.sizes.size_1}px`,
});

export const StyledWrapper = styled(Box)({
	display: 'flex',
});

export const YearInput = {
	flex: 1,
};
