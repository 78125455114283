import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { OutlinedInputProps } from '@mui/material';
import { FC, useState } from 'react';

import { Icon } from '../icon/icon.component';
import { Input } from '../input/input.component';
import { StyledAdornmentButton, StyledRoot } from './input-password.styles';

export const InputPassword: FC<OutlinedInputProps> = (props) => {
	const [isPasswordVisible, setPasswordVisible] = useState(false);

	return (
		<Input
			{...props}
			fullWidth
			error={props.error}
			className={props.className}
			value={props.value}
			type={isPasswordVisible ? 'text' : 'password'}
			onChange={props.onChange}
			onFocus={props.onFocus}
			placeholder={props.placeholder}
			label={props.label}
			endAdornment={
				<StyledAdornmentButton
					variant={'text'}
					onClick={() => setPasswordVisible(!isPasswordVisible)}
					data-testing-label={isPasswordVisible ? 'hide-password' : 'show-password'}>
					<Icon
						iconType={'systemIconDefault'}
						icon={isPasswordVisible ? VisibilityRoundedIcon : VisibilityOffRoundedIcon}
					/>
				</StyledAdornmentButton>
			}
			sx={StyledRoot}
		/>
	);
};
