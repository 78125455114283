import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LLMSearchResult } from '../../../models/message.model';
import { Link } from '../../../ui-kit/link/link.component';
import { TextRenderer } from '../../message-renderers/text-renderer/text-renderer.component';
import { useLLMResultsAccordionSummaryStyles, useLLMSearchResultsStyles } from './llm-result.styles';

interface LLMResultProps {
	content: JSX.Element;
	searchResults: LLMSearchResult[];
}

export const LLMResult: FC<LLMResultProps> = ({ content, searchResults }) => {
	const { t } = useTranslation();
	const classes = useLLMSearchResultsStyles();
	const summaryClasses = useLLMResultsAccordionSummaryStyles();

	const renderSearchResults = (searchResults: LLMSearchResult[]) => (
		<Accordion className={classes.root} square data-testing-label={'llm-result-text-sources'}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				classes={summaryClasses}
				data-testing-label={'llm-result-text-sources-summary'}>
				{t('LLMResultTextSources')}
			</AccordionSummary>
			<AccordionDetails className={classes.detailsRoot} data-testing-label={'llm-result-text-sources-details'}>
				<ul className={classes.detailsList}>
					{searchResults.map((result, index) => (
						<li
							key={result.url}
							className={classes.detailsListItem}
							data-testing-label={'llm-result-text-sources-item'}>
							<span className={classes.detailsListItemNumber}>[{index + 1}]</span>
							<Link
								href={result.url}
								data-testing-label={'llm-result-text-sources-item-link'}
								target={'_blank'}>
								{result.title}
							</Link>
							<p
								className={classes.detailsDescription}
								data-testing-label={'llm-result-text-sources-item-description'}>
								{result.text}
							</p>
						</li>
					))}
				</ul>
			</AccordionDetails>
		</Accordion>
	);

	return (
		<div data-testing-label={'llm-result-component'}>
			<TextRenderer isUndoable={false} isUserMessage={false} message={content} />
			{searchResults.length > 0 && renderSearchResults(searchResults)}
		</div>
	);
};
