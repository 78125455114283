import { makeStyles } from '@mui/styles';

export const useAppointmentSchedulerStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
		},
		typography: { smallViewPort },
	}) => ({
		container: {
			width: '100%',
			paddingTop: sizes.size_2,
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		},
		paddingContainer: {
			padding: `0px ${sizes.size_1}%`,
		},
		numberOfSlots: {
			padding: `0 0 ${sizes.sizeHalf}px ${sizes.size_1}px`,
			...smallViewPort.typeCaption1,
			marginTop: sizes.size_3,
			display: 'inline-block',
		},
		slotsContainer: {
			display: 'flex',
			flexDirection: 'column',
			paddingTop: sizes.size_1,
		},
		timeSlot: {
			margin: `0px ${sizes.size_1}%`,
			'&:not(:last-child)': {
				marginBottom: sizes.sizeHalf,
			},
		},
		withSeparator: {
			'& > *:not(:last-child):after': {
				content: '"·"',
				padding: '0 3px',
				color: colorVariables.colorNote,
			},
		},
		label: {
			...smallViewPort.typeNoteParagraph,
		},
		buttonContainerSticky: {
			position: 'sticky',
			bottom: 0,
		},
		buttonContainer: {
			marginTop: sizes.sizeHalf,
			boxSizing: 'border-box',
			padding: `${sizes.size_2}px ${sizes.size_1}%  22px`,
			backgroundColor: 'white',
		},
		linkButton: {
			...smallViewPort.typeLink2,
			padding: sizes.size_1,
			display: 'block',
			margin: `0 auto ${sizes.size_3}px`,
			textDecoration: 'underline',
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: 'transparent',
				textDecorationColor: colorVariables.colorPrimary,
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
		},
		renderWrapper: {
			flex: 1,
		},
	}),
);
