import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import React, { FC } from 'react';

import {
	ActiveDot,
	ArrowLeft,
	ArrowRight,
	Dot,
	DotsContainer,
	HiddenButton,
	MinimizedDot,
	StepperRoot,
	StyledButton,
} from './stepper.styles';

const MAX_VISIBLE_DOTS = 11;

interface StepperProps {
	steps: number;
	activeStep: number;
	onActiveStepChange: (step: number) => void;
}

export const Stepper: FC<StepperProps> = ({ steps, activeStep, onActiveStepChange }) => {
	const numberOfVisibleDots = Math.min(steps, MAX_VISIBLE_DOTS);

	const handleBack = () => onActiveStepChange(activeStep - 1);
	const handleNext = () => onActiveStepChange(activeStep + 1);

	const firstVisibleDot =
		Math.max(activeStep - Math.floor(numberOfVisibleDots / 2), 1) > steps - numberOfVisibleDots + 1
			? steps - numberOfVisibleDots + 1
			: Math.max(activeStep - Math.floor(numberOfVisibleDots / 2), 1);

	const lastVisibleDot = Math.min(firstVisibleDot + numberOfVisibleDots - 1, steps);

	const shouldRenderMinimizedDot = (i: number) =>
		steps > MAX_VISIBLE_DOTS &&
		((i === firstVisibleDot && firstVisibleDot > 1) || (i === lastVisibleDot && lastVisibleDot < steps));

	const dots = [];

	for (let i = firstVisibleDot; i <= lastVisibleDot; i++) {
		if (i === activeStep) {
			dots.push(
				<ActiveDot key={i} data-testing-label={`stepper-dot-${i}`} onClick={() => onActiveStepChange(i)} />,
			);
		} else if (shouldRenderMinimizedDot(i)) {
			dots.push(
				<MinimizedDot key={i} data-testing-label={`stepper-dot-${i}`} onClick={() => onActiveStepChange(i)} />,
			);
		} else {
			dots.push(<Dot key={i} data-testing-label={`stepper-dot-${i}`} onClick={() => onActiveStepChange(i)} />);
		}
	}

	return (
		<StepperRoot id={'steps-container'}>
			<StyledButton
				data-testing-label={'carousel-controls-prev-button'}
				aria-label={'previous'}
				variant={'text'}
				size={'medium'}
				onClick={handleBack}
				disabled={activeStep === 1}
				as={activeStep === 1 ? HiddenButton : StyledButton}>
				<ArrowLeft>
					<KeyboardArrowDownRoundedIcon aria-label={'previous'} sx={{ fontSize: 32 }} />
				</ArrowLeft>
			</StyledButton>
			<DotsContainer>{dots}</DotsContainer>
			<StyledButton
				data-testing-label={'carousel-controls-next-button'}
				aria-label={'next'}
				size={'medium'}
				onClick={handleNext}
				disabled={activeStep === steps}
				as={activeStep === steps ? HiddenButton : StyledButton}>
				<ArrowRight>
					<KeyboardArrowDownRoundedIcon aria-label={'next'} sx={{ fontSize: 32 }} />
				</ArrowRight>
			</StyledButton>
		</StepperRoot>
	);
};
