import classnames from 'classnames';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '../../../utils/types.utils';
import { IconButton } from '../../icon-button/icon-button.component';
import { Icon } from '../../icon/icon.component';
import { ThumbsDownIcon } from '../../icons/thumbsDown';
import { ThumbsDownOutlineIcon } from '../../icons/thumbsDownOutline';
import { ThumbsUpIcon } from '../../icons/thumbsUp';
import { ThumbsUpOutlineIcon } from '../../icons/thumbsUpOutline';
import { useHelpfulnessSurveyStyles } from './helpfulness-survey.styles';

export interface HelpfulnessSurveyProps {
	action(rating: number): void;
	className?: string;
}

type Rating = 1 | 2;

export const HelpfulnessSurvey: FC<HelpfulnessSurveyProps> = ({ action, className }) => {
	const { t } = useTranslation();
	const customClasses = useHelpfulnessSurveyStyles();
	const classRoot = classnames(customClasses.root, className);
	const [selectedIcon, setSelectedIcon] = useState<Nullable<Rating>>(null);
	const [animationClassName, setAnimationClassName] = useState<string>();
	const onSelectButton = (rate: Rating) => {
		if (rate !== selectedIcon) {
			setSelectedIcon(rate);
			action(rate);
		}
	};

	useEffect(() => {
		if (selectedIcon) {
			setAnimationClassName(selectedIcon === 1 ? customClasses.thumbsDown : customClasses.thumbsUp);
		}
	}, [selectedIcon]);

	const thumbs = (
		<Fragment>
			<IconButton
				className={customClasses.buttonIcon}
				aria-label={`thumbs down${selectedIcon === 1 ? ' voted' : ''}`}
				color={'primary'}
				onClick={() => onSelectButton(1)}
				size={'medium'}>
				<Icon
					className={classnames(selectedIcon === 1 && animationClassName)}
					viewBox={'0 0 20 20'}
					icon={selectedIcon === 1 ? ThumbsDownIcon : ThumbsDownOutlineIcon}
					size={'small'}
					iconType={'systemIconDefault'}
					alt={'thumbs down'}
				/>
			</IconButton>
			<IconButton
				className={customClasses.buttonIcon}
				aria-label={`thumbs up${selectedIcon === 2 ? ' voted' : ''}`}
				color={'primary'}
				onClick={() => onSelectButton(2)}>
				<Icon
					className={classnames(selectedIcon === 2 && animationClassName)}
					icon={selectedIcon === 2 ? ThumbsUpIcon : ThumbsUpOutlineIcon}
					viewBox={'0 0 20 20'}
					size={'small'}
					iconType={'systemIconDefault'}
					alt={'thumbs up'}
				/>
			</IconButton>
		</Fragment>
	);

	return (
		<div className={classRoot} data-testing-label={'helpfulness-survey'}>
			<h4 className={customClasses.text}>{t('helpfulnessSurveyText', 'Is this helpful?')}</h4>
			{thumbs}
		</div>
	);
};
