import { makeStyles } from '@mui/styles';

export const chipStyles = makeStyles(
	({
		palette: {
			colorScheme: { colorVariables },
		},
		typography: { smallViewPort },
	}) => ({
		icon: {
			color: '',
			marginInline: '0 !important',
			width: 24,
			height: 24,
		},
		label: {
			padding: 0,
			paddingLeft: 8,
			paddingRight: 0,
			paddingTop: 11,
			paddingBottom: 11,
			whiteSpace: 'initial',
		},
		labelSmall: {
			padding: 9,
			paddingLeft: 8,
			paddingRight: 0,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		root: {
			...smallViewPort.typeChip1,
			'& .MuiTouchRipple-root > *': {
				display: 'none',
			},
			maxWidth: '100%',
			height: 'auto',
			minHeight: 48,
			paddingLeft: 16,
			paddingRight: 16,
			transition: 'all 0.8s ease',
			textAlign: 'center',
			backgroundColor: colorVariables.colorPrimarySoft,
			'&:hover': {
				transition: 'background-color 0.1s, box-shadow 0.1s',
				backgroundColor: colorVariables.colorPrimarySoft,
				filter: 'brightness(96%)',
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimarySoft}`,
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
			},
			'&.Mui-disabled': {
				color: colorVariables.colorNote,
				backgroundColor: colorVariables.colorInactive,
				opacity: 1,
				border: 'none',
				'&:focus': {
					backgroundColor: colorVariables.colorInactive,
				},
			},
		},
		clickable: {
			'&:focus:not(:focus-visible), &:active:not(:focus-visible)': {
				backgroundColor: colorVariables.colorPrimarySoft,
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimarySoft}`,
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				backgroundColor: colorVariables.colorPrimarySoft,
			},
			'&:hover': {
				backgroundColor: colorVariables.colorPrimarySoft,
				filter: 'brightness(96%)',
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimarySoft}`,
			},
			'&:disabled': {
				color: colorVariables.colorNote,
				backgroundColor: colorVariables.colorInactive,
				border: 'none',
			},
		},
		sizeSmall: {
			...smallViewPort.typeChip2,
			minHeight: 40,
		},
	}),
);

export const useChipStyles = makeStyles(
	({
		palette: {
			colorScheme: { colorVariables },
			borderRadius,
		},
	}) => ({
		root: {
			borderRadius: borderRadius.commonRadius,
			'&:active': {
				backgroundColor: colorVariables.colorPrimarySoft,
				filter: 'brightness(88%)',
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimarySoft}`,
			},
			'& > svg': {
				transition: 'background-color 0.8s, box-shadow 0.8s, filter 0.8s',
			},
			whiteSpace: 'initial',
		},
		labelOnly: {
			'& > span': {
				paddingLeft: 0,
			},
		},
		selected: {
			boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimary}`,
			'&:focus:not(:focus-visible), &:active:not(:focus-visible)': {
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimary}`,
			},
			'&:hover': {
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimary}`,
			},
		},
		disabledSelected: {
			'&:focus:not(:focus-visible), &:active:not(:focus-visible)': {
				backgroundColor: colorVariables.colorInactive,
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorBorder}`,
			},
			boxShadow: `0px 0px 0px 1px ${colorVariables.colorBorder}`,
		},
		disabled: {
			'& > svg': {
				color: colorVariables.colorBorder,
			},
			'& > svg:first-child': {
				fill: colorVariables.colorBorder,
			},
		},
		iconOnly: {
			'& > span': {
				display: 'none',
			},
		},
		endIcon: {
			flexDirection: 'row-reverse',
			'& > span': {
				paddingLeft: 0,
				paddingRight: 8,
			},
		},
		ghost: {
			backgroundColor: 'transparent',
			color: colorVariables.colorCaption,
			boxShadow: `0px 0px 0px 1px ${colorVariables.colorBorder}`,
			'& > svg': {
				fill: colorVariables.colorBorder,
				color: colorVariables.colorBorder,
			},
			'&:hover': {
				backgroundColor: 'hsla(0, 0%, 100%, 0.4)',
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorNote}`,
				transition: 'background-color 0.1s, box-shadow 0.1s',
			},
			'&:focus:not(:hover)': {
				backgroundColor: 'transparent',
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorBorder}`,
			},
			'&:focus:not(:focus-visible), &:active:not(:focus-visible)': {
				backgroundColor: 'transparent',
				color: colorVariables.colorCaption,
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorBorder}`,
			},
			'&:active:not(:focus-visible), &:active': {
				backgroundColor: 'hsla(0, 0%, 100%, 0.4)',
				filter: 'brightness(88%)',
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorNote}`,
				transition: 'background-color 0.1s, box-shadow 0.1s',
			},
			'&:focus-visible': {
				backgroundColor: 'inherit',
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
			},
		},
		disabledGhost: {
			boxShadow: 'none',
		},
	}),
);
