import makeStyles from '@mui/styles/makeStyles';

export const useActionMenuStyle = makeStyles(() => ({
	root: {
		width: '100%',
		listStyleType: 'none',
		padding: 0,
		margin: 0,
	},
	itemStyle: {
		marginBottom: 8,
	},
	buttonStyle: {
		width: '100%',
	},
}));
