import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ConfirmationAltIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C12.8 2 13.5792 2.09167 14.3375 2.275C15.0958 2.45833 15.825 2.725 16.525 3.075C16.775 3.20833 16.9375 3.40833 17.0125 3.675C17.0875 3.94167 17.0417 4.19167 16.875 4.425C16.7083 4.65833 16.4875 4.80833 16.2125 4.875C15.9375 4.94167 15.6667 4.90833 15.4 4.775C14.8667 4.525 14.3125 4.33333 13.7375 4.2C13.1625 4.06667 12.5833 4 12 4C9.78333 4 7.89583 4.77917 6.3375 6.3375C4.77917 7.89583 4 9.78333 4 12C4 14.2167 4.77917 16.1042 6.3375 17.6625C7.89583 19.2208 9.78333 20 12 20C14.2167 20 16.1042 19.2208 17.6625 17.6625C19.2208 16.1042 20 14.2167 20 12C20 11.8667 19.9958 11.7375 19.9875 11.6125C19.9792 11.4875 19.9667 11.3583 19.95 11.225C19.9167 10.9417 19.9708 10.6708 20.1125 10.4125C20.2542 10.1542 20.4667 9.98333 20.75 9.9C21.0167 9.81667 21.2667 9.84167 21.5 9.975C21.7333 10.1083 21.8667 10.3083 21.9 10.575C21.9333 10.8083 21.9583 11.0417 21.975 11.275C21.9917 11.5083 22 11.75 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM10.6 13.8L19.9 4.475C20.0833 4.29167 20.3125 4.19583 20.5875 4.1875C20.8625 4.17917 21.1 4.275 21.3 4.475C21.4833 4.65833 21.575 4.89167 21.575 5.175C21.575 5.45833 21.4833 5.69167 21.3 5.875L11.3 15.9C11.1 16.1 10.8667 16.2 10.6 16.2C10.3333 16.2 10.1 16.1 9.9 15.9L7.05 13.05C6.86667 12.8667 6.775 12.6333 6.775 12.35C6.775 12.0667 6.86667 11.8333 7.05 11.65C7.23333 11.4667 7.46667 11.375 7.75 11.375C8.03333 11.375 8.26667 11.4667 8.45 11.65L10.6 13.8Z'
			}
		/>
	</SvgIcon>
);
