import makeStyles from '@mui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../models/dimensions.model';

export const useLinkStyles = makeStyles(
	({
		typography: { smallViewPort, largeViewPort },
		palette: {
			colorScheme: { colorVariables },
			borderRadius,
		},
	}) => ({
		typeSysLink1: {
			...smallViewPort.typeSysLink1,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeSysLink1,
			},
		},
		typeSysLink2: {
			...smallViewPort.typeSysLink2,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeSysLink2,
			},
		},
		typeLink1: {
			...smallViewPort.typeLink1,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeLink1,
			},
		},
		typeLink2: {
			...smallViewPort.typeLink2,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeLink2,
			},
		},
		typeNoteLink: {
			...smallViewPort.typeNoteLink,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeNoteLink,
			},
		},
		linkButton: {
			minHeight: 48,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 8,
			backgroundColor: colorVariables.colorPrimarySoft,
			borderRadius: borderRadius.button,
			...smallViewPort.typeButton1Alt,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeButton1Alt,
			},
			textDecoration: 'none',
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				boxShadow: 'none',
			},
		},
	}),
);
