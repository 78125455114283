import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { THUMBS_ANIMATION_TIME } from '../../../ui/user-survey/helfulness-survey.model';
import { Avatar } from '../../avatar/avatar.component';
import { IconButton } from '../../icon-button/icon-button.component';
import { Icon } from '../../icon/icon.component';
import { HelpfulnessSurvey } from '../helpfulness-survey/helpfulness-survey.component';
import { helpfulnessAnimation, useHelpfulnessSurveyExternalStyles } from './helpfulness-survey-external.styles';

export interface HelpfulnessSurveyExternalProps {
	action(rating: number): void;
	onCloseSurvey(): void;
	isOpen: boolean;
	hasAvatarButton?: boolean;
}

const timeout = {
	enter: THUMBS_ANIMATION_TIME,
	exit: THUMBS_ANIMATION_TIME,
};

export const HelpfulnessSurveyExternal: FC<HelpfulnessSurveyExternalProps> = ({
	action,
	onCloseSurvey,
	isOpen,
	hasAvatarButton,
}) => {
	const classes = useHelpfulnessSurveyExternalStyles({ hasWidget: !hasAvatarButton });
	const helpfulnessAnimationStyle = helpfulnessAnimation({ hasWidget: !hasAvatarButton, timeout });

	return (
		<CSSTransition
			in={isOpen}
			classNames={helpfulnessAnimationStyle}
			mountOnEnter
			unmountOnExit
			timeout={timeout}
			data-testing-label={'survey-animation'}>
			<div className={classes.root}>
				{hasAvatarButton && (
					<Avatar className={classes.avatar} dataTestingLabel={'helpfulness-survey-external-avatar'} />
				)}
				<HelpfulnessSurvey action={action} className={classes.content} />
				<IconButton
					className={classes.buttonIcon}
					aria-label={'close survey'}
					color={'primary'}
					onClick={onCloseSurvey}>
					<Icon icon={CloseRoundedIcon} size={'small'} iconType={'inputIcon'} alt={'close icon'} />
				</IconButton>
			</div>
		</CSSTransition>
	);
};
