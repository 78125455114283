import { makeStyles } from '@mui/styles';

export const useInputUploadStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
			boxShadow,
			borderRadius,
		},
		typography: { smallViewPort },
	}) => ({
		root: {
			width: '100%',
		},
		input: {
			display: 'none',
		},
		actionButton: {
			width: '100%',
			color: colorVariables.colorPrimary,
			'& svg': {
				color: colorVariables.colorPrimary,
			},
		},
		disabledChip: {
			'& svg': {
				color: 'currentColor',
			},
		},
		continueButton: {
			marginTop: sizes.size_2,
		},
		cameraButton: {
			marginBottom: sizes.size_1,
		},
		cancelButton: {
			backgroundColor: colorVariables.colorSysBgAlert,
			color: colorVariables.colorSysTextAlert,
			'&:hover, &:focus-visible, &:focus, &:focus:not(:focus-visible)': {
				backgroundColor: colorVariables.colorSysBgAlert,
			},
		},
		previewWrapper: {
			position: 'relative',
			width: '100%',
			backgroundColor: colorVariables.colorInactive,
			boxShadow: boxShadow.angle.xsmall,
			borderRadius: borderRadius.card,
			overflow: 'hidden',
			marginBottom: sizes.size_2,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		imagePreview: {
			height: 257,
		},
		blurred: {
			filter: 'blur(4px)',
		},
		blurredLayer: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			backgroundColor: 'white',
			opacity: 0.7,
			zIndex: 1,
		},
		removeUploadedImageButton: {
			position: 'absolute',
			right: sizes.size_2,
			top: sizes.size_2,
			borderRadius: '50%',
			padding: sizes.sizeHalf,
		},
		removeUploadedImageIcon: {
			fill: 'white',
		},
		error: {
			margin: `${sizes.size_2}px 0`,
		},
		progress: {
			position: 'absolute',
			bottom: '50%',
			left: sizes.size_2,
			right: sizes.size_2,
			textAlign: 'center',
			transform: 'translateY(50%)',
			zIndex: 1,
		},
		progressLabelsContainer: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		progressLabel: {
			...smallViewPort.typeSysText1,
			display: 'block',
		},
		linearProgress: {
			borderRadius: sizes.sizeHalf,
		},
		videoPreview: {
			position: 'relative',
		},
		video: {
			width: '100%',
			borderRadius: borderRadius.card,
		},
		takePictureButton: {
			marginTop: sizes.size_1,
		},
		noPreviewRoot: {
			borderRadius: borderRadius.card,
			textAlign: 'center',
			padding: `${sizes.size_3}px ${sizes.size_4}px ${sizes.size_4}px`,
			margin: `${sizes.size_3}px ${sizes.size_1}%`,
			display: 'grid',
			...smallViewPort.typeHeading1,
			'& > svg': {
				margin: `${sizes.size_1}px auto`,
			},
			'& > p': {
				...smallViewPort.typeSysHeading1,
			},
		},
	}),
);
