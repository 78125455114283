import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import classNames from 'classnames';
import { Lazy } from 'fp-ts/function';
import { FC, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../../ui-kit/icon/icon.component';
import { Link } from '../../../../ui-kit/link/link.component';
import { useLiveChatMessageStyles } from './message.styles';

type LiveChatMessageType = 'user' | 'agent';

export interface LiveChatMessageAdditionalInfo {
	agentName?: string;
	time: string;
}

export interface LiveChatMessageProps {
	text: ReactNode;
	type: LiveChatMessageType;
	additionalInfo?: LiveChatMessageAdditionalInfo;
	className?: string;
	isUnreadMessage?: boolean;
	isUndelivered?: boolean;
	isSending?: boolean;
	onRetryMessages?: Lazy<void>;
	showRetry?: boolean;
}

export const LiveChatMessage: FC<LiveChatMessageProps> = ({
	text,
	type,
	additionalInfo,
	isUnreadMessage,
	isUndelivered,
	className,
	isSending,
	onRetryMessages,
	showRetry,
}) => {
	const classes = useLiveChatMessageStyles();
	const { t } = useTranslation();
	const rootClassName = classNames(
		classes.root,
		type === 'agent' ? classes.agentRoot : classes.userRoot,
		type === 'agent' && isUnreadMessage && classes.unreadAgentMessage,
		className,
	);
	const textClassName = classNames(
		classes.textWrapper,
		type === 'agent' ? classes.agent : classes.user,
		isSending && classes.sendingText,
	);

	const renderAdditionalInfo = ({ agentName, time }: LiveChatMessageAdditionalInfo) => {
		const additionalInfoRootClass = classNames(
			classes.additionalInfo,
			classes.withSeparator,
			type === 'agent' ? classes.additionalInfoAgent : classes.additionalInfoUser,
		);
		return (
			<span className={additionalInfoRootClass}>
				{agentName && <span>{agentName}</span>}
				<span>{time}</span>
			</span>
		);
	};

	const handleRetryMessage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		onRetryMessages && onRetryMessages();
	};

	const renderDefault = (
		<Fragment>
			{isUnreadMessage && <span className={classes.unreadMessage} />}
			<p className={textClassName} data-testing-label={`live-chat-message-${type}`}>
				{text}
			</p>
			{additionalInfo && renderAdditionalInfo(additionalInfo)}
		</Fragment>
	);

	const renderUndelivered = (isSending?: boolean): JSX.Element => {
		const messageDeliveredStatusRootClass = classNames(
			classes.withRetrySeparator,
			classes.additionalInfoUser,
			isSending ? classes.sendingLabel : classes.messageDeliveredStatus,
		);
		const messageDeliveredStatusText = classNames(classes.messageStatusText);

		const renderMessageUndelivered = (
			<div className={classes.messageUndelivered} data-testing-label={'live-chat-message-undelivered'}>
				<p className={textClassName}>{text}</p>
				{!isSending && (
					<Icon
						icon={ErrorRoundedIcon}
						iconType={'systemIconAlert'}
						alt={'undelivered message'}
						className={classes.undeliveredIcon}
					/>
				)}
			</div>
		);

		const renderRetryMessageLink = (
			<span className={messageDeliveredStatusRootClass} data-testing-label={'live-chat-message-retry'}>
				<span className={messageDeliveredStatusText}>{t('liveChatNotSentLabel', 'Not sent')}</span>
				<Link
					onClick={handleRetryMessage}
					linkType={'typeLink1'}
					href={'/'}
					rel={'noreferrer'}
					className={classes.messageRetryLink}
					data-testing-label={'live-chat-retry-message-link'}>
					{t('liveChatRetryLink', 'Retry')}
				</Link>
			</span>
		);

		const rendingSendingLabel = (
			<span className={messageDeliveredStatusRootClass}>
				<span className={messageDeliveredStatusText}>{t('liveChatSendingLabel', 'Sending...')}</span>
			</span>
		);

		return (
			<div className={classes.messageWrapper}>
				{renderMessageUndelivered}
				{showRetry && !isSending && onRetryMessages && renderRetryMessageLink}
				{isSending && rendingSendingLabel}
			</div>
		);
	};

	return (
		<div className={rootClassName}>{isUndelivered || isSending ? renderUndelivered(isSending) : renderDefault}</div>
	);
};
