import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { USER_INPUT_HEIGHT } from '../../utils/sizes.utils';

interface WidgetContentStyleProps {
	isMobile: boolean;
	paddingChatValue: number;
}

export const useWidgetContentStyles = makeStyles<Theme, WidgetContentStyleProps>(
	({ palette: { colorScheme, sizes } }) => ({
		root: {
			width: '100%',
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			'@supports (overflow-y: overlay)': {
				overflowY: 'overlay',
			},
			position: 'relative',
			flex: 1,
			flexDirection: 'column',
			justifyContent: 'space-between',
			zIndex: 1,
			'&::-webkit-scrollbar': {
				display: 'block',
				width: 5,
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: colorScheme.colorVariables.colorBorder,
				borderRadius: 5,
				borderRight: 'none',
				borderLeft: 'none',
			},
			'&::-webkit-scrollbar-track-piece:end': {
				background: 'transparent',
				marginBottom: 96,
			},
			...colorScheme.customStyle.content,
		},
		userMessageWrapper: {
			position: 'absolute',
			width: '100%',
			padding: `${sizes.size_1}px 0 0`,
			zIndex: 1,
			background: 'transparent',
		},
		bottomPaddingSpace: {
			paddingBottom: (props) => `calc(${sizes.size_1}% + ${props.paddingChatValue}px)`,
		},
		botMessagesWrapper: {
			position: 'relative',
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			marginTop: sizes.size_8,
			justifyContent: 'center',
			minHeight: `calc(100% - ${USER_INPUT_HEIGHT}px - ${sizes.size_2}px)`,
			top: 0,
			'& > div:first-child': {
				marginTop: 'auto',
			},
			'& > div:last-child': {
				marginTop: 'auto',
			},
		},
		singleBotTextMessage: {
			'& > div:first-child, & > div:last-child, & > div': {
				marginTop: 0,
			},
		},
		waitingResponse: {
			marginLeft: 58,
		},
		visible: {
			opacity: 1,
			height: 'auto',
		},
		transitionGroup: {
			position: 'relative',
		},
		loadingBotMessages: {
			position: 'absolute',
			top: '45%',
			left: '45%',
			color: colorScheme.colorVariables.colorPrimary,
		},
		overflowHidden: {
			overflow: 'hidden',
		},
	}),
);

export const useWidgetAnimationStyle = makeStyles(
	({
		palette: {
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		exitNextWidgetAnimation: {
			opacity: 0,
			top: '-4%',
			transition: noAnimationTransition ? 'initial' : 'top 800ms, opacity 800ms',
		},
		exitPreviousWidgetAnimation: {
			opacity: 0,
			top: '4%',
			transition: noAnimationTransition ? 'initial' : 'top 800ms, opacity 800ms',
		},
		enterWidgetAnimation: {
			opacity: 1,
			top: 0,
			transition: noAnimationTransition ? 'initial' : 'opacity 600ms',
		},
	}),
);

export const useUserMessageAnimationStyle = makeStyles(
	({
		palette: {
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		exitLoadingAnimation: {
			animation: noAnimationTransition ? 'initial' : '$exitLoadAnimation 600ms',
		},
		enterNextLoadingAnimation: {
			opacity: 1,
			transform: 'translateY(0)',
			animation: noAnimationTransition ? 'initial' : '$enterNextLoadAnimation 400ms',
		},
		enterPreviousLoadingAnimation: {
			opacity: 1,
			transform: 'translateY(0)',
			animation: noAnimationTransition ? 'initial' : '$enterPreviousLoadAnimation 400ms',
		},
		'@keyframes enterPreviousLoadAnimation': {
			'0%': {
				opacity: 0,
				transform: 'translateY(-16%)',
			},
			'100%': {
				opacity: 1,
				transform: 'translateY(0)',
			},
		},
		'@keyframes enterNextLoadAnimation': {
			'0%': {
				opacity: 0,
				transform: 'translateY(16%)',
			},
			'100%': {
				opacity: 1,
				transform: 'translateY(0)',
			},
		},
		'@keyframes exitLoadAnimation': {
			'0%': {
				opacity: 1,
			},
			'100%': {
				opacity: 0,
			},
		},
	}),
);

export const useLoadingAnimationStyle = makeStyles({
	root: {
		zIndex: -1,
		position: 'absolute',
		top: 0,
		bottom: 0,
		width: '100%',
		overflow: 'hidden',
		opacity: 0,
	},
	undo: {
		transform: 'translateY(-4%)',
	},
	notUndo: {
		transform: 'translateY(4%)',
	},
});
