import { default as MaterialChip, ChipProps as MaterialChipProps } from '@mui/material/Chip';
import classNames from 'classnames';
import React, { FC, useState } from 'react';

import { chipStyles, useChipStyles } from './chip.styles';

export type ChipProps = Pick<
	MaterialChipProps,
	'icon' | 'label' | 'avatar' | 'size' | 'classes' | 'className' | 'onClick' | 'deleteIcon' | 'clickable'
> & {
	isSelected?: boolean;
	isDisabled?: boolean;
	isClickableOnce?: boolean;
	isGhost?: boolean;
	dataTestingLabel?: string;
	iconPosition?: 'start' | 'end';
};

export const Chip: FC<ChipProps> = ({
	icon,
	label,
	avatar,
	size,
	deleteIcon,
	classes,
	className,
	isDisabled,
	isSelected,
	isGhost,
	onClick,
	dataTestingLabel,
	iconPosition = 'start',
	isClickableOnce = false,
	clickable,
}) => {
	const [isClicked, setClickedState] = useState(false);
	const customClasses = useChipStyles();
	const innerClasses = chipStyles();
	const classesWrapper = { ...innerClasses, ...classes };

	const chipClassName = classNames(
		customClasses.root,
		isSelected && customClasses.selected,
		isGhost && customClasses.ghost,
		!icon && customClasses.labelOnly,
		isDisabled && customClasses.disabled,
		isDisabled && isSelected && customClasses.disabledSelected,
		isDisabled && isGhost && customClasses.disabledGhost,
		!label && icon && customClasses.iconOnly,
		iconPosition === 'end' && customClasses.endIcon,
		className,
	);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (isClickableOnce) {
			!isClicked && !isDisabled && onClick && onClick(e);
			setClickedState(true);
			setTimeout(() => {
				setClickedState(false);
			}, 400);
		} else {
			!isDisabled && onClick && onClick(e);
		}
	};

	return (
		<MaterialChip
			clickable={clickable}
			tabIndex={isDisabled ? -1 : 0}
			icon={icon}
			deleteIcon={deleteIcon}
			label={label}
			avatar={avatar}
			size={size}
			disabled={isDisabled}
			onClick={handleClick}
			classes={classesWrapper}
			className={chipClassName}
			data-testing-label={dataTestingLabel}
		/>
	);
};
