import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CARD_MAX_WIDTH } from '../../../utils/sizes.utils';

export const useSatisfactionSurveyStyles = makeStyles<Theme, { numberOfButtons: number }>(
	({
		palette: {
			colorScheme: { colorVariables },
			sizes,
			borderRadius,
		},
	}) => ({
		buttonWidth: {
			width: (props) => `calc(100% / (${props.numberOfButtons} + 1))`,
			'&:hover': {
				backgroundColor: colorVariables.colorSysBgDefault,
				filter: 'brightness(96%)',
				transition: 'all 0.1s',
			},
			'&:focus-visible': {
				outlineOffset: 0,
			},
		},
		container: {
			borderRadius: borderRadius.commonRadius,
			height: sizes.size_13,
			background: colorVariables.colorSysBgDefault,
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			maxWidth: CARD_MAX_WIDTH,
		},
	}),
);
