import makeStyles from '@mui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../../../models/dimensions.model';

export const useCareVenueCardStyles = makeStyles(
	({ palette: { sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		},
		title: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 0,
			textAlign: 'center',
			...smallViewPort.typeHeading2,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading2,
			},
		},
		subTitle: {
			paddingTop: sizes.size_1,
			...smallViewPort.typeCaption1,
			marginBottom: 0,
			textAlign: 'center',
		},
		actionsRoot: {
			padding: `0px ${sizes.size_2}px ${sizes.size_1}px`,
			display: 'flex',
			flexDirection: 'column',
		},
		actionButton: {
			'&:not(:last-child)': {
				marginBottom: sizes.size_1,
			},
		},
		image: {
			flex: 0,
			display: 'block',
			width: '100%',
		},
		content: {
			padding: `${sizes.size_2}px ${sizes.size_2}px`,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			height: '100%',
		},
	}),
);
