import { makeStyles } from '@mui/styles';

export const useCardButtonStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
			borderRadius,
		},
	}) => ({
		button: {
			position: 'relative',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			padding: `${sizes.size_2}px ${sizes.size_3}px ${sizes.size_3}px`,
			backgroundColor: 'white',
			borderRadius: borderRadius.card,
			border: `1px solid ${colorVariables.colorDarkShadow}`,
			textAlign: 'left',
			cursor: 'pointer',
			transition: 'all 0.8s',
			'& > svg': {
				transition: 'all 0.8s',
			},
			'&:hover': {
				backgroundColor: colorVariables.colorBgSurface,
				border: `1px solid ${colorVariables.colorBorder}`,
				boxShadow: `0 4px 0 -2px ${colorVariables.colorMediumShadow}`,
				borderRadius: borderRadius.card,
				transition: 'all 0.1s',
				'& > svg': {
					fill: colorVariables.colorPrimary,
					transition: 'all 0.1s',
				},
			},
			'&:focus-visible': {
				outline: `${sizes.sizeQuarter}px solid ${colorVariables.colorPrimary}`,
				outlineOffset: sizes.sizeQuarter,
				transition: 'outline 0s',
			},
		},
		arrowIcon: {
			position: 'absolute',
			right: sizes.size_2,
			top: 21,
		},
	}),
);
