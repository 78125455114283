import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { RemainingTimeIndicator } from '../../ui-kit/remaining-time-indicator/remaining-time-indicator.component';
import { UserInputContainer } from '../user-input/user-input.container';
import {
	useInputCSSTransitionStyles,
	useProgressbarCSSTransitionStyles,
	useWidgetFooterStyles,
} from './widget-footer.styles';

export interface WidgetFooterProps {
	isTextInputDisabled: boolean;
	shouldShowUserInput: boolean;
	isOpen: boolean;
	timeRemaining?: number;
}

const timeout = {
	enter: 600,
	exit: 400,
};

export const WidgetFooter: FC<WidgetFooterProps> = ({
	isTextInputDisabled,
	shouldShowUserInput,
	isOpen,
	timeRemaining,
}) => {
	const [isInputAnimating, setIsInputAnimating] = useState(false);
	const [isProgressAnimating, setIsProgressAnimating] = useState(false);

	const [showProgressbar, setShowProgressbar] = useState(false);
	const [showDynamicInput, setShowDynamicInput] = useState(false);
	const nodeRef = useRef(null);

	const classes = useWidgetFooterStyles();
	const rootClasses = classNames(
		classes.root,
		isOpen && classes.openedWidget,
		(isInputAnimating || isProgressAnimating) && showDynamicInput && classes.backgroundOverlay,
		!isInputAnimating && !timeRemaining && classes.noHeight,
		!isInputAnimating && !showDynamicInput && timeRemaining && classes.smallHeight,
	);

	const inputCSSTransitionStyles = useInputCSSTransitionStyles();
	const progressbarCSSTransitionStyles = useProgressbarCSSTransitionStyles();

	useEffect(() => {
		if (shouldShowUserInput) {
			if (showProgressbar) {
				setTimeout(() => setShowDynamicInput(true), timeout.exit);
			} else {
				setShowDynamicInput(true);
			}
		} else {
			setShowDynamicInput(false);
		}
		if (timeRemaining) {
			if (showDynamicInput) {
				setTimeout(() => setShowProgressbar(true), timeout.exit);
			} else {
				setShowProgressbar(true);
			}
		} else {
			setShowProgressbar(false);
		}
	}, [shouldShowUserInput, timeRemaining]);

	return (
		<footer className={rootClasses}>
			<CSSTransition
				nodeRef={nodeRef}
				in={showDynamicInput}
				classNames={inputCSSTransitionStyles}
				onEntering={() => setIsInputAnimating(true)}
				onExiting={() => setIsInputAnimating(false)}
				mountOnEnter
				unmountOnExit
				timeout={timeout}>
				<div className={classes.inputWrapper} data-testing-label={'widget-footer-input-wrapper'}>
					<UserInputContainer isTextInputDisabled={isTextInputDisabled} />
				</div>
			</CSSTransition>
			<CSSTransition
				nodeRef={nodeRef}
				in={showProgressbar && !!timeRemaining}
				classNames={progressbarCSSTransitionStyles}
				onEntering={() => setIsProgressAnimating(true)}
				onExiting={() => setIsProgressAnimating(false)}
				mountOnEnter
				unmountOnExit
				timeout={timeout}>
				<RemainingTimeIndicator timeRemaining={timeRemaining || 0} />
			</CSSTransition>
		</footer>
	);
};
