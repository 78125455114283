import { keyframes } from '@mui/material';

//TODO: NEED REFACTOR OUR ANIMATION FUNCTION TO WORK WITH MUI KEYFARAMES AND MOVE IT TO ANIMATION UTILS

export const bounceHor = keyframes`
  0% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    animation-timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    animation-timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    animation-timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    animation-timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    animation-timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
`;

export const heartbeatAnimation = keyframes`
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  5% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  10% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  15% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  20% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
`;
