import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { InfoColorSettings } from './info.component';

export const useInfoStyles = makeStyles<Theme, InfoColorSettings>(
	({ palette: { borderRadius, sizes, boxShadow, colorScheme }, typography: { smallViewPort } }) => ({
		root: {
			boxShadow: boxShadow.angle.large,
			padding: sizes.size_2,
			backgroundColor: (props) => props.bgColor,
			borderRadius: borderRadius.button,
			display: 'flex',
			alignItems: 'center',
			border: `1px solid ${colorScheme.colorVariables.colorMediumShadow}`,
		},
		icon: {
			color: (props) => props.iconColor,
			marginRight: sizes.size_1,
			width: sizes.size_3,
			height: sizes.size_3,
		},
		label: {
			...smallViewPort.typeNoteParagraph,
			color: (props) => props.textColor,
			marginBottom: 0,
		},
		clear: {
			boxShadow: 'none',
			border: 'none',
			background: 'white !important',
			padding: 0,
		},
	}),
);
