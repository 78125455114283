import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Z_INDEX_TOP } from '../../utils/sizes.utils';

export const useCustomChatButtonStyles = makeStyles<Theme, { isMobile: boolean; fullScreen: boolean }>(
	({ palette: { sizes, colorScheme } }) => ({
		collapsedChatButton: {
			position: 'fixed',
			right: sizes.size_2,
			bottom: sizes.size_2,
			zIndex: (props) => (props.isMobile || props.fullScreen ? Z_INDEX_TOP - 1 : Z_INDEX_TOP),
			...colorScheme.customStyle.collapsedChatButton,
		},
		openedChatButton: {
			...colorScheme.customStyle.openedChatButton,
			background: 'unset',
			'&:hover': {
				background: 'unset',
			},
		},
		hidden: {
			opacity: 0,
			pointerEvents: 'none',
			transition: 'all 0.4s',
			visibility: 'hidden',
		},
		displayNone: {
			display: 'none',
		},
	}),
);
