import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import { FC, ReactChild, useEffect, useState } from 'react';

import { Chip } from '../../../ui-kit/chip/chip.component';
import { Effect } from '../../../utils/function.utils';
import { useLLMLabelStyles, useLLMLabelTooltipStyles } from './llm-label.styles';

interface LLMLabelProps {
	label: string;
	tooltipContent?: ReactChild;
	onStateChange?: Effect<boolean>;
}

export const LLMLabel: FC<LLMLabelProps> = ({ label, tooltipContent, onStateChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const classes = useLLMLabelStyles();
	const tooltipClasses = useLLMLabelTooltipStyles();

	const handleClick = () => setIsOpen((isOpen) => !isOpen);
	const handleTooltipClose = () => setIsOpen(false);

	useEffect(() => {
		onStateChange && onStateChange(isOpen);
	}, [isOpen]);

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<Tooltip
					PopperProps={{
						disablePortal: true,
					}}
					placement={'bottom'}
					open={isOpen}
					title={tooltipContent || false}
					arrow
					classes={tooltipClasses}
					disableFocusListener
					disableHoverListener>
					<div className={classes.root} tabIndex={-1} role={'tooltip'}>
						<Chip
							size={'small'}
							label={label}
							onClick={handleClick}
							isSelected={isOpen}
							className={classes.chip}
							dataTestingLabel={'llm-label'}
						/>
					</div>
				</Tooltip>
			</div>
		</ClickAwayListener>
	);
};
