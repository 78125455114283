import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { CARD_MAX_WIDTH } from '../../utils/sizes.utils';

export const StepperRoot = styled('div')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
		margin: `${sizes.size_1}px auto 0`,
		maxWidth: `calc(${CARD_MAX_WIDTH}px - ${sizes.size_1}px)`,
		'@media (min-width: 624px)': {
			maxWidth: `calc(${CARD_MAX_WIDTH}px - ${sizes.size_2}px)`,
		},
		'@media (max-width: 460px)': {
			padding: `0 calc(${sizes.size_1}% + ${sizes.sizeHalf}px)`,
			width: '100%',
			maxWidth: 'unset',
		},
		'@media (max-width: 400px)': {
			maxWidth: 'unset',
			width: '100%',
			padding: `0 calc(${sizes.size_1}% + ${sizes.sizeHalf}px)`,
			margin: 0,
		},
	}),
);

export const DotsContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
});

export const Dot = styled('button')(({ theme }) => ({
	border: 'none',
	borderRadius: '50%',
	padding: 0,
	width: theme.palette.sizes.size_1,
	height: theme.palette.sizes.size_1,
	backgroundColor: theme.palette.colorScheme.colorVariables.colorBorder,
	margin: `0 ${theme.palette.sizes.sizeHalf}px`,
	'&:hover': {
		cursor: 'pointer',
	},
	'&:focus-visible': {
		outline: `2px solid ${theme.palette.colorScheme.colorVariables.colorPrimary}`,
	},
}));

export const ActiveDot = styled(Dot)(({ theme }) => ({
	backgroundColor: theme.palette.colorScheme.colorVariables.colorCaption,
	pointerEvents: 'none',
	'&:hover': {
		cursor: 'auto',
	},
}));

export const MinimizedDot = styled(Dot)(({ theme }) => ({
	width: theme.palette.sizes.sizeHalf,
	height: theme.palette.sizes.sizeHalf,
}));

export const StyledButton = styled(Button)(
	({
		theme: {
			palette: { sizes, colorScheme, borderRadius },
		},
	}) => ({
		minWidth: sizes.size_6,
		width: sizes.size_6,
		height: sizes.size_5,
		padding: `${sizes.sizeHalf}px ${sizes.size_1}px`,
		borderRadius: borderRadius.commonRadius,
		'& svg': {
			fill: colorScheme.colorVariables.colorCaption,
		},
		'&:hover': {
			backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
			boxShadow: 'none',
			'-webkit-filter': 'none',
		},
		'&:focus-visible': {
			outline: `2px solid ${colorScheme.colorVariables.colorPrimary}`,
			outlineOffset: 2,
			transition: 'outline 0s',
			boxShadow: 'none',
		},
	}),
);

export const HiddenButton = styled(StyledButton)({
	visibility: 'hidden',
});

export const ArrowLeft = styled('div')({
	display: 'flex',
	transform: 'rotate(90deg)',
});

export const ArrowRight = styled('div')({
	display: 'flex',
	transform: 'rotate(-90deg)',
});
