import { default as MaterialButton, ButtonProps as MaterialButtonProps } from '@mui/material/Button';
import { FC } from 'react';

import { ButtonProgress } from '../button-progress/button-progress.component';
import { fullWidth, linkVariant, StyledSpan } from './button.styles';

export type ButtonProps = Omit<MaterialButtonProps, 'variant'> & {
	loading?: boolean;
	isFullWidth?: boolean;
	variant?: 'text' | 'outlined' | 'contained' | 'link';
};

export const Button: FC<ButtonProps> = ({
	variant = 'contained',
	color,
	size,
	disabled,
	loading,
	classes,
	isFullWidth,
	className,
	onClick,
	children,
	...props
}) => {
	const isDisabled = loading || disabled;

	const buttonVariant: MaterialButtonProps['variant'] = variant === 'link' ? 'text' : variant;

	return (
		<MaterialButton
			disableRipple
			variant={buttonVariant}
			size={size}
			color={color}
			disabled={isDisabled}
			onClick={onClick}
			className={className}
			sx={(theme) => ({
				...(isFullWidth && fullWidth),
				...(variant === 'link' && linkVariant(theme)),
			})}
			classes={classes}
			{...props}>
			<StyledSpan className={loading ? 'hidden' : ''}>{children}</StyledSpan>
			{loading && <ButtonProgress variant={variant} color={color} size={size} />}
		</MaterialButton>
	);
};
