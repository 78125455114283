import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useLiveChatFeedbackStyles = makeStyles<Theme, { oldElementHeight: number }>(
	({
		palette: {
			borderRadius,
			sizes,
			colorScheme: { colorVariables },
			boxShadow,
		},
	}) => ({
		root: {
			padding: `${sizes.size_2}px 0 0`,
			borderRadius: borderRadius.card,
			boxShadow: boxShadow.angle.xsmall,
			backgroundColor: 'white',
			border: `1px solid ${colorVariables.colorMediumShadow}`,
			overflow: 'hidden',
			minHeight: (props) => `${props.oldElementHeight}px`,
			position: 'relative',
		},
		loading: {
			position: 'absolute',
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 1,
			height: '100%',
		},
	}),
);

export const feedbackCSSTransitionStyles = makeStyles<Theme, { timeout: { enter: number; exit: number } }>(() => ({
	enter: {
		opacity: 0,
		transform: 'translateX(20px)',
	},
	enterActive: {
		opacity: 1,
		transition: 'all 0.6s',
		transform: 'translateX(0)',
	},
	exit: {
		opacity: 1,
		transform: 'translateX(0)',
	},
	exitActive: {
		opacity: 0,
		transition: 'opacity 0.3s, transform 0.6s',
		transform: 'translateX(-20px)',
	},
}));
