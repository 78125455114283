import { makeStyles } from '@mui/styles';

export const useResponseWrapperStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	button: {
		marginTop: 16,
	},
});
