import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { FC } from 'react';

import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Icon } from '../../ui-kit/icon/icon.component';
import { AvatarIcon } from '../../ui-kit/icons/avatar.icon';
import { Effect } from '../../utils/function.utils';
import {
	AvatarButtonRootStyled,
	CollapsedButtonStyled,
	HasNotificationsStyled,
	MinimizedButtonStyled,
	UncollapsedButtonStyled,
} from './avatar-button.styles';

type AvatarButtonSize = 'short' | 'tall';

interface AvatarButtonProps {
	logo?: string;
	onClick?: Effect<EventType>;
	className?: string;
	size?: AvatarButtonSize;
	isCollapsed?: boolean;
	isDisabled?: boolean;
	isMinimized?: boolean;
	hasNotifications?: boolean;
	id?: string;
	dataTestingLabel?: string;
	tabIndex?: number;
}

export const AvatarButton: FC<AvatarButtonProps> = ({
	size = 'tall',
	hasNotifications,
	isCollapsed = true,
	logo,
	dataTestingLabel,
	isDisabled,
	isMinimized,
	id,
	tabIndex,
	className,
	onClick,
}) => {
	const customLogo = logo ? `url(${logo})` : '';
	const sizeClass = size === 'tall' ? 'tall' : 'short';

	const Wrapper = isMinimized ? MinimizedButtonStyled : AvatarButtonRootStyled;

	return (
		<>
			<Wrapper
				disabled={isDisabled}
				tabIndex={tabIndex}
				id={id}
				data-testing-label={dataTestingLabel}
				aria-label={'virtual assistant'}
				className={className}
				onClick={onClick}>
				<CollapsedButtonStyled
					id={'collapsed'}
					className={sizeClass}
					customLogo={customLogo}
					isMinimized={isMinimized}
					isCollapsed={isCollapsed}>
					{!logo && <AvatarIcon />}
				</CollapsedButtonStyled>
				<UncollapsedButtonStyled id={'uncollapsed'} className={sizeClass} isCollapsed={isCollapsed}>
					<Icon
						icon={KeyboardArrowDownRoundedIcon}
						iconType={'buttonIcon'}
						size={'xLarge'}
						alt={'collapse assistant'}
					/>
				</UncollapsedButtonStyled>
				{hasNotifications && <HasNotificationsStyled />}
			</Wrapper>
		</>
	);
};
