import makeStyles from '@mui/styles/makeStyles';

export const useUserInputContainerStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		position: 'relative',
		zIndex: 3,
	},
});
