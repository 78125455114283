import { makeStyles } from '@mui/styles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../models/dimensions.model';

export const useAppointmentManagerStyles = makeStyles(
	({ palette: { sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		container: {
			width: '100%',
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
		appointmentGroup: {
			width: '100%',
		},
		slotsContainer: {
			display: 'flex',
			flexDirection: 'column',
			padding: `${sizes.size_2}px 0 ${sizes.size_3}px`,
			listStyle: 'none',
			'& > li:not(:first-child)': {
				marginTop: sizes.size_1,
			},
		},
		groupDescription: {
			zIndex: 1,
			...smallViewPort.typeSubheading1,
			paddingLeft: `${sizes.size_1}px`,
			position: 'sticky',
			display: 'block',
			top: -1,
			background: 'white',
		},
		navigationContainer: {
			position: 'relative',
			padding: `0 0 ${sizes.size_6}px`,
			'& > span': {
				display: 'block',
				textAlign: 'center',
				...smallViewPort.typeHeading2,
				[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
					...largeViewPort.typeHeading2,
				},
			},
		},
		backButton: {
			position: 'absolute',
			top: -7,
			left: -11,
			background: 'none',
			border: 0,
			boxShadow: 'none',
			width: sizes.size_6,
			height: sizes.size_5,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				top: 0,
			},
		},
		backIcon: {
			transform: 'rotate(180deg)',
		},
	}),
);
