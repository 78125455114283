import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Menu, MenuItem, PopoverProps } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';

import { Nullable } from '../../utils/types.utils';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
import { useMenuOptionsStyles } from './menu-options.styles';

interface MenuOptionClasses {
	button?: {
		opened?: string;
		closed?: string;
	};
	paper?: string;
}
interface MenuPopoverProps {
	anchorOrigin?: PopoverProps['anchorOrigin'];
	transformOrigin?: PopoverProps['transformOrigin'];
}
export interface MenuOption {
	label: string;
	action(e: React.MouseEvent<HTMLLIElement, MouseEvent>): void;
	testLabel: string;
	icon?: JSX.Element;
}

export interface MenuOptionsProps {
	options: MenuOption[];
	customClasses?: MenuOptionClasses;
	popoverProps?: MenuPopoverProps;
}

export const MenuOptions: FC<MenuOptionsProps> = ({ options, customClasses, popoverProps }) => {
	const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);

	const classes = useMenuOptionsStyles();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const optionItems = () =>
		options.map((option, index) => (
			<MenuItem
				data-testing-label={`menu-option-${option.testLabel}`}
				key={index}
				onClick={(e) => {
					option.action(e);
					handleClose();
				}}>
				<span className={classes.item}>{option.label}</span>
				{option.icon && option.icon}
			</MenuItem>
		));

	const isOpen = Boolean(anchorEl);
	const buttonClassname = classNames(
		classes.heightWrapper,
		isOpen ? customClasses?.button?.opened : customClasses?.button?.closed,
	);
	return (
		<React.Fragment>
			<Button
				onClick={handleClick}
				color={'secondary'}
				size={'medium'}
				className={buttonClassname}
				data-testing-label={'menu-options-toggle-button'}
				aria-label={'menu options'}>
				<Icon icon={MoreHorizRoundedIcon} size={'large'} iconType={'buttonIcon'} alt={'menu options'} />
			</Button>
			<Menu
				disablePortal
				data-testing-label={'menu-options-btn'}
				disableScrollLock
				anchorEl={anchorEl}
				keepMounted
				open={isOpen}
				onClose={handleClose}
				elevation={0}
				anchorOrigin={
					popoverProps?.anchorOrigin || {
						vertical: 'top',
						horizontal: 'right',
					}
				}
				transformOrigin={popoverProps?.transformOrigin || { vertical: 'bottom', horizontal: 'right' }}
				classes={{
					paper: classNames(classes.paper, customClasses?.paper),
				}}>
				{optionItems()}
			</Menu>
		</React.Fragment>
	);
};
