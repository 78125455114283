import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Drawer } from '@mui/material';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../ui-kit/button/button.component';
import { Chip } from '../../../ui-kit/chip/chip.component';
import { IconButton } from '../../../ui-kit/icon-button/icon-button.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { Effect, Lazy } from '../../../utils/function.utils';
import { useCancelAppointmentStyles } from './cancel-appointment.styles';

interface CancelAppointmentProps {
	isOpen: boolean;
	onCancel: Effect<string>;
	onClose: Lazy<void>;
}

export const CancelAppointment: FC<CancelAppointmentProps> = memo(({ isOpen, onCancel, onClose }) => {
	const { t } = useTranslation();
	const [selectedReason, setSelectedReason] = useState<string>('');
	const classes = useCancelAppointmentStyles();
	const reasonsList = [
		t('appointmentManagementWorkConflictReason', 'Conflicto con el trabajo'),
		t('appointmentManagementNoTransportReason', 'No hay transporte'),
		t('appointmentManagementSeenElsewhereReason', 'Ser visto en otra parte'),
		t('appointmentManagementHighCostReason', 'costo demasiado alto'),
		t('appointmentManagementAnxiousReason', 'Sentirse ansioso'),
		t('appointmentManagementOtherReason', 'Otra razon'),
	];

	const handleClear = (): void => {
		setSelectedReason('');
		onClose();
	};

	const handleCancel = (reason: string): void => {
		onCancel(reason);
		handleClear();
	};

	const handleSelectReason = (reason: string): void => setSelectedReason(reason === selectedReason ? '' : reason);

	const renderCancelReasons = (reasons: string[]): JSX.Element => (
		<ul className={classes.list}>
			{reasons.map((reason) => (
				<li key={reason} className={classes.listItem}>
					<Chip
						className={classes.chip}
						onClick={() => handleSelectReason(reason)}
						isSelected={reason === selectedReason}
						label={reason}
						dataTestingLabel={`appointment-manager-cancel-reason-${reason}`}
					/>
				</li>
			))}
		</ul>
	);

	return (
		<Drawer
			data-testing-label={'appointment-manager-cancel-appointment'}
			anchor={'bottom'}
			open={isOpen}
			onClose={handleClear}
			disablePortal
			disableScrollLock
			classes={{ paper: classes.paper }}>
			<div className={classes.container}>
				<IconButton
					className={classes.closeIcon}
					color={'primary'}
					aria-label={'close-cancel-appointment'}
					onClick={() => handleClear()}
					data-testing-label={'appointment-manager-close-button'}>
					<Icon icon={CloseRoundedIcon} size={'large'} alt={'minimize'} />
				</IconButton>
				<h3 className={classes.title}>
					{t('appointmentManagementCancelTitle', 'What’s the reason for canceling the appointment?')}
				</h3>
				{renderCancelReasons(reasonsList)}
				<Button
					className={classes.cancelButton}
					data-testing-label={'appointment-manager-cancel-button'}
					isFullWidth
					color={'primary'}
					disabled={!selectedReason}
					onClick={() => handleCancel(selectedReason)}>
					{t('cancelAppointment', 'Cancel appointment')}
				</Button>
			</div>
		</Drawer>
	);
});
