import { makeStyles } from '@mui/styles';

export const useDropdownStyles = makeStyles(
	({
		typography: { smallViewPort },
		palette: {
			colorScheme: { colorVariables },
			borderRadius,
			boxShadow,
			sizes,
		},
	}) => ({
		root: {
			backgroundColor: colorVariables.colorPrimarySoft,
			borderRadius: borderRadius.commonRadius,
			border: 'none',
			transition: 'all 0.8s',
			height: sizes.size_6,
			padding: `0 ${sizes.size_2}px`,
			outline: `${sizes.sizeQuarter}px solid transparent`,
			'&.Mui-focused': {
				transition: 'all 0.1s',
			},
			'&:hover': {
				transition: 'all 0.1s',
				borderColor: colorVariables.colorNote,
			},
			'&:focus-visible': {
				backgroundColor: colorVariables.colorPrimarySoft,
				outline: `${sizes.sizeQuarter}px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				borderRadius: borderRadius.commonRadius,
			},
			'&:focus:not(:focus-visible), &:active:not(:focus-visible)': {
				backgroundColor: colorVariables.colorPrimarySoft,
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimarySoft}`,
				borderRadius: borderRadius.commonRadius,
			},
			...smallViewPort.typeInput1,
			lineHeight: `${sizes.size_6}px`,
		},
		checkbox: {
			flexShrink: 0,
			width: sizes.size_3,
			height: sizes.size_3,
			position: 'relative',
			marginLeft: 'auto',
			borderRadius: borderRadius.checkBox,
		},
		select: {
			paddingRight: `${sizes.size_6}px !important`,
		},
		paper: {
			borderRadius: borderRadius.commonRadius,
			boxShadow: boxShadow.angle.large,
		},
		list: {
			paddingTop: 0,
			paddingBottom: 0,
			maxHeight: '278px',
			overflowY: 'auto',
			'& li:hover': {
				background: colorVariables.colorPrimarySoft,
			},
			'& li.Mui-selected': {
				background: colorVariables.colorPrimarySoft,
			},
			'& li.Mui-selected:hover': {
				background: colorVariables.colorPrimarySoft,
			},
		},
		listMultiple: {
			paddingTop: 0,
			paddingBottom: 0,
			maxHeight: '278px',
			overflowY: 'auto',
			'& li:hover': {
				background: colorVariables.colorPrimarySoft,
			},
			'& li.Mui-selected': {
				background: 'none',
			},
			'& li.Mui-selected:hover': {
				background: colorVariables.colorPrimarySoft,
			},
		},
		iconWrapper: {
			position: 'absolute',
			right: '10px',
		},
		placeholderTextColor: {
			'&> div:first-child': {
				color: colorVariables.colorCaption,
			},
		},

		checked: {
			backgroundColor: colorVariables.colorPrimary,
		},
		unchecked: {
			backgroundColor: 'white',
			stroke: 'hsla(231, 100%, 10%, 0.02)',
			filter: 'drop-shadow(0px 1px 5px hsla(0, 0%, 0%, 0.10))',
			strokeWidth: 1,
		},
	}),
);
