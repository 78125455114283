export type Nullable<T> = T | null;

export type NonStruct = string | number | boolean | Date;

export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends NonStruct ? T[P] : Partial<T[P]>;
};

export const isDefined = <A>(v: A | undefined | null): v is A => typeof v !== 'undefined' && v !== null;

export interface ResponseError {
	status: number;
}
