import { Theme } from '@mui/material/styles';
import { createStyles, StyleRules } from '@mui/styles';

export const styles = ({
	palette: {
		colorScheme: { colorVariables },
	},
}: Theme): StyleRules<any, string> =>
	createStyles({
		progress: {
			position: 'absolute',
			fontSize: '14px',
		},
		progressOnDark: {
			color: colorVariables.colorTextOnDark,
		},
		progressOnLight: {
			color: colorVariables.colorText,
		},
	});
