import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

import { ColorScheme, VisualConfig } from '../../models/app.model';
import { createColorTheme } from './theme.default';

interface ThemeProps {
	children: ReactNode;
	colorScheme: ColorScheme;
	visualConfig: VisualConfig;
	isGlobalStyles?: boolean;
	noAnimationTransition?: boolean;
}

export const Theme: FC<ThemeProps> = ({
	colorScheme,
	visualConfig,
	isGlobalStyles = false,
	noAnimationTransition = false,
	children,
}) => {
	const theme = createColorTheme(colorScheme, visualConfig, { isGlobalStyles, noAnimationTransition });
	return (
		<ThemeProvider theme={theme}>
			{isGlobalStyles && <CssBaseline />}
			{children}
		</ThemeProvider>
	);
};
