import { styled } from '@mui/material/styles';

export const AvatarDiv = styled('div', {
	shouldForwardProp: (prop) => prop !== 'iconPath',
})<{ iconPath?: string }>(({ theme, iconPath }) => ({
	width: 60,
	height: 60,
	backgroundColor: iconPath ? 'inherit' : theme.palette.colorScheme.colorVariables.colorPrimary,
	borderRadius: theme.palette.borderRadius.avatar,
	backgroundImage: iconPath ? `url(${iconPath})` : 'none',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: 50,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'& svg': {
		width: 40,
		height: 40,
	},
	...theme.palette.colorScheme.customStyle.header?.logo,
}));
