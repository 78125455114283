import makeStyles from '@mui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../../../models/dimensions.model';

export const useProviderCardStyles = makeStyles(
	({ palette: { sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		root: {
			padding: `${sizes.size_2}px ${sizes.size_3}px 0`,
			height: '100%',
			...smallViewPort.typeCaption1,
		},
		section: {
			paddingBottom: sizes.size_1,
		},
		providerTitle: {
			margin: 0,
			...smallViewPort.typeHeading2,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading2,
			},
		},
		ratingIcon: {
			marginLeft: -3,
			marginTop: -3,
		},
		rating: { paddingRight: sizes.sizeHalf },
		providerImage: {
			borderRadius: '50%',
			width: 60,
			height: 60,
			objectFit: 'cover',
			marginBottom: 2,
		},
		centeredInlineFlex: {
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		withSeparator: {
			'& > *:not(:last-child):after': {
				content: '"·"',
				padding: '0 3px',
			},
		},
		ratingRoot: {
			display: 'flex',
			alignItems: 'center',
		},
		actionsRoot: {
			padding: `0 ${sizes.size_2}px ${sizes.size_1}px`,
			display: 'flex',
			flexDirection: 'column',
		},
		actionButton: {
			'&:not(:last-child)': {
				marginBottom: sizes.size_1,
			},
		},
		additionlActionsWrapper: {
			paddingTop: sizes.size_1,
			display: 'flex',
		},
		additionalActionLink: {
			flex: 1,
			'&:not(:last-child)': {
				marginRight: sizes.size_1,
			},
		},
		actionLinkLabel: {
			paddingLeft: sizes.size_1,
		},
		additionalInfoRoot: {
			whiteSpace: 'nowrap',
			'& svg': {
				marginRight: 3,
				marginLeft: -2,
			},
			'&:nth-child(2n)': {
				paddingLeft: sizes.size_2,
			},
		},
		oveflowEllipsis: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	}),
);
