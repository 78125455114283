import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import classNames from 'classnames';
import { FC, memo } from 'react';

import { handleKeyPress } from '../../utils/a11y.utils';
import { Icon } from '../icon/icon.component';
import { useCardButtonStyles } from './card-button.styles';

interface DoctorSearchCardProps {
	onClick(): void;
	children: JSX.Element;
	className?: string;
	testingLabel?: string;
}

export const CardButton: FC<DoctorSearchCardProps> = memo(({ children, className, testingLabel, onClick }) => {
	const classes = useCardButtonStyles();
	const rootClassName = classNames(classes.button, className);
	return (
		<div
			className={rootClassName}
			onClick={onClick}
			tabIndex={0}
			role={'button'}
			onKeyPress={handleKeyPress(onClick)}
			data-testing-label={testingLabel}>
			{children}
			<Icon
				dataTestingLabel={'doctor-search-card-button-icon'}
				icon={KeyboardArrowRightRoundedIcon}
				iconType={'colorBorder'}
				alt={'select doctor icon'}
				className={classes.arrowIcon}
			/>
		</div>
	);
});
