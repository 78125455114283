import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TextField } from '@mui/material';
import classNames from 'classnames';
import { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../ui-kit/button/button.component';
import { Chip } from '../../../ui-kit/chip/chip.component';
import { IconButton } from '../../../ui-kit/icon-button/icon-button.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { HelpfulnessSurvey } from '../../../ui-kit/user-survey/helpfulness-survey/helpfulness-survey.component';
import { Effect } from '../../../utils/function.utils';
import { useLLMFeedbackStyles, useTextFieldStyles } from './llm-feedback.styles';

interface FeedbackData {
	feedbacks: string[];
	comment?: string;
}
interface LLMFeedbackIssue {
	label: string;
	value: string;
}
interface LLMFeedbackReportConfig {
	title: string;
	completeFeedbackTitle: string;
	options: LLMFeedbackIssue[];
	placeholder: string;
}

interface LLMFeedbackProps {
	config?: LLMFeedbackReportConfig;
	className?: string;
	onReportProblem: Effect<FeedbackData>;
	onSurvey: Effect<number>;
}

export const LLMFeedback: FC<LLMFeedbackProps> = ({ config, className, onReportProblem, onSurvey }) => {
	const [isReportProblemShown, setReportProblemShown] = useState(false);
	const [isReportProblemSubmitted, setReportProblemSubmitted] = useState(false);
	const [comment, setComment] = useState('');
	const [reportIssues, setReportIssues] = useState<LLMFeedbackIssue[]>([]);
	const classes = useLLMFeedbackStyles();
	const textFieldClasses = useTextFieldStyles();
	const { t } = useTranslation();

	const handleFeedback = (rating: number) => {
		onSurvey(rating);
		if (rating === 1 && !isReportProblemSubmitted) {
			setReportProblemShown(true);
		}
	};

	const handleProblemReport = () => {
		const commentData = comment ? { comment } : {};
		const data: FeedbackData = {
			feedbacks: reportIssues.map((issue) => issue.value),
			...commentData,
		};
		onReportProblem(data);
		setReportProblemSubmitted(true);
	};

	const handleClose = () => {
		setReportProblemShown(false);
	};

	const handleIssueSelect = (issueItem: LLMFeedbackIssue) => {
		const isCurrentItemSelected = reportIssues.some((issue) => issue.value === issueItem.value);
		if (isCurrentItemSelected) {
			const updatedIssues = reportIssues.filter((issue) => issue.value !== issueItem.value);
			setReportIssues(updatedIssues);
		} else {
			setReportIssues([...reportIssues, issueItem]);
		}
	};

	const getHeader = useCallback(
		(title: string) => (
			<div className={classes.header}>
				<h6 className={classes.title}>{title}</h6>
				<IconButton
					onClick={handleClose}
					className={classes.closeIcon}
					data-testing-label={'llm-feedback-report-problem-close-button'}>
					<Icon icon={CloseRoundedIcon} size={'large'} iconType={'inputIcon'} />
				</IconButton>
			</div>
		),
		[],
	);

	const isNoFeedbackProvided = reportIssues.length === 0 && comment.trim().length === 0;

	const getReportProblem = ({ title, completeFeedbackTitle, options, placeholder }: LLMFeedbackReportConfig) => (
		<section className={classes.root} data-testing-label={'llm-feedback-report-broblem'}>
			{!isReportProblemSubmitted ? (
				<Fragment>
					{getHeader(title)}
					<div className={classes.optionsRoot}>
						{options.map((option) => {
							const isSelected = reportIssues.some((issue) => issue.value === option.value);
							return (
								<Chip
									label={option.label}
									isSelected={isSelected}
									onClick={() => handleIssueSelect(option)}
									dataTestingLabel={`llm-feedback-report-problem-option-${option.value}${
										isSelected ? '-selected' : ''
									}`}
									key={option.value}
								/>
							);
						})}
					</div>
					<TextField
						value={comment}
						variant={'outlined'}
						multiline
						classes={textFieldClasses}
						placeholder={placeholder}
						onChange={(e) => setComment(e.currentTarget.value)}
						data-testing-label={'ai-feedback-report-problem-input'}
					/>
					<Button
						color={'primary'}
						onClick={handleProblemReport}
						className={classes.sumbitButton}
						disabled={isNoFeedbackProvided}
						data-testing-label={'ai-feedback-report-problem-submit-button'}>
						{t('feedbackSubmit')}
					</Button>
				</Fragment>
			) : (
				getHeader(completeFeedbackTitle)
			)}
		</section>
	);

	return (
		<div className={classNames(classes.wrapper, className)} data-testing-label={'llm-feedback'}>
			<HelpfulnessSurvey action={handleFeedback} />
			{config && isReportProblemShown && getReportProblem(config)}
		</div>
	);
};
