import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useWidgetLoadingStyles = makeStyles<Theme, { isExpanded: boolean }>(({ palette: { sizes } }) => ({
	root: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		flex: '1',
		display: 'flex',
		padding: `${sizes.size_1}px ${sizes.size_1}% 0`,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	botMessage: {
		width: (props) => (props.isExpanded ? 284 : 254),
		height: (props) => (props.isExpanded ? 75 : 67),
		animation: '$blinker 1s linear infinite',
		paddingLeft: sizes.size_1,
		position: 'absolute',
	},
	buttonContent: {
		display: 'flex',
		margin: `auto auto ${sizes.size_2}px auto`,
		width: 192,
	},
	animatedButton: {
		'&:not(:last-child)': {
			marginRight: sizes.sizeHalf,
		},
		height: 48,
		width: 94,
		animation: '$blinker 1s linear infinite',
	},
	'@keyframes blinker': {
		'50%': {
			opacity: 0.3,
		},
	},
}));
