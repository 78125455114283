import { styled } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const StyledSpan = styled('span')({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'&.hidden': {
		visibility: 'hidden',
	},
});

export const fullWidth = {
	width: '100%',
};
export const linkVariant = (theme: Theme) => ({
	...theme.typography.smallViewPort.typeLink2,
	padding: theme.palette.sizes.size_1,
	width: 94,
	height: theme.palette.sizes.size_6,
	textDecoration: 'underline',
	'&:hover': {
		textDecoration: 'underline',
		backgroundColor: 'transparent',
		textDecorationColor: theme.palette.colorScheme.colorVariables.colorPrimary,
		filter: 'brightness(96%)',
		transition: 'all .2s',
	},
});
