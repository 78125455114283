import { makeStyles } from '@mui/styles';

export const useChipMultiSelectStyles = makeStyles(
	({
		palette: {
			sizes,
			borderRadius,
			colorScheme: { colorVariables },
		},
	}) => {
		const checkboxBase = {
			flexShrink: 0,
			width: sizes.size_3,
			height: sizes.size_3,
			position: 'relative' as const,
			marginLeft: sizes.size_1,
			borderRadius: borderRadius.checkBox,
		};
		return {
			root: {
				display: 'flex',
				flexDirection: 'row-reverse',

				'& > span': {
					paddingLeft: 0,
					textAlign: 'left',
					paddingRight: sizes.size_1,
				},
				'& > svg': {
					marginLeft: 8,
					marginRight: 0,
					width: sizes.size_3,
					height: sizes.size_3,
				},
			},
			fullWidth: {
				maxWidth: '100%',
				width: '100%',
				justifyContent: 'space-between',
			},

			checked: {
				...checkboxBase,
				backgroundColor: colorVariables.colorPrimary,
			},
			disabled: {
				...checkboxBase,
				backgroundColor: colorVariables.colorSysOff,
			},
			unchecked: {
				...checkboxBase,
				backgroundColor: 'white',
				stroke: 'hsla(231, 100%, 10%, 0.02)',
				filter: 'drop-shadow(0px 1px 5px hsla(0, 0%, 0%, 0.10))',
				strokeWidth: 1,
			},
		};
	},
);
