import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import VideoCameraFrontRoundedIcon from '@mui/icons-material/VideoCameraFrontRounded';
import { WithStyles, withStyles } from '@mui/styles';
import classnames from 'classnames';
import { array } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { useTranslation } from 'react-i18next';

import { ProviderAvailability, ProviderReview } from '../../../models/message.model';
import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../ui-kit/button/button.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { renderArraySeparatedWithComma } from '../../../utils/array.utils';
import { createDistance } from '../../../utils/distance.utils';
import { stringToDateFormat } from '../../../utils/time.utils';
import { Nullable } from '../../../utils/types.utils';
import { ProviderInfo, ProviderResponse } from '../doctor-search.model';
import { styles } from './doctor-search-card.styles';

type HeadingLabel = 'new_patients' | 'no_new_patients' | 'virtual_vizits' | 'next_available';

const getIconByType = (type: HeadingLabel): JSX.Element => {
	switch (type) {
		case 'new_patients':
			return (
				<Icon icon={DoneRoundedIcon} size={'small'} iconType={'systemIconSuccess'} alt={'new patients icon'} />
			);
		case 'no_new_patients':
			return (
				<Icon
					icon={BlockRoundedIcon}
					size={'small'}
					iconType={'systemIconAlert'}
					alt={'no new patients icon'}
				/>
			);
		case 'virtual_vizits':
			return (
				<Icon
					icon={VideoCameraFrontRoundedIcon}
					size={'small'}
					iconType={'decorIcon'}
					alt={'virtual visit icon'}
				/>
			);
		case 'next_available':
			return (
				<Icon
					icon={EventAvailableRoundedIcon}
					size={'small'}
					iconType={'decorIcon'}
					alt={'virtual visit icon'}
				/>
			);
	}
};

interface DoctorSearchCardProps extends WithStyles<typeof styles> {
	card: ProviderInfo;
	withDistance?: boolean;
	isDetailedView?: boolean;
	shouldHideAvailability?: boolean;
	shouldHideAcceptingNewPatients?: boolean;
	onResponse: (response: ProviderResponse, e: EventType) => void;
}

export const DoctorSearchCard = withStyles(styles)(({
	card,
	withDistance = true,
	onResponse,
	classes,
	isDetailedView = false,
	shouldHideAvailability = false,
	shouldHideAcceptingNewPatients = false,
}: DoctorSearchCardProps) => {
	const { t } = useTranslation();
	const { data, responses } = card;
	const {
		distance,
		reviews,
		languages,
		availability,
		imageUrl,
		name,
		specialties,
		acceptingVirtualVisits,
		acceptingNewPatients,
	} = data;

	const renderPersonalInfo = (name: string, img?: Nullable<string>): JSX.Element => (
		<header>
			{img && (
				<img
					src={img}
					alt={'provider'}
					className={classes.providerImage}
					data-testing-label={`doctor-search-card-image-${name}`}
				/>
			)}
			<h4 className={classnames(classes.providerTitle, classes.oveflowEllipsis)}>{name}</h4>
		</header>
	);

	const createRating = (reviews: ProviderReview): JSX.Element => {
		const roundedRating = reviews.ratingAverage.toFixed(1);
		return (
			<span className={classes.centeredInlineFlex}>
				<Icon
					icon={StarRateRoundedIcon}
					size={'small'}
					iconType={'decorIcon'}
					alt={'rating icon'}
					className={classes.ratingIcon}
				/>
				<strong className={classes.rating} data-testing-label={`doctor-search-card-${name}-rating`}>
					{roundedRating}
				</strong>
				<span data-testing-label={`doctor-search-card-${name}-reviews`}>({reviews.count})</span>
			</span>
		);
	};

	const renderList = (list: string[], testingLabel?: string): JSX.Element => {
		const specialitiesString = pipe(
			list,
			array.filter((item) => item !== ''),
			renderArraySeparatedWithComma,
		);

		return (
			<div className={classes.oveflowEllipsis} data-testing-label={testingLabel}>
				{specialitiesString}
			</div>
		);
	};

	const createNewPatients = (acceptingNewPatients: boolean): JSX.Element => {
		const acceptsPatientsInfo = {
			iconType: 'new_patients' as const,
			label: t('doctorSearchAcceptsNewPatients', 'Accepts New Patients'),
			className: classes.successColor,
		};
		const notAcceptsPatientsInfo = {
			iconType: 'no_new_patients' as const,
			label: t('doctorSearchNoNewPatients', 'No New Patients'),
			className: classes.alertColor,
		};
		const patientsInfo = acceptingNewPatients ? acceptsPatientsInfo : notAcceptsPatientsInfo;

		const icon = getIconByType(patientsInfo.iconType);

		return (
			<span
				data-testing-label={`doctor-search-${acceptingNewPatients ? 'accepts' : 'no'}-new-patients`}
				className={classnames(classes.additionalInfoItem, classes.centeredInlineFlex, patientsInfo.className)}
				id={'doctor-search-new-patients-label'}>
				{icon}
				{patientsInfo.label}
			</span>
		);
	};

	const createNextAvailable = (availability: ProviderAvailability): JSX.Element => {
		const icon = getIconByType('next_available');
		const label = availability.nextDate
			? `${t('doctorSearchAvailable', 'Available')} ${stringToDateFormat(availability.nextDate)}`
			: t('availableWithin', { days: availability.availableInNextDays });
		return (
			<span
				data-testing-label={`doctor-search-card-availability-${
					availability.nextDate || availability.availableInNextDays
				}`}
				className={classnames(classes.additionalInfoItem, classes.centeredInlineFlex)}
				id={'doctor-search-next-available-label'}>
				{icon}
				<span>{label}</span>
			</span>
		);
	};

	const createVirtualVisit = (): JSX.Element => {
		const icon = getIconByType('virtual_vizits');
		return (
			<span
				className={classnames(classes.additionalInfoItem, classes.centeredInlineFlex)}
				id={'doctor-search-virtual-visit-label'}>
				{icon}
				{t('doctorSearchVirtualVisits', 'Virtual visits available')}
			</span>
		);
	};

	const renderRatingAndLanguages = (languages: string[], reviews?: Nullable<ProviderReview>): JSX.Element => (
		<div className={classnames(classes.ratingRoot, classes.withSeparator)}>
			{reviews && createRating(reviews)} {renderList(languages, 'doctor-search-card-languages')}
		</div>
	);

	const renderAdditionalInfo = (
		acceptingNewPatients: Nullable<boolean>,
		acceptingVirtualVisits: Nullable<boolean>,
		availability?: Nullable<ProviderAvailability>,
	): JSX.Element | null => {
		const newPatientsLabel =
			acceptingNewPatients === null || shouldHideAcceptingNewPatients
				? null
				: createNewPatients(acceptingNewPatients);
		const virtualVisitsLabel = acceptingVirtualVisits ? createVirtualVisit() : null;
		const nextAvailableLabel = availability && !shouldHideAvailability ? createNextAvailable(availability) : null;
		return (
			(newPatientsLabel || virtualVisitsLabel || nextAvailableLabel) && (
				<div className={classes.section}>
					<div
						className={classes.additionalInfoRoot}
						data-testing-label={'doctor-search-card-additional-info'}>
						{newPatientsLabel} {virtualVisitsLabel} {nextAvailableLabel}
					</div>
				</div>
			)
		);
	};

	const renderActions = (responses: ProviderResponse[]): Nullable<JSX.Element> => {
		const responsesToMainActions = isDetailedView ? responses : responses.slice(0, 1);
		return responsesToMainActions.length ? (
			<div className={classes.actionsRoot}>
				{responsesToMainActions.map((response) => (
					<Button
						key={response.responseContext}
						color={'secondary'}
						onClick={(e) => {
							e.stopPropagation();
							onResponse(response, e);
						}}
						data-testing-label={`doctor-search-card-${
							isDetailedView ? 'detailed-view-' : ''
						}main-action-${response.type}`}
						className={classes.actionButton}>
						{response.content}
						{response.type === 'webUrlExternal' && (
							<Icon
								size={'large'}
								iconType={'buttonIcon'}
								icon={OpenInNewRoundedIcon}
								className={classes.externalActionIcon}
							/>
						)}
					</Button>
				))}
			</div>
		) : null;
	};

	const distanceLabel = distance && withDistance ? createDistance(distance, t) : '';
	return (
		<div
			className={classes.root}
			data-testing-label={`doctor-search-card-${name}${isDetailedView ? '-detailed' : ''}`}>
			<div className={classes.section}>
				{renderPersonalInfo(name, imageUrl)}
				{renderList([distanceLabel, ...specialties])}
				{renderRatingAndLanguages(languages, reviews)}
			</div>
			{renderAdditionalInfo(acceptingNewPatients, acceptingVirtualVisits, availability)}
			{renderActions(responses)}
		</div>
	);
});
