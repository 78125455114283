import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { FC, memo, useCallback, useContext, useEffect, useState } from 'react';

import { ConfigContext } from '../../context/config.context';
import { LiveChatProvider } from '../../context/live-chat-context';
import { NotificationProvider } from '../../context/notification.context';
import { ServicesContext } from '../../context/services.context';
import { SessionContext } from '../../context/session.context';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import { WebTrackerAction } from '../../models/trackers.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { FancyBox } from '../../ui-kit/fancy-box/fancy-box.component';
import { PoweredByFabricLabel } from '../../ui-kit/powered-by-fabric/powered-by-fabric.component';
import { Theme } from '../../ui-kit/theme/theme.component';
import { generateFabricClassName } from '../../ui-kit/theme/theme.model';
import { createAnimation } from '../../utils/animations.utils';
import { isEmbedWidget } from '../../utils/embed.utils';
import { Lazy } from '../../utils/function.utils';
import { CallToActionButtonContainer } from '../call-to-action-button/call-to-action-button.container';
import { CollapsedWidgetButtonConfig } from '../collapsed-widget/collapsed-widget.model';
import { WidgetButton } from '../widget-button/widget-button.component';
import { WidgetFrame } from '../widget-iframe/widget-frame.component';
import { WidgetWrapperContainer } from '../widget-wrapper/widget-wrapper.container';
import { checkAutoOpen, FONTS_URL } from './app.model';

interface AppProps {
	modalUrl: string;
	isModalOpen: boolean;
	onCloseModal: Lazy<void>;
}

// Create Emotion Cache with a custom key and use it to generate class names
const cache = createCache({
	key: 'fabric-fd',
	prepend: true, // Ensures Emotion styles are inserted before other styles in <head>
});

export const App: FC<AppProps> = memo(({ modalUrl, isModalOpen, onCloseModal }) => {
	const {
		appConfig: { animations, settings, colorScheme, visualConfig },
		appOptions: { fullScreen, gyAutoOpen, events, noIframe, noAnimation },
	} = useContext(ConfigContext);

	const isEmbed = isEmbedWidget();

	const [isOpen, handleOpenWidgetState] = useState<boolean>(gyAutoOpen || (!isEmbed && fullScreen));
	const [isWidgetOpenedOnce, handleWidgetOpenedOnceState] = useState<boolean>(gyAutoOpen);
	const [collapsedWidgetActionItemSelected, handleCollapsedWidgetActionItemSelected] =
		useState<CollapsedWidgetButtonConfig>();
	const { webTrackerService } = useContext(ServicesContext);
	const {
		state: { onSendMessage, sessionToken },
	} = useContext(SessionContext);

	const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

	const handleOpenState =
		(state: boolean, isCTAButton = false) =>
		(e: EventType, collapsedWidgetActionItemSelected?: CollapsedWidgetButtonConfig) => {
			if (!isCTAButton || !isOpen) {
				const action: WebTrackerAction = state ? 'onOpenChat' : 'onCloseChat';
				webTrackerService.sendEvent(action, e);
				events.onWidgetVisibilityChange(state);
				handleOpenWidgetState(state);
			}

			if (collapsedWidgetActionItemSelected) {
				sessionToken && isOpen
					? onSendMessage(
							collapsedWidgetActionItemSelected.payload,
							collapsedWidgetActionItemSelected.renderedTitle,
							true,
						)
					: handleCollapsedWidgetActionItemSelected(collapsedWidgetActionItemSelected);
			}

			if (!isWidgetOpenedOnce) {
				handleWidgetOpenedOnceState(true);
			}
		};

	const autoOpenHandler = useCallback((state: boolean, timeOut: number) => {
		setTimeout(() => {
			handleOpenWidgetState(state);
			events.onWidgetVisibilityChange(state);
		}, timeOut);
	}, []);

	const animationsList = animations.map(createAnimation);

	const buttonAnimation = animationsList.find((animation) => animation.element === 'openToggle');

	useEffect(() => {
		checkAutoOpen(settings.autoOpen, autoOpenHandler);
	}, [settings.autoOpen.afterMs]);

	useEffect(() => {
		if (noIframe) {
			const styleElement = document.createElement('style');
			styleElement.innerHTML = FONTS_URL;
			document.head.prepend(styleElement);
		}
	}, [noIframe]);

	const appContent = noIframe ? (
		<Theme colorScheme={colorScheme} visualConfig={visualConfig} isGlobalStyles>
			<WidgetWrapperContainer
				onWidgetClose={handleOpenState(false)}
				isOpen={isOpen}
				collapsedWidgetActionItemSelected={collapsedWidgetActionItemSelected}
				isWidgetDisabled={isModalOpen}
			/>
		</Theme>
	) : (
		<WidgetFrame isOpen={isOpen}>
			<Theme
				colorScheme={colorScheme}
				visualConfig={visualConfig}
				isGlobalStyles
				noAnimationTransition={noAnimation}>
				<WidgetWrapperContainer
					onWidgetClose={handleOpenState(false)}
					isOpen={isOpen}
					collapsedWidgetActionItemSelected={collapsedWidgetActionItemSelected}
					isWidgetDisabled={isModalOpen}
				/>
			</Theme>
		</WidgetFrame>
	);

	return (
		<CacheProvider value={cache}>
			<StylesProvider generateClassName={generateFabricClassName}>
				<Theme colorScheme={colorScheme} visualConfig={visualConfig} noAnimationTransition={noAnimation}>
					<LiveChatProvider>
						<CallToActionButtonContainer onClick={handleOpenState(!isOpen, true)} />
						<FancyBox url={modalUrl} onClose={onCloseModal} isOpen={isModalOpen} />
						<NotificationProvider>{appContent}</NotificationProvider>
						{!(fullScreen || isMobile) && <PoweredByFabricLabel isWidgetOpen={isOpen} />}
						{!(fullScreen && isOpen) && (
							<WidgetButton
								isDisabled={isModalOpen}
								isWidgetClosed={!isOpen}
								isWidgetOpenedOnce={isWidgetOpenedOnce}
								onClick={handleOpenState(!isOpen)}
								buttonAnimation={buttonAnimation}
								type={visualConfig.collapsedWidgetType}
								logo={visualConfig.buttonAvatar}
							/>
						)}
					</LiveChatProvider>
				</Theme>
			</StylesProvider>
		</CacheProvider>
	);
});
