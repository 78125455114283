import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BotMessageLoading = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 254 67'} width={'254px'} height={'67px'} {...props}>
		<title>Skeleton Message</title>
		<g id={'Widget'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g id={'Loading'} transform={'translate(-40.000000, -419.000000)'} fill={'#EFEFF1'}>
				<path
					d={
						'M42,446 L292,446 C293.104569,446 294,446.895431 294,448 L294,457 C294,458.104569 293.104569,459 292,459 L42,459 C40.8954305,459 40,458.104569 40,457 L40,448 C40,446.895431 40.8954305,446 42,446 Z M42,473 L212,473 C213.104569,473 214,473.895431 214,475 L214,484 C214,485.104569 213.104569,486 212,486 L42,486 C40.8954305,486 40,485.104569 40,484 L40,475 C40,473.895431 40.8954305,473 42,473 Z M42,419 L252,419 C253.104569,419 254,419.895431 254,421 L254,430 C254,431.104569 253.104569,432 252,432 L42,432 C40.8954305,432 40,431.104569 40,430 L40,421 C40,419.895431 40.8954305,419 42,419 Z'
					}
					id={'Skeleton-Message'}
				/>
			</g>
		</g>
	</SvgIcon>
);
