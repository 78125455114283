import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCalendarHeaderRoot = styled(Box)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		display: 'flex',
		justifyContent: 'space-between',
		padding: `${sizes.size_1}px ${sizes.size_2}px`,
		alignItems: 'center',
	}),
);
