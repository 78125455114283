import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const usePoweredByFabricStyles = makeStyles<Theme, { isWidgetOpen: boolean }>(
	({
		palette: {
			sizes,
			colorScheme: {
				colorVariables,
				fontFamilyRegular,
				customStyle,
				chatWindowRightPosition,
				chatWindowBottomPosition,
			},
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		poweredByLabel: {
			textAlign: 'center',
			fontFamily: fontFamilyRegular,
			margin: 0,
			position: 'fixed',
			opacity: (props) => (props.isWidgetOpen ? 1 : 0),
			textTransform: 'uppercase',
			fontWeight: 500,
			fontSize: 10,
			color: colorVariables.colorNote,
			letterSpacing: '0.9px',
			lineHeight: 1.2,
			transition: noAnimationTransition ? 'initial' : 'all 0.4s ease',
			width: sizes.size_50,
			right: chatWindowRightPosition,
			bottom: (props) =>
				props.isWidgetOpen ? `calc(${chatWindowBottomPosition} - ${sizes.size_2}px)` : -sizes.size_2,
			'@media (max-height: 500px)': {
				bottom: (props) => (props.isWidgetOpen ? `${sizes.size_1}px !important` : -sizes.size_2),
			},
		},
		fabricLink: {
			color: colorVariables.colorNote,
			marginLeft: 2,
			textTransform: 'uppercase',
			textDecoration: 'none',
			letterSpacing: '0.9px',
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary} !important`,
				transition: 'outline 0s',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none !important',
				transition: 'none !important',
			},
		},
		customStyles: {
			...customStyle.poweredBy,
		},
	}),
);
