import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useImageUploaderContainerStyles = makeStyles<Theme, { isWidgetDisabled: boolean }>(
	({ palette: { sizes } }) => ({
		container: {
			padding: `0 ${sizes.size_1}%`,
		},
		responses: {
			padding: `${sizes.size_1} 0 ${sizes.size_2}`,
		},
		quickResponseButton: {
			width: '100%',
		},
	}),
);
