import { ButtonProps } from '@mui/material/Button';
import { FC, memo } from 'react';

import { CollapsedWidgetCallActionItem } from '../../../context/api-service/api-service.model';
import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../ui-kit/button/button.component';
import { createAdditionalClasses } from './collapsed-widget-button.styles';

type CollapsedButtonProps = ButtonProps & {
	callToAction: CollapsedWidgetCallActionItem;
	index: number;
	transition: string;
	onClick(e: EventType, collapsedWidgetSelectedActionItem?: CollapsedWidgetCallActionItem): void;
};

export const CollapsedButton: FC<CollapsedButtonProps> = memo(
	({ index, transition, callToAction, onClick, ...props }) => {
		const buttonClasses = createAdditionalClasses(callToAction, transition)();

		return (
			<Button
				key={index}
				className={buttonClasses.root}
				data-payload={callToAction.payload}
				onClick={(e: EventType) => onClick(e, callToAction)}
				{...props}>
				{callToAction.renderedTitle}
			</Button>
		);
	},
);
