import { Box, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AvatarButtonRootStyled = styled(ButtonBase)(
	({
		theme: {
			palette: { colorScheme, borderRadius },
		},
	}) => ({
		padding: 0,
		borderRadius: borderRadius.widgetButton,
		transition: 'all 0.4s',
		boxShadow: 'none',
		backgroundColor: 'transparent',
		'&:hover': {
			boxShadow: 'none',
			transition: 'all 0.2s',
			'& div:not(.minimized)': {
				filter: 'brightness(92%)',
				boxShadow: 'none',
			},
		},
		'&:focus-visible': {
			outline: `2px solid ${colorScheme.colorVariables.colorPrimary} !important`,
			outlineOffset: 2,
			transition: 'outline 0s',
		},
		'&:focus:not(:focus-visible)': {
			outline: 'none !important',
			boxShadow: 'none',
			backgroundColor: 'transparent',
			border: 'none',
		},
	}),
);

export const BackgroundWrapperStyled = styled(Box)(
	({
		theme: {
			palette: { borderRadius },
		},
	}) => ({
		borderRadius: borderRadius.widgetButton,
		transition: 'all 0.2s',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
);

export const CollapsedButtonStyled = styled(BackgroundWrapperStyled, {
	shouldForwardProp: (prop) => prop !== 'customLogo' && prop !== 'isCollapsed' && prop !== 'isMinimized',
})<{ customLogo: string; isCollapsed: boolean; isMinimized?: boolean }>(
	({
		customLogo,
		isCollapsed,
		isMinimized,
		theme: {
			palette: {
				colorScheme: { colorVariables, customStyle },
				boxShadow,
				sizes,
			},
		},
	}) => ({
		backgroundColor: colorVariables.colorPrimary,
		backgroundImage: customLogo,
		backgroundSize: 72,

		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		boxShadow: boxShadow.angle.xlarge,
		opacity: isCollapsed ? 1 : 0,
		...customStyle.collapsedChatButton,
		'&.tall': {
			width: sizes.size_10,
			height: sizes.size_10,
			'& svg': {
				width: sizes.size_5,
				height: sizes.size_5,
			},
		},
		'&.short': {
			width: sizes.size_10,
			height: isMinimized ? sizes.size_3 : 60,
		},
	}),
);

export const UncollapsedButtonStyled = styled(BackgroundWrapperStyled, {
	shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(
	({
		isCollapsed,
		theme: {
			palette: {
				colorScheme: { colorVariables, customStyle },
				sizes,
			},
		},
	}) => ({
		boxShadow: 'none',
		position: 'absolute',
		opacity: isCollapsed ? 0 : 1,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: colorVariables.colorPrimarySoft,
		...customStyle.openedChatButton,
		'&.tall': {
			width: sizes.size_10,
			height: sizes.size_10,
		},
		'&.short': {
			width: sizes.size_10,
			height: 60,
		},
	}),
);

export const MinimizedButtonStyled = styled(BackgroundWrapperStyled)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		width: sizes.size_3,
		minWidth: sizes.size_3,
		height: sizes.size_3,
		opacity: 0.64,
		backgroundSize: sizes.size_2,
		transition: 'all 0.6s',
		'& svg': {
			width: sizes.size_2,
			height: sizes.size_2,
			transition: 'all 0.6s',
		},
		'& div': {
			width: sizes.size_3,
			minWidth: sizes.size_3,
			height: sizes.size_3,
			backgroundSize: sizes.size_2,
			transition: 'all 0.6s',
		},
		'&.short': {
			width: sizes.size_2,
			minWidth: sizes.size_2,
			height: 50,
			'& svg': {
				width: sizes.size_1,
				height: sizes.size_1,
			},
			'& div': {
				width: sizes.size_2,
				height: 50,
				backgroundSize: sizes.size_1,
			},
			'&:hover': {
				width: sizes.size_3,
				minWidth: sizes.size_3,
				height: 60,
				'& svg': {
					width: sizes.size_2,
					height: sizes.size_2,
				},
				'& div': {
					width: sizes.size_3,
					height: 60,
					backgroundSize: sizes.size_2,
				},
			},
		},
		'&:hover': {
			opacity: 1,
			width: sizes.size_4,
			minWidth: sizes.size_4,
			height: sizes.size_4,
			transition: 'all 0.2s',
			'& svg': {
				width: 22,
				height: 22,
				transition: 'width 0.2s, height 0.2s',
			},
			'& div': {
				transition: 'all 0.2s',
				width: sizes.size_4,
				minWidth: sizes.size_4,
				height: sizes.size_4,
				backgroundSize: sizes.size_3,
			},
		},
	}),
);

export const HasNotificationsStyled = styled(Box)(
	({
		theme: {
			palette: {
				colorScheme: { colorVariables },
			},
		},
	}) => ({
		content: '""',
		position: 'absolute',
		width: 17,
		height: 17,
		borderRadius: '50%',
		left: 0,
		top: 0,
		backgroundColor: colorVariables.colorSysWarning,
	}),
);
