import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import React, { FC, Fragment, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DoctorSearchOption } from '../../models/message.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../ui-kit/button/button.component';
import { CardButton } from '../../ui-kit/card-button/card-button.component';
import { IconButton } from '../../ui-kit/icon-button/icon-button.component';
import { Icon } from '../../ui-kit/icon/icon.component';
import { LoadingIndicator } from '../../ui-kit/loading-indicator/loading-indicator.component';
import { LoadingListMask } from '../../ui-kit/loading-list-mask/loading-list-mask.component';
import { StackPanel } from '../../ui-kit/stack-panel /stack-panel.component';
import { sizes, SizesKeys, TransitionConfig } from '../../ui-kit/theme/theme.model';
import { isEqualArrays } from '../../utils/array.utils';
import { Lazy } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { DoctorSearchCard } from './doctor-search-card/doctor-search-card.component';
import { DoctorSearchDetails } from './doctor-search-details/doctor-search-details.component';
import { DoctorSearchEmpty } from './doctor-search-empty/doctor-search-empty.component';
import { DoctorSearchFilterGroup, DoctorSearchFilters } from './doctor-search-filters/doctor-search-filters.component';
import { FiltersTypeUpdate, ProviderInfo, ProviderResponse } from './doctor-search.model';
import { useDoctorSearchStyles } from './doctor-search.styles';

const getStackPanelCustomStyles = (sizes: Record<SizesKeys, number>, transitionConfig: TransitionConfig) => ({
	main: {
		padding: `0 ${sizes.size_5}px`,
		width: '100%',
		maxWidth: '100%',
		top: 0,
		overflowY: 'auto',
		borderRight: 'none',
	},
	transition: {
		enter: {
			transform: 'translateX(100%)',
		},
		enterActive: {
			transform: 'translateX(0%)',
			transition: `all ${transitionConfig.transitionInDuration}ms`,
		},
		exit: {
			transform: 'translateX(0%)',
		},
		exitActive: {
			transform: 'translateX(100%)',
			transition: `all ${transitionConfig.transitionOutDuration}ms`,
		},
	},
});

export interface DoctorSearchProps {
	doctorsList: ProviderInfo[];
	filtersList: DoctorSearchFilterGroup[];
	initialFiltersList: DoctorSearchFilterGroup[];
	rootRef?: React.RefObject<HTMLDivElement>;
	shouldShowLoadingMask: boolean;
	isFetchingMoreDoctors: boolean;
	isMoreDoctorsAvailable: boolean;
	isEmptyByFilters: boolean;
	isError: boolean;
	totalNumberOfDoctors: number;
	filtersDescription: Nullable<string>;
	options?: Nullable<Partial<DoctorSearchOption>>;
	onUpdateFilters(filters: DoctorSearchFilterGroup[], type?: FiltersTypeUpdate): void;
	onResponse(response: ProviderResponse, e: EventType): void;
	onLoadMoreDoctors: Lazy<void>;
	onOpenDetailedView: Lazy<void>;
}

export const DoctorSearch: FC<DoctorSearchProps> = memo(
	({
		doctorsList,
		rootRef,
		shouldShowLoadingMask,
		isFetchingMoreDoctors,
		isMoreDoctorsAvailable,
		isEmptyByFilters,
		isError,
		filtersList,
		initialFiltersList,
		filtersDescription,
		totalNumberOfDoctors,
		options,
		onUpdateFilters,
		onResponse,
		onLoadMoreDoctors,
		onOpenDetailedView,
	}) => {
		const [selectedDoctor, setSelectedDoctor] = useState<Nullable<ProviderInfo>>(null);
		const [isFiltersShown, setFiltersShown] = useState(false);

		const { t } = useTranslation();
		const {
			palette: { transitionConfig },
		} = useTheme();
		const classes = useDoctorSearchStyles();

		const handleClearSelectedDoctor = () => setSelectedDoctor(null);

		const numberOfAvailableDoctorsLocalizationKey =
			totalNumberOfDoctors === 1 ? 'numberOfResults' : 'numberOfResults_plural';

		const numberOfAvailableDoctorsLabel = t(numberOfAvailableDoctorsLocalizationKey, {
			doctorsNumber: totalNumberOfDoctors,
		});
		const isFiltersChanged = !isEqualArrays(filtersList, initialFiltersList);

		const listHeader = useMemo(
			() => (
				<div className={classes.listHeader}>
					<span className={classes.numberOfDoctors}>{numberOfAvailableDoctorsLabel}</span>
					{filtersList.length > 0 && (
						<IconButton
							className={classes.filtersIcon}
							color={'primary'}
							aria-label={'doctor-search-filters'}
							onClick={() => setFiltersShown(true)}
							data-testing-label={`doctor-search-filters-button-${
								isFiltersChanged ? 'changed' : 'default'
							}`}>
							<Icon icon={TuneRoundedIcon} size={'medium'} alt={'minimize'} />
							<span>{t('doctorSearchFilterLabel', 'Filter')}</span>
							{isFiltersChanged && (
								<Icon
									className={classes.filledFiltersIcon}
									icon={CircleRoundedIcon}
									size={'small'}
									iconType={'buttonIcon'}
								/>
							)}
						</IconButton>
					)}
				</div>
			),
			[numberOfAvailableDoctorsLabel, isFiltersChanged, filtersList.length],
		);

		const handleCloseStackPanel = (): void => {
			handleClearSelectedDoctor();
			setFiltersShown(false);
		};

		const handleResponse = (response: ProviderResponse, e: EventType) => {
			if (response.type !== 'webUrlExternal') {
				handleCloseStackPanel();
			}
			onResponse(response, e);
		};

		const renderDoctorsList = (
			doctorsList: ProviderInfo[],
			options?: Nullable<Partial<DoctorSearchOption>>,
		): JSX.Element => (
			<Fragment>
				{listHeader}
				{isEmptyByFilters || isError ? (
					<DoctorSearchEmpty
						onAction={() =>
							isEmptyByFilters
								? onUpdateFilters(initialFiltersList, 'RESET')
								: onUpdateFilters([...filtersList], 'TRY_AGAIN')
						}
						type={isEmptyByFilters ? 'EMPTY' : 'ERROR'}
					/>
				) : (
					<div>
						<ul className={classes.container} data-testing-label={'doctor-list-container'}>
							{doctorsList.map((doctorsListItem) => (
								<li key={doctorsListItem.data.name} data-testing-label={'doctor-search-card-list-item'}>
									<CardButton
										onClick={() => {
											setSelectedDoctor(doctorsListItem);
											onOpenDetailedView();
										}}
										className={classes.doctorCard}
										testingLabel={`doctor-search-card-button-${doctorsListItem.data.name}`}>
										<DoctorSearchCard
											card={doctorsListItem}
											onResponse={handleResponse}
											shouldHideAcceptingNewPatients={options?.hideAcceptingNewPatients}
											shouldHideAvailability={options?.hideAvailability}
										/>
									</CardButton>
								</li>
							))}
						</ul>
					</div>
				)}
			</Fragment>
		);

		const stackPanelCustomStyles = useMemo(
			() => getStackPanelCustomStyles(sizes, transitionConfig),
			[sizes, transitionConfig],
		);

		const getStackPanelHeader = (type: 'filters' | 'doctor'): JSX.Element => (
			<div className={classes.navigationContainer}>
				{type === 'doctor' ? (
					<Fragment>
						<IconButton
							className={classes.backButton}
							aria-label={'close-doctor-details'}
							data-testing-label={'doctor-search-close-detailed-view-button'}
							onClick={handleCloseStackPanel}>
							<Icon
								icon={KeyboardArrowRightRoundedIcon}
								size={'large'}
								alt={'close doctor details'}
								className={classes.backIcon}
							/>
						</IconButton>
						<span>{t('doctorSearchDoctors', 'Doctors')}</span>
					</Fragment>
				) : (
					<Fragment>
						<span>{t('doctorSearchFilters', 'Filters')}</span>
						<IconButton
							className={classes.backButton}
							aria-label={'close-filters'}
							data-testing-label={'doctor-search-close-filters-button'}
							onClick={handleCloseStackPanel}>
							<Icon icon={CloseRoundedIcon} alt={'close filters'} className={classes.backIcon} />
						</IconButton>
					</Fragment>
				)}
			</div>
		);

		const handleUpdateFilters = (filters: DoctorSearchFilterGroup[], isReset = false): void => {
			setFiltersShown(false);
			onUpdateFilters(filters, isReset ? 'RESET' : 'FILTERS_APPLY');
		};

		const renderShowMoreButton = (isFetchingMoreDoctors: boolean) => (
			<div className={classes.showMoreButtonContainer}>
				{isFetchingMoreDoctors && (
					<span
						className={classes.loadingIndicator}
						data-testing-label={'doctor-search-show-more-button-loading-indicator'}>
						<LoadingIndicator />
					</span>
				)}
				<Button
					isFullWidth
					data-testing-label={'doctor-search-show-more-button'}
					variant={'text'}
					className={classNames(classes.showMoreButton, isFetchingMoreDoctors && classes.loadingButton)}
					onClick={onLoadMoreDoctors}
					disabled={isFetchingMoreDoctors}>
					{t('doctorSearchShowMore', 'Show more')}
				</Button>
			</div>
		);

		return (
			<Fragment>
				{shouldShowLoadingMask ? <LoadingListMask /> : renderDoctorsList(doctorsList, options)}
				{!shouldShowLoadingMask && isMoreDoctorsAvailable && renderShowMoreButton(isFetchingMoreDoctors)}
				<StackPanel
					hasOverlay={false}
					isOpen={!!selectedDoctor || isFiltersShown}
					onClose={handleCloseStackPanel}
					customStyle={stackPanelCustomStyles}
					rootRef={rootRef}>
					{getStackPanelHeader(isFiltersShown ? 'filters' : 'doctor')}
					{selectedDoctor ? (
						<DoctorSearchDetails
							doctorInfo={selectedDoctor}
							onResponse={handleResponse}
							options={options}
						/>
					) : (
						<DoctorSearchFilters
							filtersList={filtersList}
							initialFiltersList={initialFiltersList}
							description={filtersDescription}
							onUpdate={handleUpdateFilters}
						/>
					)}
				</StackPanel>
			</Fragment>
		);
	},
);
