import { makeStyles } from '@mui/styles';

export const useLocationFinderrendererStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
		},
	}) => ({
		buttonWrapper: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			'& > div': {
				marginBottom: sizes.size_1,
				'&:not(:last-child)': {
					marginRight: sizes.sizeHalf,
				},
			},
		},
		locationIcon: {
			color: colorVariables.colorPrimary,
		},
	}),
);
