import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { setAlpha } from '../../utils/colors.utils';

export const useLiveChatStyles = makeStyles<Theme, { isLiveChatSessionOver: boolean }>(
	({ palette: { sizes, colorScheme } }) => ({
		// TODO: review names
		'@keyframes slide-down': {
			'100%': {
				top: 0,
			},
		},
		'@keyframes fade-in': {
			'100%': {
				opacity: 1,
			},
		},
		'@keyframes fade-out': {
			'100%': {
				opacity: 0,
			},
		},
		'@keyframes scaleSide': {
			'0%': {
				transform: 'scale3d(0.5, 0.5 ,0.5)',
			},
			'100%': {
				transform: 'scale3d(1,1,1)',
			},
		},
		'@keyframes appear': {
			'0%': {
				opacity: 0,
			},
			'100%': {
				opacity: 1,
			},
		},
		'@keyframes grow': {
			'100%': {
				maxHeight: 1000,
			},
		},
		'@keyframes systemGrow': {
			'100%': {
				maxHeight: 100,
			},
		},
		'@keyframes slide-up': {
			'0%': {
				transform: 'translateY(0)',
			},
			'100%': {
				transform: 'translateY(-10%)',
			},
		},
		'@keyframes slide': {
			'0%': {
				top: 30,
			},
			'100%': {
				top: 0,
			},
		},
		'@keyframes bounceIn': {
			'0%': {
				transform: 'translateY(-2px)',
			},
			'100%': {
				transform: 'translateY(0px)',
			},
		},
		'@keyframes slide-top': {
			'100%': {
				top: -100,
			},
		},
		root: {
			textAlign: 'center',
			padding: (props) => `${props.isLiveChatSessionOver ? 0 : sizes.size_10}px 0 0`,
			height: '100%',
			overflowY: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
		},
		content: {
			opacity: 0,
			animationDelay: '300ms',
			animation: '$fade-in 2.5s forwards',

			overflowY: 'scroll',
			marginBottom: (props) =>
				props.isLiveChatSessionOver ? 0 : `calc(${sizes.size_1}% + ${sizes.size_3 + sizes.sizeQuarter}px)`,
			padding: `0 ${sizes.size_1}% ${sizes.size_4 + sizes.sizeHalf}px`,
			'&::-webkit-scrollbar': {
				display: 'block',
				width: 5,
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: colorScheme.colorVariables.colorBorder,
				borderRadius: 5,
				borderRight: 'none',
				borderLeft: 'none',
			},
			'&::-webkit-scrollbar-track-piece:end': {
				background: 'transparent',
			},
		},
		statusControl: {
			position: 'absolute',
			left: sizes.size_1,
			right: sizes.size_1,
			backgroundColor: 'white',
			width: 'auto',
			zIndex: 1,
			opacity: 0,
			top: -100,
			animationDelay: '1s',
			animation: '$slide-down 1s forwards, $fade-in 2s forwards',
		},
		statusControlHidden: {
			top: 0,
			opacity: 1,
			animation: '$fade-out 1s forwards, $slide-top .67s forwards',
		},
		statusControlAnimated: {
			opacity: 1,
			top: 0,
		},
		greeting: {
			flex: 1,
			boxShadow: 'none',

			opacity: 0,
			animationDelay: '300ms',
			animation: '$fade-in 2.5s forwards',
		},
		systemInfoMessage: {
			marginBottom: sizes.size_3,
			maxHeight: 0,
			opacity: 0,
			transition: 'max-height 0.87s',
			animation: '$appear .67s forwards .2s, $systemGrow 1s forwards',
		},
		message: {
			marginBottom: sizes.sizeHalf,
		},
		agentMessageAnimation: {
			maxHeight: 0,
			opacity: 0,
			animation:
				'$appear 0.57s cubic-bezier(0.65, 0, 0.35, 1) forwards .1s, $grow 1.2s cubic-bezier(0.65, 0, 0.35, 1) forwards, $slide .5s cubic-bezier(0.65, 0, 0.35, 1) forwards',
			transformOrigin: 'left bottom',
			'& > p, & > span': {
				transform: 'translateY(-2px)',
				animation: '$bounceIn .3s forwards',
				animationDelay: '.5s',
			},
		},
		lastUserMessage: {
			marginBottom: sizes.size_2,
		},
		lastAgentMessage: {
			marginBottom: sizes.size_3,
		},
		removeLiveChat: {
			animation: '$slide-up 500ms linear forwards, $fade-out 200ms linear forwards',
		},
		userMessage: {
			maxHeight: 0,
			opacity: 0,
			animation:
				'$appear 0.87s cubic-bezier(0.65, 0, 0.35, 1) forwards .1s, $grow 1.5s cubic-bezier(0.65, 0, 0.35, 1) forwards, $slide .67s cubic-bezier(0.65, 0, 0.35, 1), $bounceIn .4s ease forwards .5s',
			transformOrigin: 'right bottom',
			'& > p': {
				transform: 'scale3d(0.5, 0.5 ,0.5)',
				animation: '$scaleSide .5s .1s forwards, $slide .5s .1s forwards',
				transformOrigin: 'right bottom',
			},
		},
		flexWrapper: {
			flex: 1,
		},
		userLink: {
			fontSize: '0.941rem',
			color: colorScheme.colorVariables.colorHeadOnDark,
			textDecorationColor: setAlpha(colorScheme.colorVariables.colorHeadOnDark, '0.24'),
			'&:hover': {
				textDecorationColor: colorScheme.colorVariables.colorHeadOnDark,
			},
			'&:visited': {
				color: colorScheme.colorVariables.colorHeadOnDark,
				textDecorationColor: colorScheme.colorVariables.colorHeadOnDark,
			},
			'&:focus-visible': {
				outline: `2px solid ${colorScheme.colorVariables.colorSysTextDefault}`,
			},
		},
		agentLink: {
			fontSize: '0.941rem',
		},
	}),
);

export const useLiveChatLastMessageStyles = makeStyles(({ palette: { sizes }, typography: { smallViewPort } }) => ({
	root: {
		padding: `${sizes.size_3}px 0px`,
	},
	text: {
		padding: `${sizes.size_2 - sizes.sizeHalf}px ${sizes.size_1}% ${sizes.size_5}px`,
		...smallViewPort.typeHeading2,
	},
}));

export const messageCSSTransitionStyles = makeStyles({
	enter: {
		height: 0,
		maxWidth: 0,
		opacity: 0,
	},
	enterActive: {
		height: 40,
		maxWidth: 64,
		opacity: 1,
		transition: 'all 300ms',
	},
	exit: {
		height: 40,
		maxWidth: 64,
		opacity: 1,
		transition: 'all 300ms',
	},
	exitActive: {
		height: 0,
		maxWidth: 0,
		opacity: 0,
	},
});

export const feedbackCSSTransitionStyles = makeStyles({
	enter: {
		opacity: 0,
	},
	enterActive: {
		opacity: 1,
		transition: 'all 700ms',
	},
});

export const wellcomeCardCSSTransitionStyles = makeStyles({
	exit: {
		opacity: 1,
		marginTop: 0,
		transform: 'translateY(0)',
		transitionDelay: '100ms',
		transition: 'all 600ms',
	},
	exitActive: {
		opacity: 0,
		transform: 'translateY(-10%)',
	},
});
