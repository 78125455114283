import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { forwardRef } from 'react';

export type IconButtonProps = MuiIconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ children, ...props }, ref) => {
	return (
		<MuiIconButton disableRipple ref={ref} {...props}>
			{children}
		</MuiIconButton>
	);
});
