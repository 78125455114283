import { UnfoldMoreRounded } from '@mui/icons-material';
import { Button, InputAdornment, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { FC, memo, useMemo, useState } from 'react';

import { Effect } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { useUnitSelectorStyles } from './unit-selector.styles';

export type UnitSelectorRenderType = 'label' | 'select';
interface UnitSelectorProps {
	type: UnitSelectorRenderType;
	units: string[];
	testingLabel?: string;
	isFocused: boolean;
	onChange: Effect<string>;
}

export const UnitSelector: FC<UnitSelectorProps> = memo(({ units, type, testingLabel, isFocused, onChange }) => {
	if (!units.length) {
		console.error('Units array is empty or undefined.');
		return null;
	}

	const [unit, setUnit] = useState(units[0]);
	const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);

	const classes = useUnitSelectorStyles();
	const labelClass = classNames(classes.label, { [classes.labelFocused]: isFocused });
	const buttonClass = classNames(classes.menuButton, { [classes.menuButtonFocused]: isFocused });

	const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (unitItem: string): void => {
		setUnit(unitItem);
		onChange(unitItem);
		handleClose();
	};

	const menuItems = useMemo(
		() =>
			units.map((unitItem) => (
				<MenuItem
					key={unitItem}
					value={unitItem}
					selected={unitItem === unit}
					className={classes.customMenuItem}
					onClick={() => handleMenuItemClick(unitItem)}>
					{unitItem}
				</MenuItem>
			)),
		[units, unit],
	);

	return type === 'label' ? (
		<span className={labelClass}>{units[0]}</span>
	) : (
		<InputAdornment position={'end'}>
			<Button
				aria-controls={'unit-selector-menu'}
				aria-haspopup={'true'}
				data-testing-label={testingLabel}
				onClick={handleClick}
				endIcon={<UnfoldMoreRounded />}
				className={buttonClass}>
				{unit}
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				classes={{
					paper: classes.paper,
				}}
				MenuListProps={{
					'aria-labelledby': 'unit-selector-button',
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}>
				{menuItems}
			</Menu>
		</InputAdornment>
	);
});
