import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers';
import { useMemo } from 'react';

import { StyledCalendarHeaderRoot } from './date-picker-header.styles';

interface CustomCalendarHeaderProps extends PickersCalendarHeaderProps<Date> {
	isNextMonthAvailable: boolean;
	language: string;
}

export const CustomCalendarHeader = (props: CustomCalendarHeaderProps) => {
	const { currentMonth, isNextMonthAvailable, language, onMonthChange } = props;

	const selectNextMonth = () =>
		onMonthChange(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1), 'left');

	const selectPreviousMonth = () =>
		onMonthChange(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1), 'right');

	const isPreviousMonthDisabled = () => {
		const previousMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);
		const today = new Date();
		return previousMonth < new Date(today.getFullYear(), today.getMonth(), 1);
	};

	const formattedMonth = useMemo(() => {
		const month = currentMonth.toLocaleString(language, { month: 'long' });
		const year = currentMonth.toLocaleString(language, { year: 'numeric' });
		return `${month} ${year}`;
	}, [language, currentMonth]);

	return (
		<StyledCalendarHeaderRoot>
			<Stack spacing={1} direction={'row'}>
				<IconButton
					onClick={selectPreviousMonth}
					data-testing-label={'date-picker-prev-month-widget-button'}
					sx={{ padding: '8px' }}
					title={'Previous month'}
					aria-label={'previous month'}
					disabled={isPreviousMonthDisabled()}>
					<ChevronLeft />
				</IconButton>
			</Stack>
			<Typography
				sx={{ backgroundColor: 'transparent' }}
				variant={'body2'}
				role={'heading'}
				aria-live={'polite'}
				aria-atomic={'true'}
				aria-label={`Currently viewing ${currentMonth.toLocaleString('default', {
					month: 'long',
					year: 'numeric',
				})}`}>
				{formattedMonth}
			</Typography>
			<Stack spacing={1} direction={'row'}>
				<IconButton
					disabled={!isNextMonthAvailable}
					data-testing-label={`date-picker-next-month-widget-button${
						!isNextMonthAvailable ? '-disabled' : ''
					}`}
					onClick={selectNextMonth}
					sx={{ padding: '8px' }}
					aria-label={'next month'}
					title={'Next month'}>
					<ChevronRight />
				</IconButton>
			</Stack>
		</StyledCalendarHeaderRoot>
	);
};
