import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../ui-kit/button/button.component';
import { Icon } from '../../ui-kit/icon/icon.component';
import { CheckIcon } from '../../ui-kit/icons/check.icon';
import { InputPassword } from '../../ui-kit/input-password/input-password.component';
import { Effect } from '../../utils/function.utils';
import { usePasswordResetStyles } from './password-reset.styles';

interface Validation {
	rule: RegExp;
	label: string;
}

interface PasswordResetProps {
	validations: Validation[];
	generalValidations: Validation[];
	validationTitle: string;
	isLoading: boolean;
	onCancel: Effect<string>;
	onPasswordChange(password: string, text: string): void;
}

export const PasswordReset: FC<PasswordResetProps> = ({
	validations,
	generalValidations,
	isLoading,
	validationTitle,
	onCancel,
	onPasswordChange,
}) => {
	const classes = usePasswordResetStyles();
	const { t } = useTranslation();
	const [password, setPassword] = useState('');
	const [confirmedPassword, setConfirmedPassword] = useState('');
	const [isPasswordDirty, setPasswordDirty] = useState(false);
	const [isPasswordConfirmDirty, setPasswordConfirmDirty] = useState(false);

	const isPasswordConfirmed = password === confirmedPassword;
	const isPasswordValidated = validations.every((validation) => validation.rule.test(password));
	const isPasswordReadyToSubmit = isPasswordValidated && isPasswordConfirmed;

	const handleChange =
		(type: 'password' | 'confirm'): Effect<ChangeEvent<HTMLInputElement>> =>
		(e) => {
			const value = e.currentTarget.value;
			switch (type) {
				case 'password':
					return setPassword(value);
				case 'confirm':
					return setConfirmedPassword(value);
			}
		};

	const getGeneralError = (text: string, dataTestingLabel: string): JSX.Element => (
		<span data-testing-label={dataTestingLabel} className={classes.generalError} key={text}>
			{text}
		</span>
	);

	const shouldShowPasswordConfirmError =
		!isPasswordConfirmed && isPasswordConfirmDirty && confirmedPassword.length > 0;

	const validationsState = (
		<div className={classes.validationRoot} data-testing-label={'password-reset-validation'}>
			<h5 className={classes.validationtitle} data-testing-label={'password-reset-validation-title'}>
				{validationTitle}
			</h5>
			<ul className={classes.list}>
				{validations.map((validation) => {
					const isValid = validation.rule.test(password);
					return (
						<li
							key={validation.label}
							className={classes.listItem}
							data-testing-label={`password-reset-validation-item-${validation.label}-${
								isValid ? 'valid' : 'invalid'
							}`}>
							{isValid ? (
								<Icon
									size={'small'}
									icon={CheckIcon}
									viewBox={'0 -960 960 960'}
									iconType={'systemIconSuccess'}
									className={classes.validationIcon}
									dataTestingLabel={'password-reset-validation-icon-valid'}
								/>
							) : (
								<Icon
									size={'small'}
									icon={CancelRoundedIcon}
									iconType={'systemIconAlert'}
									className={classes.validationIcon}
									dataTestingLabel={'password-reset-validation-icon-invalid'}
								/>
							)}
							<span data-testing-label={'password-reset-validation-label'}>{validation.label}</span>
						</li>
					);
				})}
			</ul>
		</div>
	);

	return (
		<div data-testing-label={'password-reset'}>
			<InputPassword
				value={password}
				autoComplete={'off'}
				disabled={isLoading}
				onChange={handleChange('password')}
				onFocus={() => setPasswordDirty(true)}
				placeholder={t('passwordResetPasswordPlaceholder', 'New Password')}
				label={t('passwordResetPasswordPlaceholder', 'New Password')}
				data-testing-label={'password-reset-password-input'}
			/>
			{generalValidations.map((generalValidation) => {
				const isValid = generalValidation.rule.test(password);
				return (
					!isValid &&
					getGeneralError(
						generalValidation.label,
						`password-reset-${generalValidation.label}-general-invalid`,
					)
				);
			})}
			{isPasswordDirty && validationsState}
			<InputPassword
				value={confirmedPassword}
				autoComplete={'off'}
				disabled={isLoading}
				onChange={handleChange('confirm')}
				error={shouldShowPasswordConfirmError}
				className={classes.confirmPasswordInput}
				onFocus={() => setPasswordConfirmDirty(true)}
				placeholder={t('passwordResetConfirmPasswordPlaceholder', 'Confirm Password')}
				label={t('passwordResetConfirmPasswordPlaceholder', 'Confirm Password')}
				data-testing-label={'password-reset-password-confirm-input'}
			/>
			{shouldShowPasswordConfirmError &&
				getGeneralError(t('passwordResetConfirmError'), 'password-reset-confirmation-error')}
			<div className={classes.actions}>
				<Button
					loading={isLoading}
					color={'primary'}
					onClick={() => onPasswordChange(password, t('passwordResetChangeLabel'))}
					disabled={!isPasswordReadyToSubmit}
					className={classes.mainAction}
					data-testing-label={'password-reset-primary-action-button'}>
					{t('passwordResetChangeLabel')}
				</Button>
				<Button
					disabled={isLoading}
					onClick={() => onCancel(t('cancel'))}
					variant={'link'}
					data-testing-label={'password-reset-secondary-action-button'}>
					{t('cancel')}
				</Button>
			</div>
		</div>
	);
};
