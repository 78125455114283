import { styled } from '@mui/material';

export const StyledLanguageSelectorRoot = styled('div')({
	display: 'flex',
	alignItems: 'center',
	padding: 0,
	width: '100%',
});

export const StyledLanguageSelectorTitle = styled('h3')(
	({
		theme: {
			palette: { sizes },
			typography,
		},
	}) => ({
		margin: `0 0 ${sizes.size_1}px`,
		paddingLeft: sizes.size_2,
		...typography.smallViewPort.typeHeading2,
	}),
);

export const StyledLanguageSelectorList = styled('ul')({
	padding: 0,
	margin: 0,
	width: '100%',
	listStyleType: 'none',
});

export const StyledLanguageSelectorListItem = styled('li')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		'&:not(:last-child)': {
			marginBottom: sizes.sizeHalf,
		},
	}),
);
