import { makeStyles } from '@mui/styles';

export const useLLMResultContainerStyles = makeStyles(({ palette: { sizes } }) => ({
	feedback: {
		display: 'flex',
		flex: 1,
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		padding: `0 ${sizes.size_1}% ${sizes.size_3}px`,
	},
}));

export const useLLMResultsAccordionSummaryStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
		},
		typography: { smallViewPort },
	}) => ({
		root: {
			padding: `12px ${sizes.size_3}px`,
			'& svg': {
				color: colorVariables.colorNote,
				height: sizes.size_4,
				width: sizes.size_4,
			},
			'&.Mui-expanded': {
				margin: 0,
				minHeight: 'auto',
			},
			'&.Mui-focusVisible': {
				backgroundColor: 'white',
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
			},
		},
		content: {
			margin: 0,
			textTransform: 'uppercase',
			...smallViewPort.typeSubheading1,
			'&.Mui-expanded': {
				margin: 0,
			},
		},
		expandIcon: {
			padding: 0,
		},
	}),
);
export const useLLMSearchResultsStyles = makeStyles(
	({
		palette: {
			sizes,
			borderRadius,
			colorScheme: { colorVariables },
		},
		typography: { smallViewPort },
	}) => ({
		root: {
			margin: `0 ${sizes.size_1}% ${sizes.size_3}px`,
			borderRadius: borderRadius.commonRadius,
			border: `1px solid ${colorVariables.colorLightShadow}`,
			boxShadow: 'none',
			backgroundColor: colorVariables.colorSysBgDefault,
			'&:before': {
				display: 'none',
			},
			'&.Mui-expanded, &.Mui-expanded:last-child': {
				margin: `0 ${sizes.size_1}% ${sizes.size_3}px`,
			},
		},
		detailsRoot: {
			padding: `0 ${sizes.size_3}px`,
		},
		detailsList: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
			width: '100%',
		},
		detailsListItem: {
			marginBottom: sizes.size_2,
		},
		detailsListItemNumber: {
			...smallViewPort.typeLink1,
			verticalAlign: 'text-bottom',
			paddingRight: sizes.sizeHalf,
			fontWeight: 400,
		},
		detailsDescription: {
			...smallViewPort.typeCaption1,
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
			lineClamp: 2,
		},
	}),
);
