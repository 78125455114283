import { makeStyles } from '@mui/styles';

import { MAX_WIDGET_WIDTH } from '../../../utils/sizes.utils';

export const useCancelAppointmentStyles = makeStyles(({ palette: { sizes }, typography: { smallViewPort } }) => ({
	container: {
		padding: sizes.size_4,
		position: 'relative',
	},
	title: {
		...smallViewPort.typeHeading2,
		margin: `${sizes.size_7}px 0 ${sizes.size_3}px`,
	},
	list: {
		padding: 0,
		margin: 0,
		listStyleType: 'none',
	},
	listItem: {
		'&:not(:last-child)': {
			marginBottom: sizes.sizeHalf,
		},
	},
	chip: {
		width: '100%',
	},
	cancelButton: {
		marginTop: sizes.size_2,
		fontWeight: 700,
	},
	closeIcon: {
		position: 'absolute',
		right: sizes.size_3,
		top: sizes.size_3,
	},
	paper: {
		maxWidth: MAX_WIDGET_WIDTH,
		margin: '0 auto',
	},
}));
