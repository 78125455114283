import { Buffer } from 'buffer';
import { not, Predicate } from 'fp-ts/lib/function';

export const isBlank: Predicate<string> = (value) => !value.trim().length;

export const isNotBlank = not(isBlank);

export const isEmpty = (value: string): boolean => value.length === 0;

export const isNotEmpty = not(isEmpty);

export const highlightSubstring = (
	text: string,
	substring: string,
	cssProperty: React.CSSProperties,
): (string | JSX.Element)[] => {
	const substringParts = substring.split(' ').join('|');
	const substringPartsArray = substring.split(' ').map((s) => s.toLocaleLowerCase());
	try {
		const parts = (text || '').split(new RegExp(`(${substringParts})`, 'gi'));
		return parts.map((part, index) =>
			substringPartsArray.includes(part.toLowerCase()) ? (
				<span key={index} style={cssProperty}>
					{part}
				</span>
			) : (
				part
			),
		);
	} catch (e) {
		return [text];
	}
};

export const uuid = (): string => {
	const array = new Uint32Array(8);
	window.crypto.getRandomValues(array);
	let str = '';
	for (let i = 0; i < array.length; i++) {
		str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
	}
	return str;
};

export const encodeToBase64 = (data: string): string => Buffer.from(data).toString('base64');

export const decodeFromBase64 = (startingFlowStep: string, warningMessage?: string): string => {
	try {
		return atob(startingFlowStep);
	} catch (e) {
		warningMessage && console.warn(warningMessage);
		return startingFlowStep;
	}
};

export const checkValidRegExp = (regexpString: string): boolean => {
	try {
		new RegExp(regexpString);
		return true;
	} catch (e) {
		return false;
	}
};

export const MAX_INPUT_MESSAGE_LENGTH = 500;
