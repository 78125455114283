import makeStyles from '@mui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../../../models/dimensions.model';

export const useCausesCardStyles = makeStyles(
	({ palette: { sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		},
		title: {
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 0,
			width: '100%',
			textAlign: 'center',
			padding: `${sizes.size_2}px ${sizes.size_3}px`,
			...smallViewPort.typeHeading2,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading2,
			},
		},
		actionsRoot: {
			padding: `0px ${sizes.size_2}px ${sizes.size_1}px`,
			display: 'flex',
			flexDirection: 'column',
			flex: 0,
		},
		actionButton: {
			'&:not(:last-child)': {
				marginBottom: sizes.size_1,
			},
		},
		image: {
			flex: 0,
			display: 'block',
			width: '100%',
		},
	}),
);
