import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';

export const useRemainingTimeIndicatorStyles = ({
	palette: { colorScheme, sizes },
	typography: { smallViewPort },
}: Theme): StyleRules<any, string> =>
	createStyles({
		root: {
			position: 'relative',
			paddingTop: sizes.size_1,
			zIndex: 2,
			textAlign: 'center',
			...smallViewPort.typeNoteParagraph,
			marginBottom: 0,
			...colorScheme.customStyle.progressBar,
		},
	});
