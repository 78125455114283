import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useWidgetHeaderStyles = makeStyles<Theme, { isMobile: boolean; isSmallHeight: boolean }>(
	({
		palette: {
			colorScheme,
			sizes,
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		root: {
			alignItems: 'center',
			zIndex: 4,
			opacity: 0,
			top: '2%',
			left: 0,
			right: 0,
			padding: (props) => (props.isMobile || props.isSmallHeight ? sizes.size_2 : sizes.size_3),
			display: 'flex',
			justifyContent: 'space-between',
			backgroundColor: 'transparent',
			transitionDelay: noAnimationTransition ? '0s' : '100ms',
			transition: noAnimationTransition ? 'initial' : 'all 0.4s ease',
			position: 'relative',
			...colorScheme.customStyle.header?.main,
		},
		menu: {
			alignItems: 'center',
			display: 'flex',
			flex: 1,
			'& > *:first-child': {
				marginRight: sizes.size_1,
			},
		},
		closeButton: {
			width: sizes.size_6,
			height: sizes.size_5,
			padding: 0,
			...colorScheme.customStyle.header?.closeButton,
		},
		openedWidget: {
			top: 0,
			opacity: 1,
		},
		environmentLabel: {
			position: 'absolute',
			top: 2,
			left: '50%',
			transform: 'translateX(-50%)',
		},
		liveChatTitle: {
			margin: '0',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flex: 1,
			textAlign: 'center',
			padding: '17px 0',
			opacity: 0,
			transition: 'opacity 0.5s',
			zIndex: -1,
		},
		avatar: {
			transform: 'translateY(0)',
			opacity: 1,
			transition: 'opacity 1s, transform 0s',
			transitionDelay: '0.5s',
		},
		hidden: {
			transition: 'opacity 0.3s, transform 0.67s',
			transform: 'translateY(-30px)',
			opacity: 0,
			transitionDelay: '0s',
		},
		shown: {
			transitionDelay: '0.5s',
			opacity: 1,
		},
	}),
);
