import makeStyles from '@mui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../../../models/dimensions.model';

export const useClinicCardStyles = makeStyles(
	({ palette: { sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		root: {
			padding: `${sizes.size_3}px ${sizes.size_3}px 0`,
			height: '100%',
			...smallViewPort.typeCaption1,
		},
		section: {
			paddingBottom: sizes.size_1,
		},
		clinicTitle: {
			margin: 0,
			...smallViewPort.typeHeading2,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading2,
			},
		},
		dispositionTitle: {
			...smallViewPort.typeNoteHeading,
		},
		centeredInlineFlex: {
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		withSeparator: {
			'& > *:not(:last-child):after': {
				content: '"·"',
				padding: '0 3px',
			},
		},
		actionsRoot: {
			padding: `0px ${sizes.size_2}px ${sizes.size_1}px`,
			display: 'flex',
			flexDirection: 'column',
		},
		additionlActionsWrapper: {
			paddingTop: sizes.size_1,
			display: 'flex',
		},
		additionalActionLink: {
			flex: 1,
			'&:not(:last-child)': {
				marginRight: sizes.size_1,
			},
		},
		actionLinkLabel: {
			paddingLeft: sizes.size_1,
		},
		additionalInfoRoot: {
			whiteSpace: 'nowrap',
			'& svg': {
				marginRight: 3,
				marginLeft: -2,
			},
			'&:nth-child(2n)': {
				paddingLeft: 8,
			},
		},
	}),
);
