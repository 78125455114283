import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import classNames from 'classnames';
import { FC, Fragment, memo, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import { ConfigContext } from '../../context/config.context';
import { DocumentContext } from '../../context/document.context';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import { sizes } from '../../ui-kit/theme/theme.model';
import { setNextSequenceFocusElement } from '../../utils/DOM.utils';
import { PROGRESS_TIME_LABEL_HEIGHT, USER_INPUT_HEIGHT } from '../../utils/sizes.utils';
import { LiveChatContainer } from '../live-chat/live-chat.container';
import { WidgetLoading } from '../widget-loading/widget-loading.component';
import {
	useLoadingAnimationStyle,
	useUserMessageAnimationStyle,
	useWidgetAnimationStyle,
	useWidgetContentStyles,
} from './widget-content.styles';

interface WidgetContentProps {
	isLoading: boolean;
	userMessage?: JSX.Element;
	botMessages: JSX.Element[];
	isLastBotTextMessage?: boolean;
	isUndo: boolean;
	isTimeRemainingShown: boolean;
	isInputShown: boolean;
	isLiveChatShown: boolean;
}

const chatPadding = (isInputShown: boolean, isTimeRemainingShown: boolean): number => {
	let paddingValue = 0;
	if (isInputShown) paddingValue += USER_INPUT_HEIGHT;
	if (isTimeRemainingShown) paddingValue += PROGRESS_TIME_LABEL_HEIGHT;
	if (!isInputShown && !isTimeRemainingShown) paddingValue = -sizes.size_2;
	if (!isInputShown && isTimeRemainingShown) paddingValue = 0;
	return paddingValue;
};

let showLoadingTimeout: NodeJS.Timeout;

export const WidgetContent: FC<WidgetContentProps> = memo(
	({
		userMessage,
		botMessages,
		isLastBotTextMessage,
		isLoading,
		isUndo,
		isTimeRemainingShown,
		isInputShown,
		isLiveChatShown,
	}) => {
		const fieldRef = useRef<HTMLDivElement>(null);
		const botMessagesRef = useRef<HTMLDivElement>(null);
		const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
		const {
			appOptions: { fullScreen },
		} = useContext(ConfigContext);
		const { document: frameDocument } = useContext(DocumentContext);
		const {
			palette: {
				transitionConfig: { noAnimationTransition },
			},
		} = useTheme();

		const [showLoading, setShowLoading] = useState(false);
		const [hideScroll, setHideScroll] = useState(false);
		const [savedBotMessage, setSavedBotMessage] = useState(botMessages);
		const [savedIsLastBotTextMessage, setSavedIsLastBotTextMessage] = useState(isLastBotTextMessage);

		const classes = useWidgetContentStyles({
			isMobile,
			paddingChatValue: chatPadding(isInputShown, isTimeRemainingShown),
		});

		const transitionStyles = useMemo(
			() => ({
				entered: {
					opacity: 1,
					transform: 'translateY(0)',
					transition: `all ${noAnimationTransition ? 0 : '400ms'}`,
				},
			}),
			[noAnimationTransition],
		);

		const widgetContentClasses = classNames(classes.root, hideScroll && classes.overflowHidden);
		const loadingAnimationStyle = useLoadingAnimationStyle();
		const widgetAnimationStyle = useWidgetAnimationStyle();
		const userMessageAnimationStyle = useUserMessageAnimationStyle();

		const botMessagesExists = botMessages.length > 0;

		const botMessageClassName = classNames(
			classes.botMessagesWrapper,
			classes.bottomPaddingSpace,
			savedIsLastBotTextMessage && classes.singleBotTextMessage,
			!isLoading && widgetAnimationStyle.enterWidgetAnimation,
			isLoading && isUndo && widgetAnimationStyle.exitPreviousWidgetAnimation,
			isLoading && !isUndo && widgetAnimationStyle.exitNextWidgetAnimation,
		);

		const userMessageClassNames = classNames(
			classes.userMessageWrapper,
			!isLoading && widgetAnimationStyle.enterWidgetAnimation,
			isLoading && isUndo && userMessageAnimationStyle.enterPreviousLoadingAnimation,
			isLoading && !isUndo && userMessageAnimationStyle.enterNextLoadingAnimation,
		);

		// delay to animate when widget-content fade out
		useEffect(() => {
			if (!isLoading && frameDocument) {
				setNextSequenceFocusElement(frameDocument, '[data-testing-label^="user-message"]');
			}
			if (isLoading) {
				setHideScroll(true);
				if (noAnimationTransition) {
					setShowLoading(true);
				} else {
					showLoadingTimeout = setTimeout(() => {
						setShowLoading(true);
					}, 500);
				}
			} else {
				setShowLoading(false);
				clearTimeout(showLoadingTimeout);
				setTimeout(() => {
					setHideScroll(false);
				}, 100);
			}
		}, [isLoading]);

		useLayoutEffect(() => {
			if (showLoading && fieldRef.current) {
				fieldRef.current.scrollTo({ top: 0 });
			}
		}, [showLoading]);

		// save current message to be able to animate it when fade out
		useEffect(() => {
			if (!botMessagesExists) {
				setSavedBotMessage(savedBotMessage);
				setSavedIsLastBotTextMessage(savedIsLastBotTextMessage);
			} else {
				setSavedBotMessage(botMessages);
				setSavedIsLastBotTextMessage(isLastBotTextMessage);
			}
		}, [botMessages]);

		const nodeRef = useRef(null);
		return (
			<div className={widgetContentClasses} ref={fieldRef} aria-label={'widget-content'}>
				{isLiveChatShown ? (
					<LiveChatContainer />
				) : (
					<Fragment>
						{userMessage && (
							<div className={userMessageClassNames} aria-label={'user message'}>
								{userMessage}
							</div>
						)}
						{savedBotMessage && (
							<div
								ref={botMessagesRef}
								aria-label={'bot messages'}
								data-testing-label={'bot-messages-container'}
								className={botMessageClassName}>
								{savedBotMessage}
							</div>
						)}
						<Transition unmountOnExit mountOnEnter nodeRef={nodeRef} in={showLoading} timeout={0}>
							{(state) => (
								<div
									ref={nodeRef}
									className={classNames(
										loadingAnimationStyle.root,
										isUndo ? loadingAnimationStyle.undo : loadingAnimationStyle.notUndo,
									)}
									style={{
										...transitionStyles[state],
									}}>
									<WidgetLoading
										isMobile={isMobile}
										fullScreen={fullScreen}
										className={classes.bottomPaddingSpace}
										isShown={showLoading}
									/>
								</div>
							)}
						</Transition>
					</Fragment>
				)}
			</div>
		);
	},
);
