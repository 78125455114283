import { WithStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { Effect } from '../../../utils/function.utils';
import { Button } from '../../button/button.component';
import { useCarouselCardStyles } from './carousel-card.styles';

type CarouselCardProps = {
	actionLabel?: string;
	onAction?: Effect<EventType>;
	children: React.ReactNode;
} & WithStyles<typeof useCarouselCardStyles>;

export const CarouselCard = withStyles(useCarouselCardStyles)(
	({ actionLabel, classes, children, onAction }: CarouselCardProps) => (
		<div tabIndex={0} role={'list'} className={classNames(classes.root, 'card-container')}>
			<div className={classes.content}>{children}</div>
			{onAction && (
				<div className={classes.actions}>
					<Button
						color={'primary'}
						onClick={onAction}
						className={classes.actionButton}
						data-testing-label={'carousel-card-main-action'}>
						{actionLabel}
					</Button>
				</div>
			)}
		</div>
	),
);
