import makeStyles from '@mui/styles/makeStyles';

export const useMultiQuickResponsesRendererStyles = makeStyles(({ palette: { borderRadius, sizes, colorScheme } }) => ({
	root: {
		listStyleType: 'none',
		width: '100%',
		margin: 0,
		padding: `${sizes.size_1}px 0 0`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		...colorScheme.customStyle.multiselect?.main,
	},
	listItem: {
		backgroundColor: 'rgba(0,0,0,0.1)',
		margin: `${sizes.sizeHalf}px 0 0 `,
		borderRadius: borderRadius.card,
		transition: 'all ease 0.35s',
		width: '100%',
	},
	customMultiselectItem: {
		...colorScheme.customStyle.multiselect?.item,
	},
	sendButton: {
		textAlign: 'center',
		width: '100%',
		marginTop: sizes.size_2,
		marginBottom: sizes.size_1,
	},
	customSendButton: {
		...colorScheme.customStyle.multiselect?.sendButton,
	},
	exclusiveMulti: {
		width: 'auto',
	},
}));
