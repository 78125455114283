import { FC, useContext } from 'react';

import { ConfigContext } from '../../context/config.context';
import { AvatarIcon } from '../icons/avatar.icon';
import { AvatarDiv } from './avatar.styles';

interface AvatarProps {
	className?: string;
	dataTestingLabel?: string;
}

export const Avatar: FC<AvatarProps> = ({ className, dataTestingLabel }) => {
	const {
		appConfig: { visualConfig },
	} = useContext(ConfigContext);
	const iconPath = visualConfig.headerAvatar;

	return (
		<AvatarDiv className={className} iconPath={iconPath} data-testing-label={dataTestingLabel}>
			{!iconPath && <AvatarIcon />}
		</AvatarDiv>
	);
};
