import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EmojiSadIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} width={'32'} height={'32'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon points={'0 0 32 0 32 32 0 32'}></polygon>
				<circle fill={'currentColor'} fillRule={'nonzero'} cx={'20.6666667'} cy={'12.6666667'} r={'2'}></circle>
				<circle fill={'currentColor'} fillRule={'nonzero'} cx={'11.3333333'} cy={'12.6666667'} r={'2'}></circle>
				<path
					d={
						'M15.9866667,2.66666667 C8.62666667,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.62666667,29.3333333 15.9866667,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,8.64 23.36,2.66666667 15.9866667,2.66666667 Z M16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.1066667 10.1066667,5.33333333 16,5.33333333 C21.8933333,5.33333333 26.6666667,10.1066667 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 Z M16,18.6666667 C13.4666667,18.6666667 11.16,19.96 9.8,22.1066667 C9.50666667,22.5733333 9.65333333,23.1866667 10.12,23.48 C10.5866667,23.7733333 11.2,23.6266667 11.4933333,23.16 C12.48,21.5866667 14.16,20.6533333 16,20.6533333 C17.84,20.6533333 19.52,21.5866667 20.5066667,23.16 C20.6933333,23.4666667 21.0266667,23.6266667 21.36,23.6266667 C21.5466667,23.6266667 21.72,23.5733333 21.8933333,23.48 C22.36,23.1866667 22.5066667,22.5733333 22.2133333,22.1066667 C20.84,19.9466667 18.5333333,18.6666667 16,18.6666667 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
			</g>
		</g>
	</SvgIcon>
);
