import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NewPatientIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M17.55 9.175L21.075 5.625C21.275 5.425 21.5125 5.325 21.7875 5.325C22.0625 5.325 22.3 5.425 22.5 5.625C22.7 5.825 22.8 6.0625 22.8 6.3375C22.8 6.6125 22.7 6.85 22.5 7.05L18.25 11.3C18.05 11.5 17.8167 11.6 17.55 11.6C17.2833 11.6 17.05 11.5 16.85 11.3L14.725 9.175C14.525 8.975 14.425 8.7375 14.425 8.4625C14.425 8.1875 14.525 7.95 14.725 7.75C14.925 7.55 15.1583 7.45 15.425 7.45C15.6917 7.45 15.925 7.55 16.125 7.75L17.55 9.175ZM9 12C7.9 12 6.95833 11.6083 6.175 10.825C5.39167 10.0417 5 9.1 5 8C5 6.9 5.39167 5.95833 6.175 5.175C6.95833 4.39167 7.9 4 9 4C10.1 4 11.0417 4.39167 11.825 5.175C12.6083 5.95833 13 6.9 13 8C13 9.1 12.6083 10.0417 11.825 10.825C11.0417 11.6083 10.1 12 9 12ZM1 18V17.2C1 16.6333 1.14583 16.1125 1.4375 15.6375C1.72917 15.1625 2.11667 14.8 2.6 14.55C3.63333 14.0333 4.68333 13.6458 5.75 13.3875C6.81667 13.1292 7.9 13 9 13C10.1 13 11.1833 13.1292 12.25 13.3875C13.3167 13.6458 14.3667 14.0333 15.4 14.55C15.8833 14.8 16.2708 15.1625 16.5625 15.6375C16.8542 16.1125 17 16.6333 17 17.2V18C17 18.55 16.8042 19.0208 16.4125 19.4125C16.0208 19.8042 15.55 20 15 20H3C2.45 20 1.97917 19.8042 1.5875 19.4125C1.19583 19.0208 1 18.55 1 18Z'
			}
		/>
	</SvgIcon>
);
