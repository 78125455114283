import { Box } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

import { CalendarLoading } from '../icons/calendar-loading';

const blinker = keyframes`
  50% {
    opacity: 0.3;
  }
`;

export const StyledErrorBox = styled(Box)(({ theme: { palette, typography, spacing } }) => ({
	color: palette.error.main,
	fontSize: typography.smallViewPort.typeSysText2.fontSize,
	paddingTop: spacing(1),
	marginLeft: spacing(2),
}));

export const StyledNoAvailableSlotsLabel = styled('span')(({ theme }) => ({
	marginLeft: theme.spacing(2),
}));

export const StyledCalendarLoading = styled(CalendarLoading)(
	({
		theme: {
			palette: { colorScheme, sizes },
		},
	}) => ({
		color: colorScheme.colorVariables.colorInactive,
		padding: `0px ${sizes.size_2}px ${sizes.size_4}px`,
		animation: `${blinker} 1s linear infinite`,
	}),
);
