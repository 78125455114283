import { pipe } from 'fp-ts/lib/function';
import { createContext, FC, Fragment, ReactNode, useContext } from 'react';

import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Link } from '../../ui-kit/link/link.component';
import { constVoid, Effect } from '../../utils/function.utils';
import { EventsServiceContext } from '../events-service/events-service.context';
import {
	getLinkEventData,
	getTargetLink,
	isTel,
	parseLinksInTextMessage,
	reLinkData,
	removeGyantIFrameParameterFromUrl,
	removeGyantIgnoreTrackerFromUrl,
	removeSpecialQueryParameters,
	shouldOpenLinkInModal,
} from './links-service.model';

export type OnLinkClick = (url: string, event: EventType, flowStep?: string, encodedSurveyData?: string) => void;

export interface ParseLinksInTextOptions {
	text: string;
	flowStep?: string;
	flowId?: string;
	onLinkClick?: OnLinkClick;
	className?: string;
}

export interface LinksServiceType {
	parseLinksInText: (options: ParseLinksInTextOptions) => (JSX.Element | string)[];
	openExternalLink: (
		url: string,
		event: EventType | string,
		flowStep?: string,
		flowId?: string,
		encodedSurveyData?: string,
		options?: OpenExternalLinkOptions,
	) => void;
}

export const EVENTS_SERVICE_DEFAULT: LinksServiceType = {
	parseLinksInText: () => [],
	openExternalLink: constVoid,
};

export const LinksServiceContext = createContext<LinksServiceType>(EVENTS_SERVICE_DEFAULT);

interface OpenExternalLinkOptions {
	additionalEventData: Record<string, unknown>;
}
export interface LinkServiceConfig {
	settings: {
		clientName: string;
		host: string;
		confirmOpenLink: boolean;
		confirmOpenLinkLabel?: string;
		linkTarget?: string;
	};
	onOpenModal: Effect<string>;
	onDispatchEvent: (type: 'onUserClickLink' | 'onUserClickPhone', event: EventType | string) => void;
}

interface LinksServiceContextProviderProps {
	linkServiceConfig: LinkServiceConfig;
	children?: ReactNode;
}

export const LinksServiceContextProvider: FC<LinksServiceContextProviderProps> = ({ linkServiceConfig, children }) => {
	const {
		settings: { confirmOpenLink, confirmOpenLinkLabel, linkTarget },
		onOpenModal,
		onDispatchEvent,
	} = linkServiceConfig;

	const { sendEvent } = useContext(EventsServiceContext);

	const openExternalLink = (
		url: string,
		event: EventType | string,
		flowStep?: string,
		flowId?: string,
		encodedSurveyData?: string,
		options?: OpenExternalLinkOptions,
	) => {
		const trackingEventType = isTel(url) ? 'onUserClickPhone' : 'onUserClickLink';
		const originalUrlObj = new URL(url);
		const searchParams = new URLSearchParams(originalUrlObj.search);
		const eventData = getLinkEventData(searchParams) || {};

		onDispatchEvent(trackingEventType, event);

		if (confirmOpenLink) {
			const confirmOpenLink = window.confirm(confirmOpenLinkLabel);

			if (!confirmOpenLink) {
				return;
			}
		}

		if (shouldOpenLinkInModal(url)) {
			const cleanUrl = removeGyantIFrameParameterFromUrl(url);
			return onOpenModal(cleanUrl);
		}
		const targetLink = getTargetLink(url, linkTarget);

		if (encodedSurveyData) {
			url = `${url}${url.indexOf('?') !== -1 ? '&' : '?'}externalSurveyData=${encodedSurveyData}`;
		}
		const link = pipe(url, removeGyantIgnoreTrackerFromUrl, removeSpecialQueryParameters);
		const additionalEventData = options?.additionalEventData || {};
		if (isTel(url)) {
			sendEvent({
				eventName: 'onUserClickPhone',
				data: { link: url, flowStep, flowId, ...eventData, ...additionalEventData },
			});
			window.open(link, '_self');
		} else {
			sendEvent({
				eventName: 'onUserClickLink',
				data: {
					link: removeSpecialQueryParameters(url),
					flowStep,
					flowId,
					...eventData,
					...additionalEventData,
				},
			});

			window.open(link, targetLink);
		}
	};

	const parseLinksInText = (options: ParseLinksInTextOptions): JSX.Element[] => {
		const { text, flowStep, flowId, className, onLinkClick } = options;

		const messageWithParsedLinks = parseLinksInTextMessage(text);
		return messageWithParsedLinks.split(reLinkData).map((word) => {
			if (word.includes('url') && word.includes('content')) {
				const { url, content } = JSON.parse(word);

				return (
					<Fragment key={`${content}_${url}`}>
						<Link
							className={className}
							onClick={(e) => {
								if (onLinkClick) {
									onLinkClick(url, e, flowStep, flowId);
								} else {
									e.preventDefault();
									openExternalLink(url, e, flowStep, flowId);
								}
							}}
							target={'_blank'}
							rel={'noreferrer'}
							href={url}
						>
							{content}
						</Link>
					</Fragment>
				);
			}
			return <span key={word} dangerouslySetInnerHTML={{ __html: word }} />;
		});
	};

	return (
		<LinksServiceContext.Provider value={{ parseLinksInText, openExternalLink }}>
			{children}
		</LinksServiceContext.Provider>
	);
};
