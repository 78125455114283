import { makeStyles } from '@mui/styles';

export const useHelpfulnessSurveyStyles = makeStyles(
	({
		palette: {
			sizes,
			transitionConfig: { noAnimationTransition },
			colorScheme: { colorVariables },
		},
		typography: { smallViewPort },
	}) => ({
		root: {
			alignItems: 'center',
			display: 'flex',
			height: sizes.size_5,
		},
		text: {
			paddingRight: sizes.size_2,
			...smallViewPort.typeSysText2,
			margin: 0,
		},
		buttonIcon: {
			width: sizes.size_5,
			height: sizes.size_5,
			'&:not(:last-child)': {
				marginRight: sizes.sizeHalf,
			},
			'&:hover': {
				filter: 'unset',
				transition: 'unset',
				background: colorVariables.colorSysBgDefault,
			},
		},
		thumbsDown: {
			animation: noAnimationTransition ? 'initial' : '$thumbsDown 1s',
			animationTimingFunction: 'cubic-bezier(0.65, 0, 0.35, 1)',
		},
		'@keyframes thumbsDown': {
			'0%': {
				transform: 'rotate(0deg) translateY(0)',
			},
			'30%': {
				transform: 'rotate(5deg) translateY(-2px)',
			},
			'55%': {
				transform: 'rotate(-15deg) translateY(6px)',
			},
			'70%': {
				transform: 'rotate(12deg) translateY(-5px)',
			},
			'100%': {
				transform: 'rotate(-2deg) translateY(1px)',
			},
		},
		thumbsUp: {
			animation: noAnimationTransition ? 'initial' : '$thumbsUp 1s',
			animationTimingFunction: 'cubic-bezier(0.65, 0, 0.35, 1)',
		},
		'@keyframes thumbsUp': {
			'0%': {
				transform: 'rotate(0deg) translateY(0)',
			},
			'40%': {
				transform: 'rotate(-10deg) translateY(-4px)',
			},
			'55%': {
				transform: 'rotate(20deg) translateY(6px)',
			},
			'70%': {
				transform: 'rotate(-15deg) translateY(-4px)',
			},
			'100%': {
				transform: 'rotate(5deg) translateY(2px)',
			},
		},
	}),
);
