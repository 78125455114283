import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton, useMediaQuery } from '@mui/material';
import { FC, useEffect, useRef } from 'react';

import { MOBILE_MEDIA_QUERY } from '../../../models/dimensions.model';
import { Avatar } from '../../../ui-kit/avatar/avatar.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { Lazy } from '../../../utils/function.utils';
import { useHeaderStyles } from './widget-menu-header.styles';

interface MenuHeaderProps {
	onClose: Lazy<void>;
}

export const MenuHeader: FC<MenuHeaderProps> = ({ onClose }) => {
	const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

	const headerClasses = useHeaderStyles({ isMobile: isMobile });

	const buttonRef = useRef<null | HTMLButtonElement>(null);
	useEffect(() => {
		buttonRef.current?.focus();
	}, []);

	return (
		<div className={headerClasses.root} data-testing-label={'menu-header'}>
			<Avatar dataTestingLabel={'menu-header-avatar'} />
			<IconButton
				ref={buttonRef}
				aria-label={'close menu'}
				onClick={onClose}
				data-testing-label={'close-menu-btn'}>
				<Icon icon={CloseRoundedIcon} size={'large'} iconType={'buttonIcon'} alt={'close menu'} />
			</IconButton>
		</div>
	);
};
