import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EmojiNeutralIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} width={'32'} height={'32'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon points={'0 0 32 0 32 32 0 32'}></polygon>
				<path
					d={
						'M13,20.6666667 L19,20.6666667 C19.5466667,20.6666667 20,20.2133333 20,19.6666667 C20,19.12 19.5466667,18.6666667 19,18.6666667 L13,18.6666667 C12.4533333,18.6666667 12,19.12 12,19.6666667 C12,20.2133333 12.4533333,20.6666667 13,20.6666667 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
				<circle fill={'currentColor'} fillRule={'nonzero'} cx={'20.6666667'} cy={'12.6666667'} r={'2'}></circle>
				<circle fill={'currentColor'} fillRule={'nonzero'} cx={'11.3333333'} cy={'12.6666667'} r={'2'}></circle>
				<path
					d={
						'M15.9866667,2.66666667 C8.62666667,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.62666667,29.3333333 15.9866667,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,8.64 23.36,2.66666667 15.9866667,2.66666667 Z M16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.1066667 10.1066667,5.33333333 16,5.33333333 C21.8933333,5.33333333 26.6666667,10.1066667 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
			</g>
		</g>
	</SvgIcon>
);
