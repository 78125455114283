import { FormControl, OutlinedInputProps } from '@mui/material';
import { ChangeEvent, FC, useCallback } from 'react';

import { Lazy } from '../../utils/function.utils';
import { StyledInputLabel, StyledOutlinedInput } from './input.styles';

interface KeyboardEvent {
	key: string;
	preventDefault: Lazy<void>;
}
const FLOATING_NUMBER_SYMBOLS = ['.', 'e'];
const NUMBER_REGEXP = /^\d+$/;

type InputProps = OutlinedInputProps & {
	className?: string;
	textLength?: number;
	noFloatingNumbers?: boolean;
};

export const Input: FC<InputProps> = ({
	className,
	onChange,
	textLength = Number.MAX_SAFE_INTEGER,
	noFloatingNumbers,
	...props
}) => {
	const handleValueChange = useCallback(
		(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const value = e.currentTarget.value;

			if (value.length <= textLength) {
				onChange && onChange(e);
			}
		},
		[onChange, textLength],
	);

	const handleKey = useCallback(
		(event: KeyboardEvent): void => {
			if (
				!['Enter', 'Backspace'].includes(event.key) &&
				props.type === 'number' &&
				((noFloatingNumbers && FLOATING_NUMBER_SYMBOLS.includes(event.key)) || !NUMBER_REGEXP.test(event.key))
			) {
				event.preventDefault();
			}
		},
		[props.type, noFloatingNumbers],
	);

	return (
		<FormControl variant={'outlined'} className={className} fullWidth>
			{props.label && (
				<StyledInputLabel htmlFor={'outlined-input'} shrink={true}>
					{props.label}
				</StyledInputLabel>
			)}
			<StyledOutlinedInput
				{...props}
				placeholder={props.placeholder}
				withLabel={Boolean(props.label)}
				classes={props.classes}
				error={Boolean(props.error)}
				onChange={handleValueChange}
				onKeyDown={handleKey}
			/>
		</FormControl>
	);
};
