import { Theme } from '@mui/material/styles';
import { createStyles, StyleRules } from '@mui/styles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../models/dimensions.model';

export const styles = ({
	palette: {
		sizes,
		colorScheme: { colorVariables },
	},
	typography: { smallViewPort, largeViewPort },
}: Theme): StyleRules<any, string> =>
	createStyles({
		root: {
			height: '100%',
			width: '100%',
			...smallViewPort.typeCaption1,
			marginBottom: 0,
		},
		section: {
			'&:not(:first-child)': {
				paddingTop: sizes.size_1,
			},
		},
		providerTitle: {
			margin: 0,
			...smallViewPort.typeHeading2,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading2,
			},
		},
		ratingIcon: {
			marginLeft: -3,
			marginTop: -3,
		},
		rating: { paddingRight: sizes.sizeHalf },
		providerImage: {
			borderRadius: '50%',
			width: sizes.size_7,
			height: sizes.size_7,
			objectFit: 'cover',
			marginBottom: 2,
		},
		centeredInlineFlex: {
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		withSeparator: {
			'& > *:not(:last-child):after': {
				content: '"·"',
				padding: '0 3px',
				color: colorVariables.colorCaption,
			},
		},
		ratingRoot: {
			display: 'flex',
			alignItems: 'center',
		},
		externalActionIcon: {
			marginLeft: sizes.size_1,
		},
		specialitiesRoot: {
			display: 'block',
		},
		actionsRoot: {
			paddingTop: sizes.size_2,
			display: 'flex',
			flexDirection: 'column',
		},
		additionalInfoRoot: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'baseline',
		},
		additionalInfoItem: {
			paddingBottom: sizes.sizeHalf,
			'& svg': {
				marginRight: 3,
				marginLeft: -2,
			},
		},
		oveflowEllipsis: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		successColor: {
			color: colorVariables.colorSysSuccess,
		},
		alertColor: {
			color: colorVariables.colorSysAlert,
		},
		actionButton: {
			'&:not(:last-child)': {
				marginBottom: sizes.size_1,
			},
		},
	});
