import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface EnvironmentStylesProps {
	bgColor: string;
	color: string;
}
export const useEnvironmentLabelStyles = makeStyles<Theme, EnvironmentStylesProps>(({ palette: { sizes } }) => ({
	root: {
		backgroundColor: (props) => props.bgColor,
		color: (props) => props.color,
		textTransform: 'uppercase',
		fontFamily: 'Public Sans',
		fontWeight: 'bold',
		fontSize: 12,
		lineHeight: '12px',
		padding: '2px 10px',
		borderRadius: 4,
		display: 'inline-block',
		minWidth: sizes.size_6,
		height: sizes.size_2,
		textAlign: 'center',
	},
}));
