import { FC, Fragment, memo, useContext } from 'react';

import { LinksServiceContext, OnLinkClick } from '../../../context/links-service/links-service.context';
import { MessageWithLLMResultMetadata } from '../../../models/message.model';
import { LLMResultFeedbackContainer } from '../llm-feedback/llm-feedback.container';
import { LLMResult } from './llm-result.component';
import { useLLMResultContainerStyles } from './llm-result.styles';

interface LLMResultsContainerProps {
	message: MessageWithLLMResultMetadata;
}

export const LLMResultsContainer: FC<LLMResultsContainerProps> = memo(({ message }) => {
	const classes = useLLMResultContainerStyles();
	const { parseLinksInText, openExternalLink } = useContext(LinksServiceContext);
	const {
		flowStep,
		text,
		metadata: { data },
	} = message;

	const onLinkClick: OnLinkClick = (url, event) => {
		const linkPositionEventData =
			data.searchResults.length > 0
				? { linkPosition: data.searchResults.findIndex((searchResult) => searchResult.url === url) + 1 }
				: {};
		event.preventDefault();

		openExternalLink(url, event, flowStep, undefined, undefined, {
			additionalEventData: {
				...linkPositionEventData,
				llmConversationId: data.llmConversationId,
				llmMessageId: data.llmMessageId,
			},
		});
	};

	// TODO: Pass data.searchResults to the LLMResult component when the UI is ready
	return (
		<Fragment>
			<LLMResult
				searchResults={data.searchResults}
				content={<Fragment>{parseLinksInText({ text, flowStep, onLinkClick })}</Fragment>}
			/>
			{message.hasHelpfulnessSurvey && !message.isExternal && (
				<div className={classes.feedback}>
					<LLMResultFeedbackContainer message={message} />
				</div>
			)}
		</Fragment>
	);
});
