import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const CalendarLoading = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 326 260'} width={'326px'} height={'260px'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g fill={'#EFEFF1'}>
				<path
					d={
						'M18.5276115,-4.64314699e-16 L24.6123885,4.64314699e-16 C29.3643743,-4.0861093e-16 32.3473582,0.742604889 34.9547656,2.13706163 C37.562173,3.53151837 39.6084816,5.57782696 41.0029384,8.18523437 C42.3973951,10.7926418 43.14,13.7756257 43.14,18.5276115 L43.14,21.4723885 C43.14,26.2243743 42.3973951,29.2073582 41.0029384,31.8147656 C39.6084816,34.422173 37.562173,36.4684816 34.9547656,37.8629384 C32.3473582,39.2573951 29.3643743,40 24.6123885,40 L18.5276115,40 C13.7756257,40 10.7926418,39.2573951 8.18523437,37.8629384 C5.57782696,36.4684816 3.53151837,34.422173 2.13706163,31.8147656 C0.742604889,29.2073582 2.72407286e-16,26.2243743 -3.09543132e-16,21.4723885 L3.09543132e-16,18.5276115 C-2.72407286e-16,13.7756257 0.742604889,10.7926418 2.13706163,8.18523437 C3.53151837,5.57782696 5.57782696,3.53151837 8.18523437,2.13706163 C10.7926418,0.742604889 13.7756257,4.0861093e-16 18.5276115,-4.64314699e-16 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M18.5276115,44 L24.6123885,44 C29.3643743,44 32.3473582,44.7426049 34.9547656,46.1370616 C37.562173,47.5315184 39.6084816,49.577827 41.0029384,52.1852344 C42.3973951,54.7926418 43.14,57.7756257 43.14,62.5276115 L43.14,65.4723885 C43.14,70.2243743 42.3973951,73.2073582 41.0029384,75.8147656 C39.6084816,78.422173 37.562173,80.4684816 34.9547656,81.8629384 C32.3473582,83.2573951 29.3643743,84 24.6123885,84 L18.5276115,84 C13.7756257,84 10.7926418,83.2573951 8.18523437,81.8629384 C5.57782696,80.4684816 3.53151837,78.422173 2.13706163,75.8147656 C0.742604889,73.2073582 2.72407286e-16,70.2243743 -3.09543132e-16,65.4723885 L3.09543132e-16,62.5276115 C-2.72407286e-16,57.7756257 0.742604889,54.7926418 2.13706163,52.1852344 C3.53151837,49.577827 5.57782696,47.5315184 8.18523437,46.1370616 C10.7926418,44.7426049 13.7756257,44 18.5276115,44 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M18.5276115,88 L24.6123885,88 C29.3643743,88 32.3473582,88.7426049 34.9547656,90.1370616 C37.562173,91.5315184 39.6084816,93.577827 41.0029384,96.1852344 C42.3973951,98.7926418 43.14,101.775626 43.14,106.527611 L43.14,109.472389 C43.14,114.224374 42.3973951,117.207358 41.0029384,119.814766 C39.6084816,122.422173 37.562173,124.468482 34.9547656,125.862938 C32.3473582,127.257395 29.3643743,128 24.6123885,128 L18.5276115,128 C13.7756257,128 10.7926418,127.257395 8.18523437,125.862938 C5.57782696,124.468482 3.53151837,122.422173 2.13706163,119.814766 C0.742604889,117.207358 2.72407286e-16,114.224374 -3.09543132e-16,109.472389 L3.09543132e-16,106.527611 C-2.72407286e-16,101.775626 0.742604889,98.7926418 2.13706163,96.1852344 C3.53151837,93.577827 5.57782696,91.5315184 8.18523437,90.1370616 C10.7926418,88.7426049 13.7756257,88 18.5276115,88 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M18.5276115,132 L24.6123885,132 C29.3643743,132 32.3473582,132.742605 34.9547656,134.137062 C37.562173,135.531518 39.6084816,137.577827 41.0029384,140.185234 C42.3973951,142.792642 43.14,145.775626 43.14,150.527611 L43.14,153.472389 C43.14,158.224374 42.3973951,161.207358 41.0029384,163.814766 C39.6084816,166.422173 37.562173,168.468482 34.9547656,169.862938 C32.3473582,171.257395 29.3643743,172 24.6123885,172 L18.5276115,172 C13.7756257,172 10.7926418,171.257395 8.18523437,169.862938 C5.57782696,168.468482 3.53151837,166.422173 2.13706163,163.814766 C0.742604889,161.207358 2.72407286e-16,158.224374 -3.09543132e-16,153.472389 L3.09543132e-16,150.527611 C-2.72407286e-16,145.775626 0.742604889,142.792642 2.13706163,140.185234 C3.53151837,137.577827 5.57782696,135.531518 8.18523437,134.137062 C10.7926418,132.742605 13.7756257,132 18.5276115,132 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M18.5276115,176 L24.6123885,176 C29.3643743,176 32.3473582,176.742605 34.9547656,178.137062 C37.562173,179.531518 39.6084816,181.577827 41.0029384,184.185234 C42.3973951,186.792642 43.14,189.775626 43.14,194.527611 L43.14,197.472389 C43.14,202.224374 42.3973951,205.207358 41.0029384,207.814766 C39.6084816,210.422173 37.562173,212.468482 34.9547656,213.862938 C32.3473582,215.257395 29.3643743,216 24.6123885,216 L18.5276115,216 C13.7756257,216 10.7926418,215.257395 8.18523437,213.862938 C5.57782696,212.468482 3.53151837,210.422173 2.13706163,207.814766 C0.742604889,205.207358 2.72407286e-16,202.224374 -3.09543132e-16,197.472389 L3.09543132e-16,194.527611 C-2.72407286e-16,189.775626 0.742604889,186.792642 2.13706163,184.185234 C3.53151837,181.577827 5.57782696,179.531518 8.18523437,178.137062 C10.7926418,176.742605 13.7756257,176 18.5276115,176 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M18.5276115,220 L24.6123885,220 C29.3643743,220 32.3473582,220.742605 34.9547656,222.137062 C37.562173,223.531518 39.6084816,225.577827 41.0029384,228.185234 C42.3973951,230.792642 43.14,233.775626 43.14,238.527611 L43.14,241.472389 C43.14,246.224374 42.3973951,249.207358 41.0029384,251.814766 C39.6084816,254.422173 37.562173,256.468482 34.9547656,257.862938 C32.3473582,259.257395 29.3643743,260 24.6123885,260 L18.5276115,260 C13.7756257,260 10.7926418,259.257395 8.18523437,257.862938 C5.57782696,256.468482 3.53151837,254.422173 2.13706163,251.814766 C0.742604889,249.207358 2.72407286e-16,246.224374 -3.09543132e-16,241.472389 L3.09543132e-16,238.527611 C-2.72407286e-16,233.775626 0.742604889,230.792642 2.13706163,228.185234 C3.53151837,225.577827 5.57782696,223.531518 8.18523437,222.137062 C10.7926418,220.742605 13.7756257,220 18.5276115,220 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M65.6709448,-4.64314699e-16 L71.7557218,4.64314699e-16 C76.5077076,-4.0861093e-16 79.4906916,0.742604889 82.098099,2.13706163 C84.7055064,3.53151837 86.751815,5.57782696 88.1462717,8.18523437 C89.5407284,10.7926418 90.2833333,13.7756257 90.2833333,18.5276115 L90.2833333,21.4723885 C90.2833333,26.2243743 89.5407284,29.2073582 88.1462717,31.8147656 C86.751815,34.422173 84.7055064,36.4684816 82.098099,37.8629384 C79.4906916,39.2573951 76.5077076,40 71.7557218,40 L65.6709448,40 C60.918959,40 57.9359751,39.2573951 55.3285677,37.8629384 C52.7211603,36.4684816 50.6748517,34.422173 49.280395,31.8147656 C47.8859382,29.2073582 47.1433333,26.2243743 47.1433333,21.4723885 L47.1433333,18.5276115 C47.1433333,13.7756257 47.8859382,10.7926418 49.280395,8.18523437 C50.6748517,5.57782696 52.7211603,3.53151837 55.3285677,2.13706163 C57.9359751,0.742604889 60.918959,4.0861093e-16 65.6709448,-4.64314699e-16 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M65.6709448,44 L71.7557218,44 C76.5077076,44 79.4906916,44.7426049 82.098099,46.1370616 C84.7055064,47.5315184 86.751815,49.577827 88.1462717,52.1852344 C89.5407284,54.7926418 90.2833333,57.7756257 90.2833333,62.5276115 L90.2833333,65.4723885 C90.2833333,70.2243743 89.5407284,73.2073582 88.1462717,75.8147656 C86.751815,78.422173 84.7055064,80.4684816 82.098099,81.8629384 C79.4906916,83.2573951 76.5077076,84 71.7557218,84 L65.6709448,84 C60.918959,84 57.9359751,83.2573951 55.3285677,81.8629384 C52.7211603,80.4684816 50.6748517,78.422173 49.280395,75.8147656 C47.8859382,73.2073582 47.1433333,70.2243743 47.1433333,65.4723885 L47.1433333,62.5276115 C47.1433333,57.7756257 47.8859382,54.7926418 49.280395,52.1852344 C50.6748517,49.577827 52.7211603,47.5315184 55.3285677,46.1370616 C57.9359751,44.7426049 60.918959,44 65.6709448,44 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M65.6709448,88 L71.7557218,88 C76.5077076,88 79.4906916,88.7426049 82.098099,90.1370616 C84.7055064,91.5315184 86.751815,93.577827 88.1462717,96.1852344 C89.5407284,98.7926418 90.2833333,101.775626 90.2833333,106.527611 L90.2833333,109.472389 C90.2833333,114.224374 89.5407284,117.207358 88.1462717,119.814766 C86.751815,122.422173 84.7055064,124.468482 82.098099,125.862938 C79.4906916,127.257395 76.5077076,128 71.7557218,128 L65.6709448,128 C60.918959,128 57.9359751,127.257395 55.3285677,125.862938 C52.7211603,124.468482 50.6748517,122.422173 49.280395,119.814766 C47.8859382,117.207358 47.1433333,114.224374 47.1433333,109.472389 L47.1433333,106.527611 C47.1433333,101.775626 47.8859382,98.7926418 49.280395,96.1852344 C50.6748517,93.577827 52.7211603,91.5315184 55.3285677,90.1370616 C57.9359751,88.7426049 60.918959,88 65.6709448,88 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M65.6709448,132 L71.7557218,132 C76.5077076,132 79.4906916,132.742605 82.098099,134.137062 C84.7055064,135.531518 86.751815,137.577827 88.1462717,140.185234 C89.5407284,142.792642 90.2833333,145.775626 90.2833333,150.527611 L90.2833333,153.472389 C90.2833333,158.224374 89.5407284,161.207358 88.1462717,163.814766 C86.751815,166.422173 84.7055064,168.468482 82.098099,169.862938 C79.4906916,171.257395 76.5077076,172 71.7557218,172 L65.6709448,172 C60.918959,172 57.9359751,171.257395 55.3285677,169.862938 C52.7211603,168.468482 50.6748517,166.422173 49.280395,163.814766 C47.8859382,161.207358 47.1433333,158.224374 47.1433333,153.472389 L47.1433333,150.527611 C47.1433333,145.775626 47.8859382,142.792642 49.280395,140.185234 C50.6748517,137.577827 52.7211603,135.531518 55.3285677,134.137062 C57.9359751,132.742605 60.918959,132 65.6709448,132 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M65.6709448,176 L71.7557218,176 C76.5077076,176 79.4906916,176.742605 82.098099,178.137062 C84.7055064,179.531518 86.751815,181.577827 88.1462717,184.185234 C89.5407284,186.792642 90.2833333,189.775626 90.2833333,194.527611 L90.2833333,197.472389 C90.2833333,202.224374 89.5407284,205.207358 88.1462717,207.814766 C86.751815,210.422173 84.7055064,212.468482 82.098099,213.862938 C79.4906916,215.257395 76.5077076,216 71.7557218,216 L65.6709448,216 C60.918959,216 57.9359751,215.257395 55.3285677,213.862938 C52.7211603,212.468482 50.6748517,210.422173 49.280395,207.814766 C47.8859382,205.207358 47.1433333,202.224374 47.1433333,197.472389 L47.1433333,194.527611 C47.1433333,189.775626 47.8859382,186.792642 49.280395,184.185234 C50.6748517,181.577827 52.7211603,179.531518 55.3285677,178.137062 C57.9359751,176.742605 60.918959,176 65.6709448,176 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M65.6709448,220 L71.7557218,220 C76.5077076,220 79.4906916,220.742605 82.098099,222.137062 C84.7055064,223.531518 86.751815,225.577827 88.1462717,228.185234 C89.5407284,230.792642 90.2833333,233.775626 90.2833333,238.527611 L90.2833333,241.472389 C90.2833333,246.224374 89.5407284,249.207358 88.1462717,251.814766 C86.751815,254.422173 84.7055064,256.468482 82.098099,257.862938 C79.4906916,259.257395 76.5077076,260 71.7557218,260 L65.6709448,260 C60.918959,260 57.9359751,259.257395 55.3285677,257.862938 C52.7211603,256.468482 50.6748517,254.422173 49.280395,251.814766 C47.8859382,249.207358 47.1433333,246.224374 47.1433333,241.472389 L47.1433333,238.527611 C47.1433333,233.775626 47.8859382,230.792642 49.280395,228.185234 C50.6748517,225.577827 52.7211603,223.531518 55.3285677,222.137062 C57.9359751,220.742605 60.918959,220 65.6709448,220 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M112.814278,-4.64314699e-16 L118.899055,4.64314699e-16 C123.651041,-4.0861093e-16 126.634025,0.742604889 129.241432,2.13706163 C131.84884,3.53151837 133.895148,5.57782696 135.289605,8.18523437 C136.684062,10.7926418 137.426667,13.7756257 137.426667,18.5276115 L137.426667,21.4723885 C137.426667,26.2243743 136.684062,29.2073582 135.289605,31.8147656 C133.895148,34.422173 131.84884,36.4684816 129.241432,37.8629384 C126.634025,39.2573951 123.651041,40 118.899055,40 L112.814278,40 C108.062292,40 105.079308,39.2573951 102.471901,37.8629384 C99.8644936,36.4684816 97.818185,34.422173 96.4237283,31.8147656 C95.0292716,29.2073582 94.2866667,26.2243743 94.2866667,21.4723885 L94.2866667,18.5276115 C94.2866667,13.7756257 95.0292716,10.7926418 96.4237283,8.18523437 C97.818185,5.57782696 99.8644936,3.53151837 102.471901,2.13706163 C105.079308,0.742604889 108.062292,4.0861093e-16 112.814278,-4.64314699e-16 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M112.814278,44 L118.899055,44 C123.651041,44 126.634025,44.7426049 129.241432,46.1370616 C131.84884,47.5315184 133.895148,49.577827 135.289605,52.1852344 C136.684062,54.7926418 137.426667,57.7756257 137.426667,62.5276115 L137.426667,65.4723885 C137.426667,70.2243743 136.684062,73.2073582 135.289605,75.8147656 C133.895148,78.422173 131.84884,80.4684816 129.241432,81.8629384 C126.634025,83.2573951 123.651041,84 118.899055,84 L112.814278,84 C108.062292,84 105.079308,83.2573951 102.471901,81.8629384 C99.8644936,80.4684816 97.818185,78.422173 96.4237283,75.8147656 C95.0292716,73.2073582 94.2866667,70.2243743 94.2866667,65.4723885 L94.2866667,62.5276115 C94.2866667,57.7756257 95.0292716,54.7926418 96.4237283,52.1852344 C97.818185,49.577827 99.8644936,47.5315184 102.471901,46.1370616 C105.079308,44.7426049 108.062292,44 112.814278,44 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M112.814278,88 L118.899055,88 C123.651041,88 126.634025,88.7426049 129.241432,90.1370616 C131.84884,91.5315184 133.895148,93.577827 135.289605,96.1852344 C136.684062,98.7926418 137.426667,101.775626 137.426667,106.527611 L137.426667,109.472389 C137.426667,114.224374 136.684062,117.207358 135.289605,119.814766 C133.895148,122.422173 131.84884,124.468482 129.241432,125.862938 C126.634025,127.257395 123.651041,128 118.899055,128 L112.814278,128 C108.062292,128 105.079308,127.257395 102.471901,125.862938 C99.8644936,124.468482 97.818185,122.422173 96.4237283,119.814766 C95.0292716,117.207358 94.2866667,114.224374 94.2866667,109.472389 L94.2866667,106.527611 C94.2866667,101.775626 95.0292716,98.7926418 96.4237283,96.1852344 C97.818185,93.577827 99.8644936,91.5315184 102.471901,90.1370616 C105.079308,88.7426049 108.062292,88 112.814278,88 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M112.814278,132 L118.899055,132 C123.651041,132 126.634025,132.742605 129.241432,134.137062 C131.84884,135.531518 133.895148,137.577827 135.289605,140.185234 C136.684062,142.792642 137.426667,145.775626 137.426667,150.527611 L137.426667,153.472389 C137.426667,158.224374 136.684062,161.207358 135.289605,163.814766 C133.895148,166.422173 131.84884,168.468482 129.241432,169.862938 C126.634025,171.257395 123.651041,172 118.899055,172 L112.814278,172 C108.062292,172 105.079308,171.257395 102.471901,169.862938 C99.8644936,168.468482 97.818185,166.422173 96.4237283,163.814766 C95.0292716,161.207358 94.2866667,158.224374 94.2866667,153.472389 L94.2866667,150.527611 C94.2866667,145.775626 95.0292716,142.792642 96.4237283,140.185234 C97.818185,137.577827 99.8644936,135.531518 102.471901,134.137062 C105.079308,132.742605 108.062292,132 112.814278,132 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M112.814278,176 L118.899055,176 C123.651041,176 126.634025,176.742605 129.241432,178.137062 C131.84884,179.531518 133.895148,181.577827 135.289605,184.185234 C136.684062,186.792642 137.426667,189.775626 137.426667,194.527611 L137.426667,197.472389 C137.426667,202.224374 136.684062,205.207358 135.289605,207.814766 C133.895148,210.422173 131.84884,212.468482 129.241432,213.862938 C126.634025,215.257395 123.651041,216 118.899055,216 L112.814278,216 C108.062292,216 105.079308,215.257395 102.471901,213.862938 C99.8644936,212.468482 97.818185,210.422173 96.4237283,207.814766 C95.0292716,205.207358 94.2866667,202.224374 94.2866667,197.472389 L94.2866667,194.527611 C94.2866667,189.775626 95.0292716,186.792642 96.4237283,184.185234 C97.818185,181.577827 99.8644936,179.531518 102.471901,178.137062 C105.079308,176.742605 108.062292,176 112.814278,176 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M112.814278,220 L118.899055,220 C123.651041,220 126.634025,220.742605 129.241432,222.137062 C131.84884,223.531518 133.895148,225.577827 135.289605,228.185234 C136.684062,230.792642 137.426667,233.775626 137.426667,238.527611 L137.426667,241.472389 C137.426667,246.224374 136.684062,249.207358 135.289605,251.814766 C133.895148,254.422173 131.84884,256.468482 129.241432,257.862938 C126.634025,259.257395 123.651041,260 118.899055,260 L112.814278,260 C108.062292,260 105.079308,259.257395 102.471901,257.862938 C99.8644936,256.468482 97.818185,254.422173 96.4237283,251.814766 C95.0292716,249.207358 94.2866667,246.224374 94.2866667,241.472389 L94.2866667,238.527611 C94.2866667,233.775626 95.0292716,230.792642 96.4237283,228.185234 C97.818185,225.577827 99.8644936,223.531518 102.471901,222.137062 C105.079308,220.742605 108.062292,220 112.814278,220 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M159.957611,-4.64314699e-16 L166.042389,4.64314699e-16 C170.794374,-4.0861093e-16 173.777358,0.742604889 176.384766,2.13706163 C178.992173,3.53151837 181.038482,5.57782696 182.432938,8.18523437 C183.827395,10.7926418 184.57,13.7756257 184.57,18.5276115 L184.57,21.4723885 C184.57,26.2243743 183.827395,29.2073582 182.432938,31.8147656 C181.038482,34.422173 178.992173,36.4684816 176.384766,37.8629384 C173.777358,39.2573951 170.794374,40 166.042389,40 L159.957611,40 C155.205626,40 152.222642,39.2573951 149.615234,37.8629384 C147.007827,36.4684816 144.961518,34.422173 143.567062,31.8147656 C142.172605,29.2073582 141.43,26.2243743 141.43,21.4723885 L141.43,18.5276115 C141.43,13.7756257 142.172605,10.7926418 143.567062,8.18523437 C144.961518,5.57782696 147.007827,3.53151837 149.615234,2.13706163 C152.222642,0.742604889 155.205626,4.0861093e-16 159.957611,-4.64314699e-16 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M159.957611,44 L166.042389,44 C170.794374,44 173.777358,44.7426049 176.384766,46.1370616 C178.992173,47.5315184 181.038482,49.577827 182.432938,52.1852344 C183.827395,54.7926418 184.57,57.7756257 184.57,62.5276115 L184.57,65.4723885 C184.57,70.2243743 183.827395,73.2073582 182.432938,75.8147656 C181.038482,78.422173 178.992173,80.4684816 176.384766,81.8629384 C173.777358,83.2573951 170.794374,84 166.042389,84 L159.957611,84 C155.205626,84 152.222642,83.2573951 149.615234,81.8629384 C147.007827,80.4684816 144.961518,78.422173 143.567062,75.8147656 C142.172605,73.2073582 141.43,70.2243743 141.43,65.4723885 L141.43,62.5276115 C141.43,57.7756257 142.172605,54.7926418 143.567062,52.1852344 C144.961518,49.577827 147.007827,47.5315184 149.615234,46.1370616 C152.222642,44.7426049 155.205626,44 159.957611,44 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M159.957611,88 L166.042389,88 C170.794374,88 173.777358,88.7426049 176.384766,90.1370616 C178.992173,91.5315184 181.038482,93.577827 182.432938,96.1852344 C183.827395,98.7926418 184.57,101.775626 184.57,106.527611 L184.57,109.472389 C184.57,114.224374 183.827395,117.207358 182.432938,119.814766 C181.038482,122.422173 178.992173,124.468482 176.384766,125.862938 C173.777358,127.257395 170.794374,128 166.042389,128 L159.957611,128 C155.205626,128 152.222642,127.257395 149.615234,125.862938 C147.007827,124.468482 144.961518,122.422173 143.567062,119.814766 C142.172605,117.207358 141.43,114.224374 141.43,109.472389 L141.43,106.527611 C141.43,101.775626 142.172605,98.7926418 143.567062,96.1852344 C144.961518,93.577827 147.007827,91.5315184 149.615234,90.1370616 C152.222642,88.7426049 155.205626,88 159.957611,88 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M159.957611,132 L166.042389,132 C170.794374,132 173.777358,132.742605 176.384766,134.137062 C178.992173,135.531518 181.038482,137.577827 182.432938,140.185234 C183.827395,142.792642 184.57,145.775626 184.57,150.527611 L184.57,153.472389 C184.57,158.224374 183.827395,161.207358 182.432938,163.814766 C181.038482,166.422173 178.992173,168.468482 176.384766,169.862938 C173.777358,171.257395 170.794374,172 166.042389,172 L159.957611,172 C155.205626,172 152.222642,171.257395 149.615234,169.862938 C147.007827,168.468482 144.961518,166.422173 143.567062,163.814766 C142.172605,161.207358 141.43,158.224374 141.43,153.472389 L141.43,150.527611 C141.43,145.775626 142.172605,142.792642 143.567062,140.185234 C144.961518,137.577827 147.007827,135.531518 149.615234,134.137062 C152.222642,132.742605 155.205626,132 159.957611,132 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M159.957611,176 L166.042389,176 C170.794374,176 173.777358,176.742605 176.384766,178.137062 C178.992173,179.531518 181.038482,181.577827 182.432938,184.185234 C183.827395,186.792642 184.57,189.775626 184.57,194.527611 L184.57,197.472389 C184.57,202.224374 183.827395,205.207358 182.432938,207.814766 C181.038482,210.422173 178.992173,212.468482 176.384766,213.862938 C173.777358,215.257395 170.794374,216 166.042389,216 L159.957611,216 C155.205626,216 152.222642,215.257395 149.615234,213.862938 C147.007827,212.468482 144.961518,210.422173 143.567062,207.814766 C142.172605,205.207358 141.43,202.224374 141.43,197.472389 L141.43,194.527611 C141.43,189.775626 142.172605,186.792642 143.567062,184.185234 C144.961518,181.577827 147.007827,179.531518 149.615234,178.137062 C152.222642,176.742605 155.205626,176 159.957611,176 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M159.957611,220 L166.042389,220 C170.794374,220 173.777358,220.742605 176.384766,222.137062 C178.992173,223.531518 181.038482,225.577827 182.432938,228.185234 C183.827395,230.792642 184.57,233.775626 184.57,238.527611 L184.57,241.472389 C184.57,246.224374 183.827395,249.207358 182.432938,251.814766 C181.038482,254.422173 178.992173,256.468482 176.384766,257.862938 C173.777358,259.257395 170.794374,260 166.042389,260 L159.957611,260 C155.205626,260 152.222642,259.257395 149.615234,257.862938 C147.007827,256.468482 144.961518,254.422173 143.567062,251.814766 C142.172605,249.207358 141.43,246.224374 141.43,241.472389 L141.43,238.527611 C141.43,233.775626 142.172605,230.792642 143.567062,228.185234 C144.961518,225.577827 147.007827,223.531518 149.615234,222.137062 C152.222642,220.742605 155.205626,220 159.957611,220 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M207.100945,-4.64314699e-16 L213.185722,4.64314699e-16 C217.937708,-4.0861093e-16 220.920692,0.742604889 223.528099,2.13706163 C226.135506,3.53151837 228.181815,5.57782696 229.576272,8.18523437 C230.970728,10.7926418 231.713333,13.7756257 231.713333,18.5276115 L231.713333,21.4723885 C231.713333,26.2243743 230.970728,29.2073582 229.576272,31.8147656 C228.181815,34.422173 226.135506,36.4684816 223.528099,37.8629384 C220.920692,39.2573951 217.937708,40 213.185722,40 L207.100945,40 C202.348959,40 199.365975,39.2573951 196.758568,37.8629384 C194.15116,36.4684816 192.104852,34.422173 190.710395,31.8147656 C189.315938,29.2073582 188.573333,26.2243743 188.573333,21.4723885 L188.573333,18.5276115 C188.573333,13.7756257 189.315938,10.7926418 190.710395,8.18523437 C192.104852,5.57782696 194.15116,3.53151837 196.758568,2.13706163 C199.365975,0.742604889 202.348959,4.0861093e-16 207.100945,-4.64314699e-16 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M207.100945,44 L213.185722,44 C217.937708,44 220.920692,44.7426049 223.528099,46.1370616 C226.135506,47.5315184 228.181815,49.577827 229.576272,52.1852344 C230.970728,54.7926418 231.713333,57.7756257 231.713333,62.5276115 L231.713333,65.4723885 C231.713333,70.2243743 230.970728,73.2073582 229.576272,75.8147656 C228.181815,78.422173 226.135506,80.4684816 223.528099,81.8629384 C220.920692,83.2573951 217.937708,84 213.185722,84 L207.100945,84 C202.348959,84 199.365975,83.2573951 196.758568,81.8629384 C194.15116,80.4684816 192.104852,78.422173 190.710395,75.8147656 C189.315938,73.2073582 188.573333,70.2243743 188.573333,65.4723885 L188.573333,62.5276115 C188.573333,57.7756257 189.315938,54.7926418 190.710395,52.1852344 C192.104852,49.577827 194.15116,47.5315184 196.758568,46.1370616 C199.365975,44.7426049 202.348959,44 207.100945,44 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M207.100945,88 L213.185722,88 C217.937708,88 220.920692,88.7426049 223.528099,90.1370616 C226.135506,91.5315184 228.181815,93.577827 229.576272,96.1852344 C230.970728,98.7926418 231.713333,101.775626 231.713333,106.527611 L231.713333,109.472389 C231.713333,114.224374 230.970728,117.207358 229.576272,119.814766 C228.181815,122.422173 226.135506,124.468482 223.528099,125.862938 C220.920692,127.257395 217.937708,128 213.185722,128 L207.100945,128 C202.348959,128 199.365975,127.257395 196.758568,125.862938 C194.15116,124.468482 192.104852,122.422173 190.710395,119.814766 C189.315938,117.207358 188.573333,114.224374 188.573333,109.472389 L188.573333,106.527611 C188.573333,101.775626 189.315938,98.7926418 190.710395,96.1852344 C192.104852,93.577827 194.15116,91.5315184 196.758568,90.1370616 C199.365975,88.7426049 202.348959,88 207.100945,88 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M207.100945,132 L213.185722,132 C217.937708,132 220.920692,132.742605 223.528099,134.137062 C226.135506,135.531518 228.181815,137.577827 229.576272,140.185234 C230.970728,142.792642 231.713333,145.775626 231.713333,150.527611 L231.713333,153.472389 C231.713333,158.224374 230.970728,161.207358 229.576272,163.814766 C228.181815,166.422173 226.135506,168.468482 223.528099,169.862938 C220.920692,171.257395 217.937708,172 213.185722,172 L207.100945,172 C202.348959,172 199.365975,171.257395 196.758568,169.862938 C194.15116,168.468482 192.104852,166.422173 190.710395,163.814766 C189.315938,161.207358 188.573333,158.224374 188.573333,153.472389 L188.573333,150.527611 C188.573333,145.775626 189.315938,142.792642 190.710395,140.185234 C192.104852,137.577827 194.15116,135.531518 196.758568,134.137062 C199.365975,132.742605 202.348959,132 207.100945,132 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M207.100945,176 L213.185722,176 C217.937708,176 220.920692,176.742605 223.528099,178.137062 C226.135506,179.531518 228.181815,181.577827 229.576272,184.185234 C230.970728,186.792642 231.713333,189.775626 231.713333,194.527611 L231.713333,197.472389 C231.713333,202.224374 230.970728,205.207358 229.576272,207.814766 C228.181815,210.422173 226.135506,212.468482 223.528099,213.862938 C220.920692,215.257395 217.937708,216 213.185722,216 L207.100945,216 C202.348959,216 199.365975,215.257395 196.758568,213.862938 C194.15116,212.468482 192.104852,210.422173 190.710395,207.814766 C189.315938,205.207358 188.573333,202.224374 188.573333,197.472389 L188.573333,194.527611 C188.573333,189.775626 189.315938,186.792642 190.710395,184.185234 C192.104852,181.577827 194.15116,179.531518 196.758568,178.137062 C199.365975,176.742605 202.348959,176 207.100945,176 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M207.100945,220 L213.185722,220 C217.937708,220 220.920692,220.742605 223.528099,222.137062 C226.135506,223.531518 228.181815,225.577827 229.576272,228.185234 C230.970728,230.792642 231.713333,233.775626 231.713333,238.527611 L231.713333,241.472389 C231.713333,246.224374 230.970728,249.207358 229.576272,251.814766 C228.181815,254.422173 226.135506,256.468482 223.528099,257.862938 C220.920692,259.257395 217.937708,260 213.185722,260 L207.100945,260 C202.348959,260 199.365975,259.257395 196.758568,257.862938 C194.15116,256.468482 192.104852,254.422173 190.710395,251.814766 C189.315938,249.207358 188.573333,246.224374 188.573333,241.472389 L188.573333,238.527611 C188.573333,233.775626 189.315938,230.792642 190.710395,228.185234 C192.104852,225.577827 194.15116,223.531518 196.758568,222.137062 C199.365975,220.742605 202.348959,220 207.100945,220 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M254.244278,-4.64314699e-16 L260.329055,4.64314699e-16 C265.081041,-4.0861093e-16 268.064025,0.742604889 270.671432,2.13706163 C273.27884,3.53151837 275.325148,5.57782696 276.719605,8.18523437 C278.114062,10.7926418 278.856667,13.7756257 278.856667,18.5276115 L278.856667,21.4723885 C278.856667,26.2243743 278.114062,29.2073582 276.719605,31.8147656 C275.325148,34.422173 273.27884,36.4684816 270.671432,37.8629384 C268.064025,39.2573951 265.081041,40 260.329055,40 L254.244278,40 C249.492292,40 246.509308,39.2573951 243.901901,37.8629384 C241.294494,36.4684816 239.248185,34.422173 237.853728,31.8147656 C236.459272,29.2073582 235.716667,26.2243743 235.716667,21.4723885 L235.716667,18.5276115 C235.716667,13.7756257 236.459272,10.7926418 237.853728,8.18523437 C239.248185,5.57782696 241.294494,3.53151837 243.901901,2.13706163 C246.509308,0.742604889 249.492292,4.0861093e-16 254.244278,-4.64314699e-16 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M254.244278,44 L260.329055,44 C265.081041,44 268.064025,44.7426049 270.671432,46.1370616 C273.27884,47.5315184 275.325148,49.577827 276.719605,52.1852344 C278.114062,54.7926418 278.856667,57.7756257 278.856667,62.5276115 L278.856667,65.4723885 C278.856667,70.2243743 278.114062,73.2073582 276.719605,75.8147656 C275.325148,78.422173 273.27884,80.4684816 270.671432,81.8629384 C268.064025,83.2573951 265.081041,84 260.329055,84 L254.244278,84 C249.492292,84 246.509308,83.2573951 243.901901,81.8629384 C241.294494,80.4684816 239.248185,78.422173 237.853728,75.8147656 C236.459272,73.2073582 235.716667,70.2243743 235.716667,65.4723885 L235.716667,62.5276115 C235.716667,57.7756257 236.459272,54.7926418 237.853728,52.1852344 C239.248185,49.577827 241.294494,47.5315184 243.901901,46.1370616 C246.509308,44.7426049 249.492292,44 254.244278,44 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M254.244278,88 L260.329055,88 C265.081041,88 268.064025,88.7426049 270.671432,90.1370616 C273.27884,91.5315184 275.325148,93.577827 276.719605,96.1852344 C278.114062,98.7926418 278.856667,101.775626 278.856667,106.527611 L278.856667,109.472389 C278.856667,114.224374 278.114062,117.207358 276.719605,119.814766 C275.325148,122.422173 273.27884,124.468482 270.671432,125.862938 C268.064025,127.257395 265.081041,128 260.329055,128 L254.244278,128 C249.492292,128 246.509308,127.257395 243.901901,125.862938 C241.294494,124.468482 239.248185,122.422173 237.853728,119.814766 C236.459272,117.207358 235.716667,114.224374 235.716667,109.472389 L235.716667,106.527611 C235.716667,101.775626 236.459272,98.7926418 237.853728,96.1852344 C239.248185,93.577827 241.294494,91.5315184 243.901901,90.1370616 C246.509308,88.7426049 249.492292,88 254.244278,88 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M254.244278,132 L260.329055,132 C265.081041,132 268.064025,132.742605 270.671432,134.137062 C273.27884,135.531518 275.325148,137.577827 276.719605,140.185234 C278.114062,142.792642 278.856667,145.775626 278.856667,150.527611 L278.856667,153.472389 C278.856667,158.224374 278.114062,161.207358 276.719605,163.814766 C275.325148,166.422173 273.27884,168.468482 270.671432,169.862938 C268.064025,171.257395 265.081041,172 260.329055,172 L254.244278,172 C249.492292,172 246.509308,171.257395 243.901901,169.862938 C241.294494,168.468482 239.248185,166.422173 237.853728,163.814766 C236.459272,161.207358 235.716667,158.224374 235.716667,153.472389 L235.716667,150.527611 C235.716667,145.775626 236.459272,142.792642 237.853728,140.185234 C239.248185,137.577827 241.294494,135.531518 243.901901,134.137062 C246.509308,132.742605 249.492292,132 254.244278,132 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M254.244278,176 L260.329055,176 C265.081041,176 268.064025,176.742605 270.671432,178.137062 C273.27884,179.531518 275.325148,181.577827 276.719605,184.185234 C278.114062,186.792642 278.856667,189.775626 278.856667,194.527611 L278.856667,197.472389 C278.856667,202.224374 278.114062,205.207358 276.719605,207.814766 C275.325148,210.422173 273.27884,212.468482 270.671432,213.862938 C268.064025,215.257395 265.081041,216 260.329055,216 L254.244278,216 C249.492292,216 246.509308,215.257395 243.901901,213.862938 C241.294494,212.468482 239.248185,210.422173 237.853728,207.814766 C236.459272,205.207358 235.716667,202.224374 235.716667,197.472389 L235.716667,194.527611 C235.716667,189.775626 236.459272,186.792642 237.853728,184.185234 C239.248185,181.577827 241.294494,179.531518 243.901901,178.137062 C246.509308,176.742605 249.492292,176 254.244278,176 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M254.244278,220 L260.329055,220 C265.081041,220 268.064025,220.742605 270.671432,222.137062 C273.27884,223.531518 275.325148,225.577827 276.719605,228.185234 C278.114062,230.792642 278.856667,233.775626 278.856667,238.527611 L278.856667,241.472389 C278.856667,246.224374 278.114062,249.207358 276.719605,251.814766 C275.325148,254.422173 273.27884,256.468482 270.671432,257.862938 C268.064025,259.257395 265.081041,260 260.329055,260 L254.244278,260 C249.492292,260 246.509308,259.257395 243.901901,257.862938 C241.294494,256.468482 239.248185,254.422173 237.853728,251.814766 C236.459272,249.207358 235.716667,246.224374 235.716667,241.472389 L235.716667,238.527611 C235.716667,233.775626 236.459272,230.792642 237.853728,228.185234 C239.248185,225.577827 241.294494,223.531518 243.901901,222.137062 C246.509308,220.742605 249.492292,220 254.244278,220 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M301.387611,-4.64314699e-16 L307.472389,4.64314699e-16 C312.224374,-4.0861093e-16 315.207358,0.742604889 317.814766,2.13706163 C320.422173,3.53151837 322.468482,5.57782696 323.862938,8.18523437 C325.257395,10.7926418 326,13.7756257 326,18.5276115 L326,21.4723885 C326,26.2243743 325.257395,29.2073582 323.862938,31.8147656 C322.468482,34.422173 320.422173,36.4684816 317.814766,37.8629384 C315.207358,39.2573951 312.224374,40 307.472389,40 L301.387611,40 C296.635626,40 293.652642,39.2573951 291.045234,37.8629384 C288.437827,36.4684816 286.391518,34.422173 284.997062,31.8147656 C283.602605,29.2073582 282.86,26.2243743 282.86,21.4723885 L282.86,18.5276115 C282.86,13.7756257 283.602605,10.7926418 284.997062,8.18523437 C286.391518,5.57782696 288.437827,3.53151837 291.045234,2.13706163 C293.652642,0.742604889 296.635626,4.0861093e-16 301.387611,-4.64314699e-16 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M301.387611,44 L307.472389,44 C312.224374,44 315.207358,44.7426049 317.814766,46.1370616 C320.422173,47.5315184 322.468482,49.577827 323.862938,52.1852344 C325.257395,54.7926418 326,57.7756257 326,62.5276115 L326,65.4723885 C326,70.2243743 325.257395,73.2073582 323.862938,75.8147656 C322.468482,78.422173 320.422173,80.4684816 317.814766,81.8629384 C315.207358,83.2573951 312.224374,84 307.472389,84 L301.387611,84 C296.635626,84 293.652642,83.2573951 291.045234,81.8629384 C288.437827,80.4684816 286.391518,78.422173 284.997062,75.8147656 C283.602605,73.2073582 282.86,70.2243743 282.86,65.4723885 L282.86,62.5276115 C282.86,57.7756257 283.602605,54.7926418 284.997062,52.1852344 C286.391518,49.577827 288.437827,47.5315184 291.045234,46.1370616 C293.652642,44.7426049 296.635626,44 301.387611,44 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M301.387611,88 L307.472389,88 C312.224374,88 315.207358,88.7426049 317.814766,90.1370616 C320.422173,91.5315184 322.468482,93.577827 323.862938,96.1852344 C325.257395,98.7926418 326,101.775626 326,106.527611 L326,109.472389 C326,114.224374 325.257395,117.207358 323.862938,119.814766 C322.468482,122.422173 320.422173,124.468482 317.814766,125.862938 C315.207358,127.257395 312.224374,128 307.472389,128 L301.387611,128 C296.635626,128 293.652642,127.257395 291.045234,125.862938 C288.437827,124.468482 286.391518,122.422173 284.997062,119.814766 C283.602605,117.207358 282.86,114.224374 282.86,109.472389 L282.86,106.527611 C282.86,101.775626 283.602605,98.7926418 284.997062,96.1852344 C286.391518,93.577827 288.437827,91.5315184 291.045234,90.1370616 C293.652642,88.7426049 296.635626,88 301.387611,88 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M301.387611,132 L307.472389,132 C312.224374,132 315.207358,132.742605 317.814766,134.137062 C320.422173,135.531518 322.468482,137.577827 323.862938,140.185234 C325.257395,142.792642 326,145.775626 326,150.527611 L326,153.472389 C326,158.224374 325.257395,161.207358 323.862938,163.814766 C322.468482,166.422173 320.422173,168.468482 317.814766,169.862938 C315.207358,171.257395 312.224374,172 307.472389,172 L301.387611,172 C296.635626,172 293.652642,171.257395 291.045234,169.862938 C288.437827,168.468482 286.391518,166.422173 284.997062,163.814766 C283.602605,161.207358 282.86,158.224374 282.86,153.472389 L282.86,150.527611 C282.86,145.775626 283.602605,142.792642 284.997062,140.185234 C286.391518,137.577827 288.437827,135.531518 291.045234,134.137062 C293.652642,132.742605 296.635626,132 301.387611,132 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M301.387611,176 L307.472389,176 C312.224374,176 315.207358,176.742605 317.814766,178.137062 C320.422173,179.531518 322.468482,181.577827 323.862938,184.185234 C325.257395,186.792642 326,189.775626 326,194.527611 L326,197.472389 C326,202.224374 325.257395,205.207358 323.862938,207.814766 C322.468482,210.422173 320.422173,212.468482 317.814766,213.862938 C315.207358,215.257395 312.224374,216 307.472389,216 L301.387611,216 C296.635626,216 293.652642,215.257395 291.045234,213.862938 C288.437827,212.468482 286.391518,210.422173 284.997062,207.814766 C283.602605,205.207358 282.86,202.224374 282.86,197.472389 L282.86,194.527611 C282.86,189.775626 283.602605,186.792642 284.997062,184.185234 C286.391518,181.577827 288.437827,179.531518 291.045234,178.137062 C293.652642,176.742605 296.635626,176 301.387611,176 Z'
					}
					id={'Rectangle'}></path>
				<path
					d={
						'M301.387611,220 L307.472389,220 C312.224374,220 315.207358,220.742605 317.814766,222.137062 C320.422173,223.531518 322.468482,225.577827 323.862938,228.185234 C325.257395,230.792642 326,233.775626 326,238.527611 L326,241.472389 C326,246.224374 325.257395,249.207358 323.862938,251.814766 C322.468482,254.422173 320.422173,256.468482 317.814766,257.862938 C315.207358,259.257395 312.224374,260 307.472389,260 L301.387611,260 C296.635626,260 293.652642,259.257395 291.045234,257.862938 C288.437827,256.468482 286.391518,254.422173 284.997062,251.814766 C283.602605,249.207358 282.86,246.224374 282.86,241.472389 L282.86,238.527611 C282.86,233.775626 283.602605,230.792642 284.997062,228.185234 C286.391518,225.577827 288.437827,223.531518 291.045234,222.137062 C293.652642,220.742605 296.635626,220 301.387611,220 Z'
					}
					id={'Rectangle'}></path>
			</g>
		</g>
	</SvgIcon>
);
