import { makeStyles } from '@mui/styles';

export const useLiveChatConfirmationModalStyles = makeStyles(
	({ typography: { smallViewPort }, palette: { sizes, borderRadius, boxShadow } }) => ({
		root: {
			minWidth: 264,
			maxWidth: '67%',
			padding: sizes.size_3,
			backgroundColor: 'white',
			borderRadius: borderRadius.card,
			boxShadow: boxShadow.angle.xsmall,
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		title: {
			textAlign: 'center',
			...smallViewPort.typeHeading1,
			margin: 0,
		},
		description: {
			paddingTop: sizes.size_1,
			textAlign: 'center',
			...smallViewPort.typeCaption1,
			marginBottom: 0,
		},
		actions: {
			paddingTop: sizes.size_3,
			textAlign: 'center',
		},
		action: {
			width: 106,
			'&:not(:last-child)': {
				marginRight: sizes.sizeHalf,
			},
		},
	}),
);
